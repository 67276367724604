import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import AlertContext from "../../../context/Alert/AlertContext";
import DataTable2 from "../../../components/DataTable2";
import classes from "./addProjectTaskList.module.css";
import { errorToast, successToast } from "../../../utils/Toaster";

const validationRules = {
  name: Yup.string()
    .max(120, "Task Name must not exceed 120 characters")
    .required("Task Name is required"),
};

const EditProjectTask = () => {
  const navigate = useNavigate();
  const { setAlertMessage } = useContext(AlertContext);
  const [parent, setParent] = useState([]);
  const [getData, setGetData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axiosPrivate
      .get("task-categories/categories")
      .then((projects) => {
        setParent(projects?.data?.data);
      })
      .catch((error) => {
        errorToast("Error fetching task categories:", error);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      project_id: "",
      taskType: "",
      parent_id: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const requestData = {
        name: values.name,
        project_id: values.project_id,
        parent_id: values.taskType === "parent" ? null : values.parent_id,
      };

      try {
        await axiosPrivate.put(`task-categories/update/${id}`, requestData);
        setAlertMessage({ message: "Task updated successfully" });
        navigate(`/admin/project-task-list/edit/${id}`);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            setFieldError(
              error.response.data.errors[i].param,
              error.response.data.errors[i].msg
            );
          }
        }
        errorToast("Error updating task:", error);
      }
    },
  });

  useEffect(() => {
    axiosPrivate
      .get(`task-categories/view/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          const taskData = response.data.data;
          setGetData({ ...taskData });
          formik.setValues({
            name: taskData.name,
            project_id: taskData.project_id,
            taskType: taskData.taskType,
            parent_id: taskData.parent_id,
          });
        }
      })
      .catch((error) => {
        errorToast("Error fetching task data:", error);
      });
  }, [id]);

  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div className="tab-pane fade show active" id="user-add" role="tabpanel">
              <div className="card">
                <div className="card-header">
                  <strong>Edit Project Tasks</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label>
                        Child Task Name:<span className="required">*</span>
                      </label>
                      <div className="form-group">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Child Task"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <hr className="mt-4" />
                    <div className="table-responsive">
                      {/* Include DataTable2 with the updated data */}
                     
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary ml-2"
                      data-dismiss="modal"
                      onClick={() => navigate('/admin/project-task-list')}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProjectTask;
