import { Formik, useFormik } from "formik";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import * as Yup from "yup";
import TaskList from "../../../components/TaskList";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./addProjectTaskList.module.css";
import "react-datepicker/dist/react-datepicker.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { successToast } from "../../../utils/Toaster";
import { BsCalendar } from "react-icons/bs";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import AlertContext from "../../../context/Alert/AlertContext";

const validationRules = {
  task: Yup.array()
    .of(
      Yup.object().shape({
        category_id: Yup.string().required("Category is required"),
        sub_category: Yup.string().required("Task is required"),
        title: Yup.string().required("Task description is required"),
        assigned_to_user_id: Yup.string().required("Employee name is required"),
        priority: Yup.string().required("Priority is required"),
        estimated_time: Yup.number()
          .required("Estimated minutes is required")
          .positive("Estimated hours must be a positive number"),
        due_date: Yup.string().required("Due date is required"),
        status: Yup.string().required("Status is required"),
      })
    )
    .required("At least one task is required"),
};

const AddNewProjectTask = () => {
  const { id } = useParams();
  // const project_id = id;
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [project_id, setProjectId] = useState(id);

  const slug = window.location.pathname;
  const validate = slug?.includes("add-task");
  const inputRef = useRef(null);
  const { sendNotification } =
    useContext(AlertContext);

  const formatDate = (inputDateStr) => {
    if (!inputDateStr) {
      return null;
    }

    const parsedDate = new Date(inputDateStr);

    parsedDate.setHours(0, 0, 0, 0);

    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const originalDateString = "2023-12-14T05:00:00.000";
  const formattedDate = formatDate(originalDateString);

  let userId = "";
  if (localStorage.getItem("userData")) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    userId = userData?.userId;
  }

  const [tasklist, setTaskList] = useState([
    {
      category_id: "",
      sub_category: "",
      title: "",
      assigned_to_user_id: "",
      estimated_time: "",
      priority: "",
      status: "Pending",
      due_date: formatDate(date),
      parentListItems: [],
      childList: [],
      raised_by_user_id: userId,
      register_id: "",
    },
  ]);

  const [projects, setProjects] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [category, setCategory] = useState([]);

  const handleProjectChange = async () => {
    if (validate) {
      if (project_id) {
        try {
          const response = await axiosPrivate.post(`get-team-by-project`, {
            project_id: project_id,
          });

          if (response?.data?.data && response?.data?.data?.length > 0) {
            const employeeDetails = response?.data.data[0].employee_details;
            setEmployee(employeeDetails);
          } else {
            setEmployee([]);
          }
        } catch (error) {
          console.error("Error fetching team data:", error);
          setEmployee([]);
        }
      }
    } else {
      try {
        const response = await axiosPrivate.post(`get-team-by-project`, {
          project_id: project_id,
        });

        if (response?.data?.data && response?.data?.data?.length > 0) {
          const employeeDetails = response?.data.data[0].employee_details;
          setEmployee(employeeDetails);
        } else {
          setEmployee([]);
        }
      } catch (error) {
        console.error("Error fetching team data:", error);
        setEmployee([]);
      }
    }
  };

  useEffect(() => {
    handleProjectChange();
  }, [project_id]);

  useEffect(() => {
    if (validate) {
      axiosPrivate
        .get("projects")
        .then((projects) => {
          setProjects(projects?.data?.data);
        })
        .catch((error) => {});
    }

    axiosPrivate
      .get("task-categories/categories")
      .then((res) => {
        setCategory(res?.data?.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (category?.length > 0) {
      const oldTasks = formik.values.task;
      for (let i = 0; i < oldTasks.length; i++) {
        oldTasks[i].parentListItems = category;
      }
      formik.setFieldValue("task", oldTasks);
    }
  }, [category]);

  useEffect(() => {
    if (tasklist?.length > 0 && category?.length > 0) {
      let oldTaskList = tasklist;
      for (let i = 0; i < tasklist.length; i++) {
        oldTaskList[i]["parentListItems"] = category;
      }
      setTaskList(oldTaskList);
    }
  }, [category]);

  const formik = useFormik({
    initialValues: {
      project_id: project_id || "",
      task: tasklist,
    },

    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const tasks = values.task.map((task) => {
        const { parentListItems, childList, ...restOfTask } = task;
        return restOfTask;
      });
      const postData = {
        project_id: project_id,
        task: tasks,
      };

      try {
        const response = await axiosPrivate
          .post(`task-assign`, postData)
          .then((res) => {
            res?.data?.taskDetails?.map((data) =>{
              if(data?.register_id){
                sendNotification(data?.register_id, data?.sub_category, data?.title, `/admin/project-ticket-list/view/${data?.id}`, data?.user_id, 1);
              }
            })
            setFormSubmitted(true);
            successToast("Task Added successfully");
            navigate(
              `/admin/project-list/edit/${project_id.replaceAll(" ", "-")}`
            );
          });
      } catch (error) {
        if (error.response) {
          if (error.response.status === 422) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  // Function to add a new parent task
  const addNewParentTask = (eve) => {
    eve.preventDefault();
    const newParentTask = {
      category_id: "",
      sub_category: "",
      title: "",
      assigned_to_user_id: "",
      status: "Pending",
      estimated_time: "",
      priority: "",
      due_date: formatDate(date),
      parentListItems: category,
      childList: [],
      raised_by_user_id: userId,
      register_id: "",
    };
    formik.setFieldValue("task", [...formik.values.task, newParentTask]);
  };

  // Function to remove a parent task
  const removeParentTask = (ind, eve) => {
    eve.preventDefault();
    if (formik.values.task.length > 1) {
      const updatedtasklist = [...formik.values.task];
      updatedtasklist.splice(ind, 1);
      formik.setFieldValue("task", updatedtasklist);
    }
  };

  const setFormikCategory = async (event, value, parentIndex) => {
    event.preventDefault();
    formik.setFieldValue(`task[${parentIndex}.category_id]`, value);
    await getChildOptions(value, parentIndex);
    return true;
  };

  const setFormikChildCategory = async (event, value, parentIndex) => {
    event.preventDefault();
    formik.setFieldValue(`task[${parentIndex}.sub_category]`, value);
    return true;
  };

  const setFormikEmployee = async (event, value, parentIndex) => {
    event.preventDefault();
    formik.setFieldValue(`task[${parentIndex}.assigned_to_user_id]`, value);
    return true;
  };

  const setNotificationId = async (event, value, parentIndex) => {
    event.preventDefault();
    formik.setFieldValue(`task[${parentIndex}.register_id]`, value);
    return true;
  };

  const getChildOptions = async (taskId, index) => {
    try {
      const response = await axiosPrivate.get(
        `task-categories/sub-categories/${taskId}`
      );
      if (response.data.code === 200 && response?.data?.data) {
        formik.setFieldValue(`task[${index}.childList]`, response?.data?.data);
        // setTeam(updatedList);
      } else {
        formik.setFieldValue(`task[${index}.childList]`, []);
      }
    } catch (error) {}
  };

  // Task

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="section-body mt-3">
      <div>
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>Add Project Tasks</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix"></div>
                  <div>
                    {/* Task Table */}
                    {formik.values.task.length > 0 &&
                      formik.values.task.map((item, index) => (
                        <div className="">
                          <span style={{ fontWeight: "bold" }}>
                            {index + 1}.
                          </span>
                          <div className="row">
                            {validate && (
                              <div className="col-md-4">
                                <div className="form-group">
                                  <select
                                    className="form-control show-tick"
                                    id="assigned_to_user_id"
                                    name="assigned_to_user_id"
                                    onChange={(event) =>
                                      setProjectId(event.target.value)
                                    }
                                    onBlur={formik.handleBlur}
                                    disabled={formSubmitted}
                                  >
                                    <option value="" selected disabled>
                                      Select Project *
                                    </option>
                                    {projects.map((project) => (
                                      <option
                                        key={project?.project_id}
                                        value={project?.project_name}
                                      >
                                        {project?.project_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}
                            <div className="col-md-4">
                              <div className="form-group">
                                <select
                                  className="form-control show-tick"
                                  id="category_id"
                                  name="category_id"
                                  onChange={(event) =>
                                    setFormikCategory(
                                      event,
                                      event.target.value,
                                      index
                                    )
                                  }
                                  onBlur={formik.handleBlur}
                                  value={formik.values.task[index].category_id}
                                  disabled={formSubmitted}
                                >
                                  <option value="" disabled>
                                    Select Category *
                                  </option>
                                  {item?.parentListItems.map((task, ind) => (
                                    <option key={ind} value={task.id}>
                                      {task.name}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.task &&
                                formik.touched.task[index] &&
                                formik.errors.task &&
                                formik.errors.task[index]?.category_id ? (
                                  <div className={classes.invalidDataError}>
                                    {formik.errors.task[index]?.category_id}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Task *"
                                  id="sub_category"
                                  name="sub_category"
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      `task[${index}].sub_category`,
                                      event.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.task[index].sub_category}
                                  disabled={formSubmitted}
                                />
                                {formik.touched.task &&
                                formik.touched.task[index] &&
                                formik.errors.task &&
                                formik.errors.task[index]?.sub_category ? (
                                  <div className={classes.invalidDataError}>
                                    {formik.errors.task[index]?.sub_category}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter Task Description *"
                                  id="title"
                                  name="title"
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      `task[${index}].title`,
                                      event.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.task[index].title}
                                  disabled={formSubmitted}
                                />
                                {formik.touched.task &&
                                formik.touched.task[index] &&
                                formik.errors.task &&
                                formik.errors.task[index]?.title ? (
                                  <div className={classes.invalidDataError}>
                                    {formik.errors.task[index]?.title}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <select
                                  className="form-control show-tick"
                                  id="assigned_to_user_id"
                                  name="assigned_to_user_id"
                                  onChange={(event) => {
                                    const selectedIndex =
                                      event.target.selectedIndex;
                                    const selectedOption =
                                      event.target.options[selectedIndex];
                                    const registerId =
                                      selectedOption.getAttribute(
                                        "data-register-id"
                                      );

                                    setFormikEmployee(
                                      event,
                                      event.target.value,
                                      index
                                    );
                                    setNotificationId(event, registerId, index);
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values.task[index]
                                      .assigned_to_user_id
                                  }
                                  disabled={formSubmitted}
                                >
                                  <option value="" disabled>
                                    Assigned To *
                                  </option>
                                  {employee &&
                                    employee.map((employee) => (
                                      <option
                                        key={employee.employee_user_id}
                                        value={employee.employee_user_id}
                                        data-register-id={employee.register_id}
                                      >
                                        {employee.employee_name}
                                      </option>
                                    ))}
                                </select>

                                {formik.touched.task &&
                                formik.touched.task[index] &&
                                formik.errors.task &&
                                formik.errors.task[index]
                                  ?.assigned_to_user_id ? (
                                  <div className={classes.invalidDataError}>
                                    {
                                      formik.errors.task[index]
                                        ?.assigned_to_user_id
                                    }
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  className="form-control"
                                  type="number"
                                  placeholder="Estimated Time (Minutes) *"
                                  id="estimated_time"
                                  name="estimated_time"
                                  onChange={(event) => {
                                    formik.setFieldValue(
                                      `task[${index}].estimated_time`,
                                      event.target.value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  value={
                                    formik.values.task[index].estimated_time
                                  }
                                  disabled={formSubmitted}
                                />

                                {formik.touched.task &&
                                formik.touched.task[index] &&
                                formik.errors.task &&
                                formik.errors.task[index]?.estimated_time ? (
                                  <div className={classes.invalidDataError}>
                                    {formik.errors.task[index]?.estimated_time}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  id="priority"
                                  name={`task[${index}].priority`}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.task[index].priority}
                                  disabled={formSubmitted}
                                >
                                  <option value="" disabled>
                                    Select Priority *
                                  </option>
                                  <option value="4">Critical</option>
                                  <option value="3">High</option>
                                  <option value="2">Medium</option>
                                  <option value="1">Low</option>
                                </select>

                                {formik.touched.task &&
                                formik.touched.task[index] &&
                                formik.errors.task &&
                                formik.errors.task[index]?.priority ? (
                                  <div className={classes.invalidDataError}>
                                    {formik.errors.task[index]?.priority}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  id={`status`}
                                  name={`task[${index}].status`}
                                  onChange={formik.handleChange}
                                  value={formik.values?.status}
                                >
                                  <option value="Pending" selected>
                                    Pending
                                  </option>
                                  <option value="In Progress">
                                    In Progress
                                  </option>
                                  <option value="Ready For Q/A">
                                    Ready For Q/A
                                  </option>
                                  <option value="Done">Done</option>

                                  <option value="Overdue">Overdue</option>
                                  <option value="On Hold">On Hold</option>
                                  <option value="Ready For UAT">
                                    Ready For UAT
                                  </option>
                                </select>
                                {formik.touched.task &&
                                formik.touched.task[index] &&
                                formik.errors.task &&
                                formik.errors.task[index]?.status ? (
                                  <div className={classes.invalidDataError}>
                                    {formik.errors.task[index]?.status}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <div className="input-group datepickerwidth">
                                  <DatePicker
                                    className="form-control "
                                    id={`due_date_${index}`}
                                    name={`task[${index}].due_date`}
                                    onChange={(date) => {
                                      formik.setFieldValue(
                                        `task[${index}].due_date`,
                                        date ? formatDate(date) : null
                                      );
                                    }}
                                    onBlur={formik.handleBlur}
                                    selected={
                                      formik.values.task[index].due_date
                                        ? new Date(
                                            formik.values.task[index].due_date
                                          )
                                        : null
                                    }
                                    disabled={formSubmitted}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Due Date (dd-mm-yyyy) *"
                                    minDate={new Date()} // Disable previous dates
                                  />

                                  <div
                                    className="input-group-append"
                                    style={{ height: "32px" }}
                                  >
                                    <button
                                      className="btn btn-outline-secondary"
                                      type="button"
                                      onClick={() =>
                                        document
                                          .getElementById(`due_date_${index}`)
                                          .click()
                                      }
                                    >
                                      <BsCalendar />
                                    </button>
                                  </div>
                                </div>
                                {formik.touched.task &&
                                formik.touched.task[index] &&
                                formik.errors.task &&
                                formik.errors.task[index]?.due_date ? (
                                  <div className={classes.invalidDataError}>
                                    {formik.errors.task[index]?.due_date}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-auto">
                              <div
                                className="form-group text-center"
                                style={{ marginBottom: "0px" }}
                              >
                                <div className="d-flex justify-content-around">
                                  {index === formik.values.task.length - 1 && (
                                    <button
                                      className="btn btn-success"
                                      onClick={(eve) => addNewParentTask(eve)}
                                      disabled={formSubmitted}
                                    >
                                      +
                                    </button>
                                  )}
                                  {index !== 0 && (
                                    <button
                                      className="btn btn-danger ml-2"
                                      onClick={(eve) =>
                                        removeParentTask(index, eve)
                                      }
                                      disabled={formSubmitted}
                                    >
                                      -
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary mr-1 ml-1 "
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary ml-2"
                      onClick={() => navigate("/admin/project-list")}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewProjectTask;
