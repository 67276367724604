import axios from "axios";
import { axiosPublic } from "./axiosPublic";
import { errorToast } from "../utils/Toaster";

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getUserData = () => JSON.parse(localStorage.getItem("userData") || "{}");

const checkUpdateToken = async () => {
  try {
    const userData = getUserData();
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const refreshAccessToken = async () => {
  try {
    const { refresh_token } = getUserData();
    const response = await axiosPublic.post("refresh-token", {
      refresh_token,
    });

    const newUserData = response.data;
    localStorage.setItem("userData", JSON.stringify(newUserData));
    localStorage.setItem("userRefreshToken", JSON.stringify(newUserData.refresh_token));
    return newUserData;
  } catch (error) {
    localStorage.clear();
    window.location.reload();
    throw error;
  }
};

axiosApiInstance.interceptors.request.use(
  async (config) => {
    await checkUpdateToken();
    const userData = getUserData();
    if (userData.token) {
      config.headers.Authorization = `Bearer ${userData.token}`;
    }
    config.headers.Accept = "application/json";
    return config;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { response, config } = error;
    const errorMessage = response?.data?.message ||
      response?.data?.errorMessages?.[0]?.message ||
      response?.data?.errorMessages?.[1]?.message ||
      response?.statusText;

      if(errorMessage !== "jwt expired"){
        // errorToast(errorMessage || "An error occurred");
      }
    
    if (response?.status === 401 && !config._retry) {
      config._retry = true;
      try {
        const newUserData = await refreshAccessToken();
        config.headers.Authorization = `Bearer ${newUserData.token}`;
        return axiosApiInstance(config);
      } catch (refreshError) {
        localStorage.clear();
        window.location.reload();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosApiInstance;
