import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Button } from "@material-ui/core";
import moment from "moment/moment";

const GenerateReportButton = ({ data, reportType, reportTime, reportName }) => {
  function convertToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes < 10 ? "0" : ""}${remainingMinutes}`;
  }

  const finalData = data.map((value) => {
    value["due_date"] = moment(value?.due_date).format("DD MMM YYYY");
    value["created_at"] = moment(value?.created_at).format("DD MMM YYYY");
    value["estimated_time"] = `${convertToHoursAndMinutes(value.estimated_time)} Hrs`;
    value["actual_time"] = `${convertToHoursAndMinutes(value.actual_time)} Hrs`;

    // This is for project report
    value?.summary?.map((item) =>{
      item["actual_time"] = `${convertToHoursAndMinutes(item?.actual_time)} Hrs`;
      let priority = item?.priority;
    if (priority === "1") {
      item["priority"] = "Low";
    } else if (priority === "2") {
      item["priority"] = "Medium";
    } else if (priority === "3") {
      item["priority"] = "High";
    } else if (priority === "4") {
      item["priority"] = "Critical";
    }
    })

    // This is for employee report
    let priority = value.priority;
    if (priority === "1") {
      value["priority"] = "Low";
    } else if (priority === "2") {
      value["priority"] = "Medium";
    } else if (priority === "3") {
      value["priority"] = "High";
    } else if (priority === "4") {
      value["priority"] = "Critical";
    }

    
      value["replies"] = value?.replies?.filter((reply) => {
      reply.date = moment(reply?.date).format("DD MMM YYYY");
  
      // Convert HTML content to plain text
      const parser = new DOMParser();
      const doc = parser.parseFromString(reply.reply, 'text/html');
      reply.reply = doc.body.textContent || "";
  
      if(reportName === "Webanix Report"){
        return reply;
      }else{
        return reply.internal_external === 1;
      }
    });
  
    return value;
  });
  

  const exportPdf = async () => {
    if (reportType === "Project Report") {
      if (reportName === "Webanix Report") {
        const doc = new jsPDF({ orientation: "landscape" });

        const projectName = finalData[0].project_name;
        const projectEstimatedTime = reportTime?.estimated_hours; // Assuming this field exists in your data
        const projectActualTime = reportTime?.totalProjectHours; // Assuming this field exists in your data
        const pageWidth = doc.internal.pageSize.getWidth();
        const fontSize = 12; // Adjust this value as needed
        doc.setFontSize(fontSize);

        // Add project name at the top left
        doc.setFont("helvetica", "normal");
        doc.text(projectName, 14, 10);

        // Calculate positions for estimated and actual times
        const estimatedTimeText = `Estimated Time: ${projectEstimatedTime} Hrs / `;
        const actualTimeText = `Actual Time: ${projectActualTime} Hrs`;

        const estimatedTimeTextWidth =
          (doc.getStringUnitWidth(estimatedTimeText) * fontSize) /
          doc.internal.scaleFactor;
        const actualTimeTextWidth =
          (doc.getStringUnitWidth(actualTimeText) * fontSize) /
          doc.internal.scaleFactor;

        const estimatedTimeTextX =
          pageWidth - estimatedTimeTextWidth - actualTimeTextWidth - 14;
        const actualTimeTextX = pageWidth - actualTimeTextWidth - 14;

        // Add estimated and actual times
        doc.text(estimatedTimeText, estimatedTimeTextX, 10);
        doc.text(actualTimeText, actualTimeTextX, 10);

        const uniformColumnWidth = 30;
        doc.setFontSize(10); // Adjust this value as needed

        // Generate the table
        doc.autoTable({
          head: [
            [
              "Department",
              "Emp Name",
              "Category",
              "Task",
              "Task Description",
              "Est Time",
              "Actual Time",
              "Priority",
              "Status",
              "Task Date",
              "Due Date",
            ],
          ],
          body: finalData.flatMap((row) =>
            row?.summary?.map((summary) => [
              row.department,
              summary.name,
              row.category_name,
              row.sub_category,
              row.title,
              row.estimated_time,
              summary.actual_time,
              summary.priority,
              summary.status,
              row.created_at,
              row.due_date,
            ])
          ),
          startY: 15, // Adjust startY to leave space for the project name and times
          headStyles: {
            overflow: "hidden", // Ensure the header text does not wrap
            cellWidth: "auto", // Set the cell width to auto
            whiteSpace: "nowrap", // Set the white-space property to nowrap
          },
          columnStyles: {
            1: { cellWidth: 25 },
            2: { cellWidth: 25 },
            3: { cellWidth: uniformColumnWidth },
            4: { cellWidth: 35 },
            8: { cellWidth: 25 },
          },
        });

        doc.save("project_report.pdf");
      } else {
        const doc = new jsPDF({ orientation: "landscape" });

        const projectName = finalData[0].project_name;
        const projectEstimatedTime = reportTime?.estimated_hours; // Assuming this field exists in your data
        const projectActualTime = reportTime?.totalProjectHours; // Assuming this field exists in your data
        const pageWidth = doc.internal.pageSize.getWidth();
        const fontSize = 12; // Adjust this value as needed
        doc.setFontSize(fontSize);
        
        // Add project name at the top left
        doc.setFont("helvetica", "normal");
        doc.text(projectName, 14, 10);
        
        // Calculate positions for estimated and actual times
        const actualTimeText = `Estimated Time: ${projectEstimatedTime} Hrs`;
        
        const actualTimeTextWidth =
          (doc.getStringUnitWidth(actualTimeText) * fontSize) /
          doc.internal.scaleFactor;
        
        const actualTimeTextX = pageWidth - actualTimeTextWidth - 14;
        
        // Add estimated and actual times
        doc.text(actualTimeText, actualTimeTextX, 10);
        
        const uniformColumnWidth = 25;
        doc.setFontSize(10); // Adjust this value as needed
        
        // Generate the table
        doc.autoTable({
          head: [
            [
              "Employee Name",
              "Category",
              "Task",
              "Task Description",
              "Reply",
              "Reply Date",
              "Status"
            ],
          ],
          body: finalData.flatMap((row) => {
            // If replies array is empty, create a single row with default values
            if (!row.replies || row.replies.length === 0) {
              return row?.summary?.map((summary) => [
                summary.name,
                row.category_name,
                row.sub_category,
                row.title,
                '-', // No reply content
                '-', // No reply date
                summary.status
              ]);
            }
          
            // Otherwise, create rows for each reply
            return row.replies.map((reply, index) => [

              reply.assigned_to_username,
              reply.category_name,
              reply.sub_category,
              reply.title,
              reply?.reply, 
              reply?.date, 
              reply.status,
            ]);
          }),
          startY: 15, // Adjust startY to leave space for the project name and times
          headStyles: {
            overflow: "hidden", // Ensure the header text does not wrap
            cellWidth: "auto", // Set the cell width to auto
            whiteSpace: "nowrap", // Set the white-space property to nowrap
          },
          columnStyles: {
            2: { cellWidth: 35 },
            5: { cellWidth: uniformColumnWidth },
            6: { cellWidth: uniformColumnWidth },
          },
        });
        
        doc.save("project_report.pdf");
        

      }
    } else {
      const doc = new jsPDF({ orientation: "landscape" });

      const projectName = finalData[0].assigned_to_username;
      const pageWidth = doc.internal.pageSize.getWidth();

      // Calculate the center position for the project name
      const textWidth =
        (doc.getStringUnitWidth(projectName) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const textX = (pageWidth - textWidth) / 2;

      // Set font size for the texts
      const fontSize = 12; // Adjust this value as needed
      doc.setFontSize(fontSize);

      // Add project name at the top center
      doc.setFont("helvetica", "normal");
      doc.text(projectName, textX, 10);
      const uniformColumnWidth = 35;

      doc.autoTable({
        head: [
          [
            "Department",
            "Project Name",
            "Category",
            "Task",
            "Task Description",
            "Estimated Time",
            "Actual Time",
            "Priority",
            "Status",
            "Due Date",
          ],
        ],
        body: finalData.map((row) => [
          row.department,
          row.project_name,
          row.category_name,
          row.sub_category,
          row.title,
          row.estimated_time,
          row.actual_time,
          row.priority,
          row.status,
          row.due_date,
        ]),
        startY: 15, // Adjust startY to leave space for the project name
        // styles: {
        //   overflow: 'linebreak', // Handle overflow by breaking lines
        //   cellWidth: 'wrap', // Set the cell width to wrap
        // },
        headStyles: {
          overflow: "hidden", // Ensure the header text does not wrap
          cellWidth: "auto", // Set the cell width to auto
          whiteSpace: "nowrap", // Set the white-space property to nowrap
        },
        columnStyles: {
          // 0: { cellWidth: uniformColumnWidth },
          1: { cellWidth: 30 },
          2: { cellWidth: 25 },
          3: { cellWidth: uniformColumnWidth },
          4: { cellWidth: uniformColumnWidth },
          // 5: { cellWidth: uniformColumnWidth },
          // 6: { cellWidth: uniformColumnWidth },
          // 7: { cellWidth: uniformColumnWidth },
          // 8: { cellWidth: uniformColumnWidth },
          // 9: { cellWidth: uniformColumnWidth },
          // 10: { cellWidth: uniformColumnWidth },
        },
      });

      doc.save("employee_report.pdf");
    }
  };

  return (
    <Button
      variant="contained"
      style={{ backgroundColor: "#5a5278", color: "#fff" }}
      className="export-btn"
      onClick={exportPdf}
    >
      Export Pdf
    </Button>
  );
};

export default GenerateReportButton;
