import React, { useEffect, useRef, useState } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import Select from "react-select";

import styles from "./TodoListFixedControls.module.css";
import "./toggle.css";
import { successToast, errorToast } from "../../../utils/Toaster";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IoIosClose } from "react-icons/io";
import dayjs from "dayjs";
import moment from "moment";
const CustomTable = ({ data, projectDetails, completedTask, users, leadTask}) => {
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [leadTasks, setLeadTasks] = useState([]);
  const [departmentList, setDepartmentList] = useState([
    { name: "Development", id: "1" },
    { name: "Digital", id: "2" },
  ]);
  const [lead, setLead] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [departmentNameFilter, setDepartmentNameFilter] = useState("");
  const [dateNameFilter, setDateNameFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [projectList, setProjectList] = useState([]);
  // const [filteredData1, setFilteredData1] = useState([]);
  // const [leadData, setLeadData] = useState([]);

  let date1 = "00-00-0000";
  let date2 = "00-00-0000";
  let date3 = "00-00-0000";
  let date4 = "00-00-0000";

  let userDetails = {};
  if (localStorage.getItem("userData")) {
    userDetails = JSON.parse(localStorage.getItem("userData"));
  }

  // Tasks list of employees 

  

  // const filteredData1 = !checked
  //   ? data?.filter(
  //       (item) =>
  //         item?.project_name
  //           .toLowerCase()
  //           .includes(projectNameFilter?.toLowerCase()) &&
  //         item?.assigned_to_username
  //           ?.toLowerCase()
  //           ?.includes(employeeNameFilter?.toLowerCase()) &&
  //         (statusFilter === "" || item?.status === statusFilter) &&
  //         item?.department
  //           ?.toLowerCase()
  //           .includes(departmentNameFilter?.toLowerCase()) &&
  //         item?.due_date?.toLowerCase()?.includes(dateNameFilter?.toLowerCase())
  //     )
  //   : completedTasks?.filter(
  //       (item) =>
  //         item?.project_name
  //           ?.toLowerCase()
  //           ?.includes(projectNameFilter?.toLowerCase()) &&
  //         item?.assigned_to_username
  //           ?.toLowerCase()
  //           ?.includes(employeeNameFilter?.toLowerCase()) &&
  //         (statusFilter === "" || item?.status === statusFilter) &&
  //         item?.department
  //           ?.toLowerCase()
  //           ?.includes(departmentNameFilter?.toLowerCase()) &&
  //         item?.due_date?.toLowerCase()?.includes(dateNameFilter?.toLowerCase())
  //     );

      
  //   // Tasks list of leads
  // const leadData = leadTasks?.filter(
  //   (item) =>
  //     item?.project_name
  //       ?.toLowerCase()
  //       ?.includes(projectNameFilter?.toLowerCase()) &&
  //     item?.assigned_to_username
  //       ?.toLowerCase()
  //       ?.includes(employeeNameFilter?.toLowerCase()) &&
  //     (statusFilter === "" || item?.status === statusFilter) &&
  //     item?.department
  //       ?.toLowerCase()
  //       ?.includes(departmentNameFilter?.toLowerCase()) &&
  //     item?.due_date?.toLowerCase()?.includes(dateNameFilter?.toLowerCase())
  // );

  const filterTasks = (tasks) => {
    return tasks?.filter( 
      (item) =>
        item?.project_name?.toLowerCase().includes(projectNameFilter?.toLowerCase()) &&
        item?.assigned_to_username?.toLowerCase().includes(employeeNameFilter?.toLowerCase()) &&
        (statusFilter === "" || item?.status === statusFilter) &&
        item?.department?.toLowerCase().includes(departmentNameFilter?.toLowerCase()) &&
        item?.due_date?.includes(dateNameFilter)
    );
  };

  const filteredData1 = !checked ? filterTasks(data) : filterTasks(completedTasks);
  const leadData = filterTasks(leadTasks);  

  
  
  // useEffect(() => {
  //   const filterTasks = (tasks) => {
    
  //     return tasks?.filter(
  //       (item) =>
  //         item?.project_name?.toLowerCase().includes(projectNameFilter.toLowerCase()) &&
  //         item?.assigned_to_username?.toLowerCase().includes(employeeNameFilter.toLowerCase()) &&
  //         (statusFilter === "" || item?.status === statusFilter) &&
  //         item?.department?.toLowerCase().includes(departmentNameFilter.toLowerCase()) &&
  //         item?.due_date?.includes(dateNameFilter) 
  //     );
  //   }; 

  //   setFilteredData1(!checked ? filterTasks(data) : filterTasks(completedTasks));
  //   setLeadData(filterTasks(leadTasks));
  // }, [projectNameFilter, employeeNameFilter, statusFilter, departmentNameFilter, dateNameFilter, checked, data, completedTasks, leadTasks]);

  
  

  // Formatting date in dd-mm-yyyy format
  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // January is 0!
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const todaysDate = getCurrentDate();

  const formatDate = (date) => {
    // Format the date using dayjs
    const formattedDate = dayjs(date).format("DD-MM-YYYY");
    // Set the formatted date to the state
    setDateNameFilter(formattedDate);
    setSelectedDate(date);
  };

  const clearDateFilter = () => {
    // Clear the date filter
    setDateNameFilter("");
    setSelectedDate(null);
    // alert(dateNameFilter);
  };



  const currentData = !lead
    ? !checked
      ? filteredData1?.filter(
          (item) =>
            item?.status === "Overdue" ||
            item?.status === "Pending" ||
            item?.status === "In Progress"
        )
      : filteredData1
    : !checked
    ? leadData?.filter(
        (item) =>
          item?.status === "Overdue" ||
          item?.status === "Pending" ||
          item?.status === "In Progress" ||
          item?.status === "Discard"
      )
    : leadData?.filter(
        (item) => item?.status === "Done" || item?.status === "Ready For Q/A"
      );

      // Capitalizing the user name

  currentData?.map((item) => {
    const nameParts = item?.assigned_to_username?.split(" ");
    const firstName = nameParts[0];
    const lastName =
      nameParts?.length > 1
        ? nameParts[nameParts?.length - 1]?.slice(0, 1) + "."
        : "";
    const formattedName = `${firstName} ${lastName}`;
    const formattedNameCapitalized =
      formattedName?.charAt(0)?.toUpperCase() + formattedName?.slice(1);
    item["assigned_to_username"] = formattedNameCapitalized;
  });

  // Filtering tasks based on priority

  const criticalData = currentData?.filter((item) => {
    return item?.new === "4";
  });

  const highData = currentData?.filter((item) => {
    return item?.new === "3";
  });

  const mediumData = currentData?.filter((item) => {
    return item?.new === "2";
  });

  const lowData = currentData?.filter((item) => {
    return item?.new === "1";
  });

  // Checking that any task is available to perform today or not

  let criticalTodayTask = false;

  criticalData?.map((item) => {
    if (item?.due_date?.toLowerCase()?.includes(todaysDate.toLowerCase())) {
      criticalTodayTask = true;
    }
  });

  let highTodayTask = false;

  highData?.map((item) => {
    if (item?.due_date?.toLowerCase()?.includes(todaysDate.toLowerCase())) {
      highTodayTask = true;
    }
  });

  let mediumTodayTask = false;

  mediumData?.map((item) => {
    if (item?.due_date?.toLowerCase()?.includes(todaysDate.toLowerCase())) {
      mediumTodayTask = true;
    }
  });

  let lowTodayTask = false;

  lowData?.map((item) => {
    if (item?.due_date?.toLowerCase()?.includes(todaysDate.toLowerCase())) {
      lowTodayTask = true;
    }
  });

  

 

  useEffect(() => {

    if(users){
      const fetchedEmployeeOptions = users?.filter((employee) => employee?.role_name !== "Client")?.map((employee) => ({
        value: employee?.id,
        label: employee?.name,
        role_name: employee?.role_name,
      }));
    setEmployeeList(fetchedEmployeeOptions);
    }

    

    if(completedTask){
      const finalData = completedTask?.map((item) => {
        item["new"] = item?.priority;
        const splitDate = item?.due_date?.split("T")[0];
        if(splitDate){
        const dateParts = splitDate?.split("-");
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];
        item["due_date"] = `${day}-${month}-${year}`;
        }
        return item;
      }) 
      setCompletedTasks(finalData);
  
    }

    

    if(Array.isArray(leadTask)){
      const updatedLeadData =  leadTask?.map((item) => {
        item["new"] = item?.priority;
        const splitDate = item?.due_date?.split("T")[0];
        if(splitDate){
        const dateParts = splitDate?.split("-");
        const day = dateParts[2];
        const month = dateParts[1];
        const year = dateParts[0];
        item["due_date"] = `${day}-${month}-${year}`;
        }
        return item;
      });
      setLeadTasks(updatedLeadData);
    }


     

      if(projectDetails){
        const fetchedProjectOptions = projectDetails?.map((project) => ({
          value: project?.project_id,
          label: project?.project_name,
        }));
        setProjectList(fetchedProjectOptions);
      }


  }, [users, completedTask, leadTask, projectDetails]);

 

  const statusOptions1 = [
    { value: "Pending", label: "Pending" },
    { value: "In Progress", label: "In Progress" },
    { value: "Discard", label: "Discard" },
    { value: "Overdue", label: "Overdue" },
  ];

  const statusOptions2 = [
    { value: "Ready For Q/A", label: "Ready For Q/A" },
    { value: "Done", label: "Done" },
  ];


  // Formatting date to show like jan, feb etc. in tasks list
  function convertToDate(dateString) {
    const dateParts = dateString?.split("-");
    if(dateParts?.length > 0){
    const day = dateParts[0];
    const monthIndex = parseInt(dateParts[1], 10) - 1; // months are zero-based in JavaScript
    const year = dateParts[2];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = months[monthIndex];
    return `${day} ${monthName} ${year}`;
    }
  }



  function convertToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`;
}


  return (
    <div>
      <div className={styles.reportContainer}>
        {
           userDetails?.role === "4" && (
            <div className={styles.toggleContainer}>
          <div>My Tasks</div>
          <div>
            <label className="switch">
              <input
                type="checkbox"
                checked={lead}
                onChange={() => setLead(!lead)}
              />
            </label>
          </div>
          <div>Team Tasks</div>
        </div>
           )
        }
        <div className={styles.toggleContainer}>
          {
             userDetails?.role !== "3" && (
              <>
              <div>To Do Tasks</div>
          <div>
            <label className="switch">
              <input
                type="checkbox"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            </label>
          </div>
          <div>Completed Tasks</div></>
             )
          }
        </div>
        <div style={{display:'flex', alignItems:'center'}}>
          <div style={{position:'relative', display:'flex', alignItems:'center'}}>
          <div style={{ position: "absolute", right: "20%", cursor: "pointer", zIndex: "1" }} onClick={clearDateFilter}>
        {
           selectedDate !== null && (
            <IoIosClose size={20} />
           )
        }
      </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker value={selectedDate} onChange={(newDate) => formatDate(newDate)} />
          </LocalizationProvider>
          </div>
        </div>
      </div>
      <div className="d-block d-md-flex " style={{ gap: "5px" }}>
        <Select
          className="form-control p-0 border-0 custom-style "
          value={projectList?.find(
            (project) => project.label === projectNameFilter
          )}
          onChange={(selectedOption) =>
            setProjectNameFilter(selectedOption ? selectedOption.label : "")
          }
          options={projectList}
          placeholder="Select Project"
          isSearchable
          isClearable={true}
          styles={{
            control: (styles) => ({
              ...styles,
              fontSize: "1rem",
            }),
            menu: (provided) => ({
              ...provided,
              overflowY: "auto",
            }),
            option: (provided) => ({
              ...provided,
              fontSize: "1rem",
              // backgroundColor:'blue'
            }),
          }}
        />

        {
          userDetails?.role !== "3" && (
            <Select
          className="form-control p-0 border-0 custom-style "
          value={employeeList?.find(
            (employee) => employee.label === employeeNameFilter
          )}
          onChange={(selectedOption) => {
            if (selectedOption) {
              const nameParts = selectedOption?.label.split(" ");
              if (nameParts && nameParts.length > 0) {
                const firstName = nameParts[0];
                const lastName =
                  nameParts.length > 1
                    ? nameParts[nameParts.length - 1].slice(0, 1) + "."
                    : "";
                const formattedName = `${firstName} ${lastName}`;
                const formattedNameCapitalized =
                  formattedName.charAt(0).toUpperCase() +
                  formattedName.slice(1);
                setEmployeeNameFilter(formattedNameCapitalized);
              }
            } else {
              setEmployeeNameFilter("");
            }
          }}
          options={employeeList}
          placeholder="Select Employee"
          isSearchable
          isClearable={true}
          styles={{
            control: (styles) => ({
              ...styles,
              fontSize: "1rem",
            }),
            menu: (provided) => ({
              ...provided,
              overflowY: "auto",
            }),
            option: (provided) => ({
              ...provided,
              fontSize: "1rem",
            }),
          }}
        />
          )
        }

        <Select
          className="form-control form-control p-0 border-0  custom-style"
          value={
            !checked
              ? statusOptions1?.find((option) => option.value === statusFilter)
              : statusOptions2?.find((option) => option.value === statusFilter)
          }
          onChange={(selectedOption) =>
            setStatusFilter(selectedOption ? selectedOption.value : "")
          }
          options={!checked ? statusOptions1 : statusOptions2}
          isSearchable
          placeholder="Select Status"
          isClearable={true}
          styles={{
            control: (styles) => ({
              ...styles,
              fontSize: "1rem",
            }),

            menu: (provided) => ({
              ...provided,
              // maxHeight: "150px",
              overflowY: "auto",
            }),
            option: (provided) => ({ 
              ...provided,
              fontSize: "1rem",
            }),
          }}
        />

        {
          userDetails?.role !== "3" && (
            <Select
          className="form-control p-0 border-0 custom-style "
          value={departmentList.find(
            (department) => department.label === departmentNameFilter
          )}
          onChange={(selectedOption) =>
            setDepartmentNameFilter(selectedOption ? selectedOption.label : "")
          }
          options={departmentList.map((department) => ({
            value: department.id,
            label: department.name,
          }))}
          placeholder="Select Department"
          isSearchable
          isClearable={true}
          styles={{
            control: (styles) => ({
              ...styles,
              fontSize: "1rem",
            }),
            menu: (provided) => ({
              ...provided,
              overflowY: "auto",
            }),
            option: (provided) => ({
              ...provided,
              fontSize: "1rem",
            }),
          }}
        />
          )
        }
      </div>
      <div style={{ marginTop: "20px", overflowX: "auto" }}>
        {currentData?.length > 0 ? (
          <div className={styles.priorityContainer}>
            <div className={styles.priorityCritical}>
              <span className={styles.critical}>
                <span className={styles.flexHeader}>
                  <span>Critical</span>
                  <span className={styles.count} style={{ color: "#ff5c5c" }}>
                    {criticalData.length}
                  </span>
                </span>
              </span>
              <div className={styles.parentCard}>

                {/* It will be shown only if there is no tasks to perform today */}
                {!checked && !criticalTodayTask && (
                  <>
                    <div className={styles.date}>
                      {convertToDate(todaysDate)}
                    </div>
                    <div
                      className="alert alert-warning text-center"
                      role="alert"
                      style={{ fontSize: "10px", padding: "5px 1.25rem" }}
                    >
                      No Task Available To Perform !
                    </div>
                  </>
                )}
                {criticalData.map((item) => (
                  <>
                    {item?.due_date !== date1 &&
                      ((date1 = item?.due_date),
                      (
                        <div className={styles.date}>
                          {convertToDate(item?.due_date)}
                        </div>
                      ))}
                    <div
                      className={styles.card}
                      style={{
                        // boxShadow: item?.status === "Overdue" ? '#ffabb3e0 -6px 0px 2px -2px' : ""
                        borderLeft:
                          item?.status === "Overdue"
                            ? "4px solid #ffabb3e0"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "10px",
                            color: "gray",
                          }}
                        >
                          <div>{item?.project_name}</div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              {convertToDate(item?.due_date)} |{" "}
                              {/* {(item.estimated_hours / 60).toFixed(2)} Hrs */}
                              {convertToHoursAndMinutes(item?.estimated_hours)} Hrs
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "start",
                            padding: "10px 0px",
                          }}
                        >
                          <span>{item?.category_name}</span>
                          {/* <span> | </span>
                        <span>{item?.sub_category}</span> */}
                          <span> | </span>
                          <span>{item?.title}</span>
                        </div>
                        {/* <div>{item?.project_name}</div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          paddingTop: "0px",
                          fontSize: "10px",
                          color: "gray",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d4f1db",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.status}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#d0f2fd",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.assigned_to_username}
                        </div>
                        <Link
                          key="viewButton##1"
                          type="button"
                          to={`/admin/project-ticket-list/view/${item?.id}`}
                          // className="btn btn-icon btn-outline-success mr-1 mb-1"
                          className={styles.button}
                          title="View"
                        >
                          <FaEye />
                          {/* <MdKeyboardArrowRight /> */}
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className={styles.priorityHigh}>
              <span className={styles.high}>
                <span className={styles.flexHeader}>
                  <span>High</span>
                  <span className={styles.count} style={{ color: "#ff952b" }}>
                    {highData.length}
                  </span>
                </span>
              </span>

              <div className={styles.parentCard}>
                {!checked && !highTodayTask && (
                  <>
                    <div className={styles.date}>
                      {convertToDate(todaysDate)}
                    </div>
                    <div
                      className="alert alert-warning text-center"
                      role="alert"
                      style={{ fontSize: "10px", padding: "5px 1.25rem" }}
                    >
                      No Task Available To Perform !
                    </div>
                  </>
                )}
                {highData.map((item) => (
                  <>
                    {item?.due_date !== date2 &&
                      ((date2 = item?.due_date),
                      (
                        <div className={styles.date}>
                          {convertToDate(item?.due_date)}
                        </div>
                      ))}
                    <div
                      className={styles.card}
                      style={{
                        // boxShadow: item?.status === "Overdue" ? '#ffabb3e0 -6px 0px 2px -2px' : ""
                        borderLeft:
                          item?.status === "Overdue"
                            ? "4px solid #ffabb3e0"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "10px",
                            color: "gray",
                          }}
                        >
                          <div>{item?.project_name}</div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              {convertToDate(item?.due_date)} |{" "}
                              {convertToHoursAndMinutes(item?.estimated_hours)} Hrs
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "start",
                            // alignItems: "center",
                            // display: "flex",
                            // gap: "10px",
                            // flexWrap:'wrap',
                            padding: "10px 0px",
                          }}
                        >
                          <span>{item?.category_name}</span>

                          <span> | </span>
                          <span>{item?.title}</span>
                        </div>
                        {/* <div>{item?.project_name}</div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          paddingTop: "0px",
                          fontSize: "10px",
                          color: "gray",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d4f1db",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.status}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#d0f2fd",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.assigned_to_username}
                        </div>
                        <Link
                          key="viewButton##1"
                          type="button"
                          to={`/admin/project-ticket-list/view/${item?.id}`}
                          // className="btn btn-icon btn-outline-success mr-1 mb-1"
                          className={styles.button}
                          title="View"
                        >
                          <FaEye />
                          {/* <MdKeyboardArrowRight /> */}
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className={styles.priorityMedium}>
              <span className={styles.medium}>
                <span className={styles.flexHeader}>
                  <span>Medium</span>
                  <span className={styles.count} style={{ color: "#f6c32d" }}>
                    {mediumData.length}
                  </span>
                </span>
              </span>
              <div className={styles.parentCard}>
                {!checked && !mediumTodayTask && (
                  <>
                    <div className={styles.date}>
                      {convertToDate(todaysDate)}
                    </div>
                    <div
                      className="alert alert-warning text-center"
                      role="alert"
                      style={{ fontSize: "10px", padding: "5px 1.25rem" }}
                    >
                      No Task Available To Perform !
                    </div>
                  </>
                )}
                {mediumData.map((item) => (
                  <>
                    {item?.due_date !== date3 &&
                      ((date3 = item?.due_date),
                      (
                        <div className={styles.date}>
                          {convertToDate(item?.due_date)}
                        </div>
                      ))}
                    <div
                      className={styles.card}
                      style={{
                        // boxShadow: item?.status === "Overdue" ? '#ffabb3e0 -6px 0px 2px -2px' : ""
                        borderLeft:
                          item?.status === "Overdue"
                            ? "4px solid #ffabb3e0"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "10px",
                            color: "gray",
                          }}
                        >
                          <div>{item?.project_name}</div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              {convertToDate(item?.due_date)} |{" "}
                              {convertToHoursAndMinutes(item?.estimated_hours)} Hrs
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "start",
                            // alignItems: "center",
                            // display: "flex",
                            // gap: "10px",
                            // flexWrap:'wrap',
                            padding: "10px 0px",
                          }}
                        >
                          <span>{item?.category_name}</span>

                          <span> | </span>
                          <span>{item?.title}</span>
                        </div>
                        {/* <div>{item?.project_name}</div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          paddingTop: "0px",
                          fontSize: "10px",
                          color: "gray",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d4f1db",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.status}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#d0f2fd",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.assigned_to_username}
                        </div>
                        <Link
                          key="viewButton##1"
                          type="button"
                          to={`/admin/project-ticket-list/view/${item?.id}`}
                          // className="btn btn-icon btn-outline-success mr-1 mb-1"
                          className={styles.button}
                          title="View"
                        >
                          <FaEye />
                          {/* <MdKeyboardArrowRight /> */}
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className={styles.priorityLow}>
              <span className={styles.low}>
                <span className={styles.flexHeader}>
                  <span>Low</span>
                  <span className={styles.count} style={{ color: "#308cd0" }}>
                    {lowData.length}
                  </span>
                </span>
              </span>
              <div className={styles.parentCard}>
                {!checked && !lowTodayTask && (
                  <>
                    <div className={styles.date}>
                      {convertToDate(todaysDate)}
                    </div>
                    <div
                      className="alert alert-warning text-center"
                      role="alert"
                      style={{ fontSize: "10px", padding: "5px 1.25rem" }}
                    >
                      No Task Available To Perform !
                    </div>
                  </>
                )}
                {lowData.map((item) => (
                  <>
                    {item?.due_date !== date4 &&
                      ((date4 = item?.due_date),
                      (
                        <div className={styles.date}>
                          {convertToDate(item?.due_date)}
                        </div>
                      ))}
                    <div
                      className={styles.card}
                      style={{
                        // boxShadow: item?.status === "Overdue" ? '#ffabb3e0 -6px 0px 2px -2px' : ""
                        borderLeft:
                          item?.status === "Overdue"
                            ? "4px solid #ffabb3e0"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "10px",
                            color: "gray",
                          }}
                        >
                          <div>{item?.project_name}</div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              {convertToDate(item?.due_date)} |{" "}
                              {convertToHoursAndMinutes(item?.estimated_hours)} Hrs
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "start",
                            padding: "10px 0px",
                          }}
                        >
                          <span>{item?.category_name}</span>

                          <span> | </span>
                          <span>{item?.title}</span>
                        </div>
                        {/* <div>{item?.project_name}</div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          paddingTop: "0px",
                          fontSize: "10px",
                          color: "gray",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d4f1db",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.status}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#d0f2fd",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.assigned_to_username}
                        </div>
                        <Link
                          key="viewButton##1"
                          type="button"
                          to={`/admin/project-ticket-list/view/${item?.id}`}
                          // className="btn btn-icon btn-outline-success mr-1 mb-1"
                          className={styles.button}
                          title="View"
                        >
                          <FaEye />
                          {/* <MdKeyboardArrowRight /> */}
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="alert alert-warning text-center" role="alert">
            No Task Available To Perform !
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTable;