import React, { useEffect, useRef, useState, useContext } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import Swal from "sweetalert2";
import Select from "react-select";
import styles from "./report.module.css";
import { successToast, errorToast } from "../../../utils/Toaster";
import ExcelExportComponent from "./ExcelReport";
import GenerateReportButton from "./PdfReport";

const Reports = () => {
  const [employeeList, setEmployeeList] = useState([]);

  const [projectList, setProjectList] = useState([]);
  const [reportList, setReportList] = useState([
    { id: 1, name: "Project Report" },
    { id: 2, name: "Employee Report" },
  ]);

  const [reportTypeList, setReportTypeList] = useState([
    { id: 1, name: "Webanix Report" },
    { id: 2, name: "Client Report" },
  ]);
  const [reportData, setReportData] = useState([]);
  const [reportTime, setReportTime] = useState({});

  const [reportType, setReportType] = useState("");
  const [reportFormatList, setReportFormatList] = useState([
    { id: 1, name: "Generate Excel" },
    { id: 2, name: "Generate Pdf" },
  ]);
  const [reportFormat, setReportFormat] = useState("");

  const [reportName, setReportName] = useState("");
  let userDetails = {};
  if (localStorage.getItem("userData")) {
    userDetails = JSON.parse(localStorage.getItem("userData"));
  }

  useEffect(() => {
    if (reportType === "Employee Report") {
      axiosPrivate
        .get("users")
        .then((response) => {
          const fetchedEmployeeOptions = response.data.data
            .filter((employee) => employee.role_name !== "Client")
            .map((employee) => ({
              value: employee.id,
              label: employee.name,
              role_name: employee.role_name,
            }));
          setEmployeeList(fetchedEmployeeOptions);
        })
        .catch((error) => {
          errorToast(error?.response?.data?.message);
        });
    }

    if (reportType === "Project Report") {
      axiosPrivate
        .get("projects")
        .then((projectList) => {
          // setProjectList(projectList?.data?.data);
          const fetchedProjectOptions = projectList.data.data.map(
            (project) => ({
              value: project.project_id,
              label: project.project_name,
            })
          );
          setProjectList(fetchedProjectOptions);
        })
        .catch((error) => {
          // Handle errors
          errorToast(error);
        });
    }
  }, [reportType]);

  const setReport = (type) => {
    setReportType(type);
    setReportName("");
    setReportData([]);
  };

  const projectReportData = (id) => {
    setReportData([]);
    ReportFormat("Hide Button");
    if (id) {
      axiosPrivate
        .get(`report-by-project/${id}`)
        .then((projectReport) => {
          // Assuming projectReport?.data?.data?.projectDetails?.report is an array of objects
          const reportData = projectReport?.data?.data?.projectDetails?.report;
          

          // Check if reportData is defined and is an array
          if (Array.isArray(reportData)) {
            // Sort the data in descending order by the 'estimated_time' field
          }

          // Set the sorted data
          setReportData(
            reportData.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            )
          );
          setReportTime(projectReport?.data?.data?.projectDetails);
        })
        .catch((error) => {
          // Handle errors
          errorToast(error);
        });
    }
  };

  const employeeReportData = (id) => {
    setReportData([]);
    ReportFormat("Hide Button");
    if (id) {
      axiosPrivate
        .get(`report-by-employee/${id}`)
        .then((employeeReport) => {
          setReportData(employeeReport?.data?.data?.projectDetails?.report);
          
          
        })
        .catch((error) => {
          // Handle errors
          errorToast(error);
        });
    }
  };

  const ReportFormat = (format) => {
    setReportFormat(format);
  };

  return (
    <div>
      {userDetails.role === "1" && (
        <div className={styles.reportContainer}>
          <div className="form-control p-0 border-0 custom-style">
            <Select
              className="form-control p-0 border-0 custom-style"
              onChange={(selectedOption) =>
                // setReportType(selectedOption ? selectedOption.name : "")
                setReport(selectedOption ? selectedOption?.label : "")
              }
              options={reportList.map((report) => ({
                value: report?.id,
                label: report?.name,
              }))}
              placeholder="Generate Report"
              isSearchable
              isClearable={true}
              styles={{
                control: (styles) => ({
                  ...styles,
                  fontSize: "1rem",
                }),
                menu: (provided) => ({
                  ...provided,
                  overflowY: "auto",
                }),
                option: (provided) => ({
                  ...provided,
                  fontSize: "1rem",
                }),
              }}
            />
          </div>

          {reportType === "Project Report" && (
            <div className="form-control p-0 border-0 custom-style">
              <Select
                className="form-control p-0 border-0 custom-style"
                onChange={(selectedOption) =>
                  // setReportType(selectedOption ? selectedOption.name : "")
                  setReportName(selectedOption ? selectedOption?.label : "")
                }
                options={reportTypeList.map((report) => ({
                  value: report?.id,
                  label: report?.name,
                }))}
                placeholder="Report Type"
                isSearchable
                isClearable={true}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    fontSize: "1rem",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    overflowY: "auto",
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: "1rem",
                  }),
                }}
              />
            </div>
          )}

          <div className="form-control p-0 border-0 custom-style">
            {reportName && reportType === "Project Report" && (
              <Select
                className="form-control p-0 border-0 custom-style"
                onChange={(selectedOption) =>
                  projectReportData(selectedOption ? selectedOption.value : "")
                }
                options={projectList}
                placeholder="Project Name"
                isSearchable
                isClearable={true}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    fontSize: "1rem",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    overflowY: "auto",
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: "1rem",
                  }),
                }}
              />
            )}

            {reportType === "Employee Report" && (
              <Select
                className="form-control p-0 border-0 custom-style"
                onChange={(selectedOption) =>
                  employeeReportData(
                    selectedOption ? selectedOption?.value : ""
                  )
                }
                options={employeeList}
                placeholder="Employee Name"
                isSearchable
                isClearable={true}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    fontSize: "1rem",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    overflowY: "auto",
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: "1rem",
                  }),
                }}
              />
            )}
          </div>

          <div className="form-control p-0 border-0 custom-style">
            {reportData?.length > 0 && (
              <Select
                className="form-control p-0 border-0 custom-style"
                onChange={(selectedOption) =>
                  ReportFormat(selectedOption ? selectedOption?.label : "")
                }
                options={reportFormatList?.map((format) => ({
                  value: format?.id,
                  label: format?.name,
                }))}
                placeholder="Report Format"
                isSearchable
                isClearable={true}
                styles={{
                  control: (styles) => ({
                    ...styles,
                    fontSize: "1rem",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    overflowY: "auto",
                  }),
                  option: (provided) => ({
                    ...provided,
                    fontSize: "1rem",
                  }),
                }}
              />
            )}
          </div>
          <div
            className=" p-0 border-0 custom-style"
            style={{ whiteSpace: "nowrap" }}
          >
            {reportData?.length > 0 && reportFormat === "Generate Excel" && (
              <ExcelExportComponent
                data={reportData}
                reportType={
                  reportType === "Project Report" ? "project" : "employee"
                }
                reportName={reportName}
                reportTime={reportTime}
              />
            )}
            {reportData?.length > 0 && reportFormat === "Generate Pdf" && (
              <GenerateReportButton
                data={reportData}
                reportType={reportType}
                reportTime={reportTime}
                reportName={reportName}
              />
            )}
            {/* {reportData.length > 0 && reportType === "Project Report" && reportBtn === "Project" && <ProjectCSV userData={reportData} />} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Reports;
