import axiosPrivate from "../../../hooks/axiosPrivate";
import Ckeditor from "../../../components/common/ckeditor";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import classes from "../TicketList/TicketList.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { errorToast, successToast } from "../../../utils/Toaster";

const validationRules = {};

const AddProjectTicketDetails = (props) => {
  const { fixNavbar, boxClose, box2Close, box3Close } = props;
  const [editorContent, setEditorContent] = useState("");
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [team, setTeam] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [projectEmployees, setProjectEmployees] = useState([]);
  const [projectTasks, setProjectTasks] = useState([]);
  const [raiseByUserId, setRaiseByUserId] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      setRaiseByUserId(userData?.data?.userId);
    }
  }, []);

  const handleInternalButtonClick = (e) => {
    e.preventDefault();
    setSelectedButton("internal");
  };

  const handleExternalButtonClick = (e) => {
    e.preventDefault();
    setSelectedButton("external");

    if (selectedTeam) {
      const selectedTeamUsers = selectedTeam.members.map((member) => ({
        value: member.user_id,
        label: member.user_name,
        role_name: member.role_name,
      }));
      formik.setFieldValue("employee_user_id", selectedTeamUsers);
    }
  };

  const handleEditorChange = (value) => {
    setEditorContent(value);
  };

  function stripHtml(html) {
    var doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  useEffect(() => {
    axiosPrivate
      .get("team")
      .then((team) => {
        setTeam(team?.data?.data);
      })
      .catch((error) => {
      });

    axiosPrivate
      .get("projects")
      .then((projects) => {
        setProjects(projects?.data?.data);
      })
      .catch((error) => {
      });

    axiosPrivate
      .get("clients")
      .then((user_roles) => {
        setClients(user_roles?.data?.data);
      })
      .catch((error) => {
      });

    axiosPrivate
      .get("users")
      .then((response) => {
        const fetchedEmployeeOptions = response.data.data.map((employee) => ({
          value: employee.id,
          label: employee.name,
          role_name: employee.role_name,
        }));
        setEmployeeOptions(fetchedEmployeeOptions);
      })
      .catch((error) => {
      });  
  }, []);

  const formik = useFormik({
    initialValues: {
      team_name: "",
      project_id: "",
      client_user_id: "",
      employee_user_id: [],
      priority: "",
      subject: "",
      status: "",
      description: "",
      task_id: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const selectedEmployees = formik.values.employee_user_id;
      const cleanedDescription = stripHtml(editorContent);
      const requestData = {
        project_id: values.project_id,
        subject: values.subject,
        description: cleanedDescription,
        internal_external: selectedButton === "internal" ? 0 : 1,
        priority: values.priority,
        status: values.status,
        user_id: selectedEmployees.includes("all")
          ? employeeOptions.map((employee) => employee.value)
          : selectedEmployees.map((employee) => employee.value),
        raised_by_user_id: raiseByUserId,
        task_id: values.task_id,
      };

      try {
        const response = await axiosPrivate.post("ticket/raise", requestData);
        successToast("Ticket Created Successfully");
        navigate("/admin/project-ticket-details");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            setFieldError(
              error.response.data.errors[i].param,
              error.response.data.errors[i].msg
            );
          }
        }
      }
    },
  });

  const fetchEmployeesByProjectId = async (project_id) => {
    try {
      const responseEmployees = await axiosPrivate.get(
        `project/emp-by-project-id/${project_id}`
      );
      const employees = responseEmployees?.data?.data || [];
      setProjectEmployees(employees);
  
      formik.setFieldValue("employee_user_id", []);
  
      // Now, fetch tasks
      try {
        const responseTasks = await axiosPrivate.get(`tasks-by-project/${project_id}`);
  
        const tasks = await responseTasks?.data?.data;
  
        setProjectTasks(tasks);
        formik.setFieldValue("task_id", "");
      } catch (errorTasks) {
      }
  
    } catch (errorEmployees) {
    }
  };
  


 

  const handleProjectChange = (selectedProjectId) => {
    formik.handleChange("project_id")(selectedProjectId);
    fetchEmployeesByProjectId(selectedProjectId);
    setSelectedProjectId(selectedProjectId);
  };

  return (
    <div className={`section-body ${fixNavbar ? "marginTop" : ""} mt-3`}>
      <div className="container-fluid">
        <div className="row clearfix">
          <div className="col-lg-8 col-md-12 col-sm-12 mx-auto">
            <form onSubmit={formik.handleSubmit}>
              <div className="card">
                <div className="card-body text-center">
                  <button
                    onClick={handleInternalButtonClick}
                    className={`btn btn-primary mr-2 mb-2 ${
                      selectedButton === "internal" ? "active" : ""
                    }`}
                  >
                    Internal
                  </button>
                  <button
                    onClick={handleExternalButtonClick}
                    className={`btn btn-primary mb-2 ${
                      selectedButton === "external" ? "active" : ""
                    }`}
                  >
                    External
                  </button>

                  {selectedButton === "internal" && (
                    <>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="project_id"
                            name="project_id"
                            onChange={(e) =>
                              handleProjectChange(e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.project_id}
                          >
                            <option>Select Project</option>
                            {projects.map((project, key) => (
                              <option
                                key={key + project.title}
                                value={project.id}
                              >
                                {project.title}
                              </option>
                            ))}
                          </select>
                          {formik.touched.project_id &&
                          formik.errors.project_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.project_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <Select
                            id="employee_user_id"
                            name="employee_user_id"
                            isMulti
                            options={[
                              {
                                value: "all",
                                label: "Select All",
                                role_name: "Employee",
                              },
                              ...projectEmployees.map((employee) => ({
                                value: employee.id,
                                label: employee.name,
                                role_name: employee.role_name,
                              })),
                            ]}
                            onChange={(selectedOptions) => {
                              const allEmployeesOption = selectedOptions.find(
                                (option) =>
                                  option.value === "all" &&
                                  option.role_name === "Employee"
                              );

                              if (allEmployeesOption) {
                                formik.setFieldValue(
                                  "employee_user_id",
                                  projectEmployees.map((employee) => ({
                                    value: employee.id,
                                    label: employee.name,
                                    role_name: employee.role_name,
                                  }))
                                );
                              } else {
                                formik.setFieldValue(
                                  "employee_user_id",
                                  selectedOptions.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                    role_name: option.role_name,
                                  }))
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.employee_user_id}
                            placeholder={
                              formik.values.employee_user_id &&
                              formik.values.employee_user_id.some(
                                (option) => option.value === "all"
                              )
                                ? "Select All"
                                : "Assigned To"
                            }
                          />
                          {formik.touched.employee_user_id &&
                          formik.errors.employee_user_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.employee_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="priority"
                            name="priority"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.priority}
                          >
                            <option value="" disabled>
                              Select Priority
                            </option>
                            <option value="4">Critical</option>
                            <option value="3">High</option>
                            <option value="2">Medium</option>
                            <option value="1">Low</option>
                          </select>

                          {formik.touched.priority && formik.errors.priority ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.priority}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      

                   
                      <div className="col-lg-12">
                        <div className="form-group">
                          {/* Task dropdown */}
                          <select
                            className="form-control show-tick"
                            id="task_id"
                            name="task_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.task_id}
                          >
                            <option value="" disabled>
                              Select Task
                            </option>
                            {projectTasks?.map((task) => (
                              <option key={task.id} value={task.id}>
                                {task.title}
                              </option>
                            ))}
                          </select>
                          {formik.touched.task_id && formik.errors.task_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.task_id}
                            </div>
                          ) : null}
                        </div>
                      </div>


                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="subject"
                            name="subject"
                            placeholder="Subject"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.subject}
                          />
                          {formik.touched.subject && formik.errors.subject ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.subject}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                          >
                            <option value="" disabled>
                              Select Status
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Ready For Q/A">Ready For Q/A</option>
                            <option value="Done">Done</option>
                            <option value="Overdue">Overdue</option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.status}
                            </div> 
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                  {selectedButton === "external" && (
                    <>
                      {/* <div className="col-lg-12">
                      <div className="form-group">
                        <select
                          className="form-control show-tick"
                          id="client_user_id"
                          name="client_user_id"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.client_user_id}
                        >
                          <option>Select Client</option>
                          {clients.map((client, key) => (
                            <option
                              key={key + client.name}
                              value={client.id}
                            >
                              {client.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.client_user_id && formik.errors.client_user_id ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.client_user_id}
                          </div>
                        ) : null}
                      </div>
                    </div> */}
                      <div className="col-lg-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="project_id"
                            name="project_id"
                            onChange={(e) =>
                              handleProjectChange(e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.project_id}
                          >
                            <option>Select Project</option>
                            {projects.map((project, key) => (
                              <option
                                key={key + project.title}
                                value={project.id}
                              >
                                {project.title}
                              </option>
                            ))}
                          </select>
                          {formik.touched.project_id &&
                          formik.errors.project_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.project_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <Select
                            id="employee_user_id"
                            name="employee_user_id"
                            isMulti
                            options={[
                              {
                                value: "all",
                                label: "Select All",
                                role_name: "Employee",
                              },
                              ...projectEmployees.map((employee) => ({
                                value: employee.id,
                                label: employee.name,
                                role_name: employee.role_name,
                              })),
                            ]}
                            onChange={(selectedOptions) => {
                              const allEmployeesOption = selectedOptions.find(
                                (option) =>
                                  option.value === "all" &&
                                  option.role_name === "Employee"
                              );

                              if (allEmployeesOption) {
                                // Set employee_user_id to all employees for the current project
                                formik.setFieldValue(
                                  "employee_user_id",
                                  projectEmployees.map((employee) => ({
                                    value: employee.id,
                                    label: employee.name,
                                    role_name: employee.role_name,
                                  }))
                                );
                              } else {
                                // Set employee_user_id to selected employees
                                formik.setFieldValue(
                                  "employee_user_id",
                                  selectedOptions.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                    role_name: option.role_name,
                                  }))
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.employee_user_id}
                            placeholder={
                              formik.values.employee_user_id &&
                              formik.values.employee_user_id.some(
                                (option) => option.value === "all"
                              )
                                ? "Select All"
                                : "Assigned To"
                            }
                          />
                          {formik.touched.employee_user_id &&
                          formik.errors.employee_user_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.employee_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="priority"
                            name="priority"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.priority}
                          >
                            <option value="" disabled>
                              Select Priority
                            </option>
                            <option value="4">Critical</option>
                            <option value="3">High</option>
                            <option value="2">Medium</option>
                            <option value="1">Low</option>
                          </select>

                          {formik.touched.priority && formik.errors.priority ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.priority}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="subject"
                            name="subject"
                            placeholder="Subject"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.subject}
                          />
                          {formik.touched.subject && formik.errors.subject ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.subject}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          {/* Status dropdown */}
                          <select
                            className="form-control show-tick"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                          >
                            <option value="" disabled>
                              Select Status
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Ready For Q/A">Ready For Q/A</option>
                            <option value="Done">Done</option>
                            <option value="Discarded">Discard</option>
                            <option value="Overdue">Overdue</option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.status}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-lg-12">
                    <div className="form-group">
                      <ReactQuill
                        theme="snow"
                        value={editorContent}
                        onChange={handleEditorChange}
                      />
                      <div className="col-12">
                        <hr className="mt-4" />
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <tbody></tbody>
                          </table>
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Post
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary ml-2"
                          data-dismiss="modal"
                          onClick={() =>
                            navigate("/admin/project-ticket-details")
                          }
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProjectTicketDetails;
