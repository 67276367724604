import React, { useContext, useEffect, useState, useRef } from "react";
import { Formik, useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./addProjectStyle.module.css";
import { FaCheck, FaTimes } from "react-icons/fa";
import AddTeam from "../../hrms/Team/AddTeam";
import { errorToast, successToast } from "../../../utils/Toaster";
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendar } from "react-icons/bs";
import DatePicker from "react-datepicker";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
  title: Yup.string()
    .max(120, "Project Name must not exceed 120 characters")
    .required("Project Name is required"),

  // client_user_id: Yup.string()
  // .required('Client is required'),
  estimated_hours: Yup.string()
    .max(120, "Time must not exceed 30 characters")
    .required("Time is required"),
  type: Yup.string()
    .max(120, "Type must not exceed 30 characters")
    .required("Type is required"),
  client_user_id: Yup.string().required("Client Name is required"),
  due_date: Yup.string().required("Due date is required"),
};

const AddProject = (props) => {
  let { id } = useParams();

  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    axiosPrivate
      .get("clients")
      .then((user_roles) => {
        setClients(user_roles?.data?.data);
      })
      .catch((error) => {});
  }, []);

  const formik = useFormik({
    initialValues: {
      title: "",
      type: "",
      estimated_hours: "",
      client_user_id: "",
      due_date: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      // const existingProject = clients.find((client) => client.name === values.title);
      // if (existingProject) {
      //   setFieldError('title', 'Project name and client name cannot be same');
      //   return;
      // }

      const requestData = {
        title: values.title,
        type: values.type,
        estimated_hours: values.estimated_hours,
        client_user_id: values.client_user_id,
        due_date: values.due_date,
      };

      try {
        await axiosPrivate
          .post(`projects/add`, requestData)
          .then((response) => {
            if (response?.status === 201) {
              setFormSubmitted(true);
            }
            // successToast('Project added successfully');
            setProjectId(response?.data?.data?.insertId);
            formik.setFieldValue("project_id", response.data.data.insertId);
          });
      } catch (error) {
        if (error.response) {
          if (error.response.status === 422) {
            errorToast(error?.response?.data?.message);
            for (let i = 0; i < error.response.data.errors.length; i++) {
              setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const formatDate = (inputDateStr) => {
    if (!inputDateStr) {
      return null;
    }

    const parsedDate = new Date(inputDateStr);

    parsedDate.setHours(0, 0, 0, 0);

    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const projectNameValidate = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    formik.setFieldValue("title", value);
  };
  return (
    <div>
      <div className="section-body mt-3">
        <div>
          <div className="tab-content mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong>Add Project</strong>
                  </div>
                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            placeholder="Client Name*"
                            id="client_user_id"
                            name="client_user_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.client_user_id}
                            disabled={formSubmitted}
                          >
                            <option>Select Client</option>
                            {clients?.map((client, key) => (
                              <option key={key + client.name} value={client.id}>
                                {client.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.client_user_id &&
                          formik.errors.client_user_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.client_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Project Name *"
                            onChange={projectNameValidate}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                            disabled={formSubmitted}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.title}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <input
                            id="estimated_hours"
                            name="estimated_hours"
                            type="number"
                            className="form-control"
                            placeholder="Time(hours) *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.estimated_hours}
                            disabled={formSubmitted}
                          />
                          {formik.touched.estimated_hours &&
                          formik.errors.estimated_hours ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.estimated_hours}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            id="type"
                            name="type"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.type}
                            disabled={formSubmitted}
                          >
                            <option value="" disabled>
                              Select Project Type *
                            </option>
                            <option value="single">Single</option>
                            <option value="recurring">Recurring</option>
                          </select>
                          {formik.touched.type && formik.errors.type ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.type}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12">
                      <div className="form-group">
                        <div className="input-group datepickerwidth">
                          <DatePicker
                            className="form-control "
                            id="due_date"
                            name="due_date"
                            onChange={(date) => {
                              formik.setFieldValue(
                                "due_date",
                                date ? formatDate(date) : null
                              );
                            }}
                            placeholderText="Due Date (dd-mm-yyyy) *"
                            onBlur={formik.handleBlur}
                            selected={
                              formik.values.due_date
                                ? new Date(formik.values.due_date)
                                : null
                            }
                            disabled={formSubmitted}
                            dateFormat="dd-MM-yyyy"
                            minDate={new Date()}
                          />

                          <div className="input-group-append" style={{height:'32px'}}>
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() =>
                                document
                                  .getElementById(`due_date`)
                                  .click()
                              }
                            >
                              <BsCalendar />
                            </button>
                          </div>
                        </div>
                        {
                        formik.touched.due_date &&
                        
                        formik.errors.due_date ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.due_date}
                          </div>
                        ) : null}
                      </div>
                    </div>
                      
                    </div>

                    <div className="table-responsive">
                      <table className="table table-striped"></table>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary "
                      disabled={formSubmitted}
                    >
                      <FaCheck />
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary ml-2"
                      onClick={() => navigate("/admin/project-list")}
                      disabled={formSubmitted}
                    >
                      <FaTimes />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {formSubmitted && (
        <AddTeam project_id={projectId} project_name={formik?.values?.title} />
      )}
    </div>
  );
};

export default AddProject;
