import React, { useEffect, useState, useContext } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { getFirebaseToken, onForegroundMessage } from "./FirebaseConfig";
import addNotification from 'react-push-notification';
import { Notifications } from 'react-push-notification';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router
import AlertContext from "../../../context/Alert/AlertContext";

const NotificationHandler = () => {
  const navigate = useNavigate(); // Hook for navigation
  const {changeNotification} = useContext(AlertContext);

  

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then(async (firebaseToken) => {
        console.log("firebase token: ", firebaseToken);
        
       if(localStorage.getItem("userData")){
        await axiosPrivate.post("/notification", {
          register_id: firebaseToken
      });
       }
      })
      .catch((err) =>
        console.error("An error occurred while retrieving firebase token. ", err)
      );
  };

  useEffect(() => {
    handleGetFirebaseToken();
  }, []); // Add navigate as a dependency


  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        console.log("Received foreground message: ", payload);
        const {
          notification: { title, body },
          fcmOptions: { link }, // Assuming the link is in the payload data
        } = payload;

        addNotification({
          title: title,
          message: body,
          theme: 'light',
          native: true,
          onClick: () => {
            if (link) {
              navigate(link); // Use react-router's navigate to change the route
            }
          },
        });
      })
      .catch((err) =>
        console.log(
          "An error occurred while retrieving foreground message. ",
          err
        )
      );
  }, [changeNotification]); 

  return (
    <main>
      <Notifications />
    </main>
  );
};

export default NotificationHandler;