import React, { useEffect, useRef, useState } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import Select from "react-select";

import styles from "./TodoListFixedControls.module.css";
import "./toggle.css";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
const PriorityWiseTask = (props) => {
//   const PriorityWiseTask = (props) => {
 
  
  // const [filteredData1, setFilteredData1] = useState([]);
  // const [leadData, setLeadData] = useState([]);

  

  let date1 = "00-00-0000";
  let date2 = "00-00-0000";
  let date3 = "00-00-0000";
  let date4 = "00-00-0000";

  

  

  // Formatting date in dd-mm-yyyy format
  function getCurrentDate() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // January is 0!
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const todaysDate = getCurrentDate();

  



  const currentData = !props?.lead
    ? !props?.checked
      ? props?.filteredData?.filter(
          (item) =>
            item?.status === "Overdue" ||
            item?.status === "Pending" ||
            item?.status === "In Progress" ||
            item?.status === "On Hold"
        )
      : props?.filteredData
    : !props?.checked
    ? props?.leadData?.filter(
        (item) =>
          item?.status === "Overdue" ||
          item?.status === "Pending" ||
          item?.status === "In Progress" ||
          item?.status === "On Hold"
      )
    : props?.leadData?.filter(
        (item) => item?.status === "Done" || item?.status === "Ready For Q/A" || item?.status === "Ready For UAT"
      );

      // Capitalizing the user name

  currentData?.map((item) => {
    const nameParts = item?.assigned_to_username?.split(" ");
    const firstName = nameParts[0];
    const lastName =
      nameParts?.length > 1
        ? nameParts[nameParts?.length - 1]?.slice(0, 1) + "."
        : "";
    const formattedName = `${firstName} ${lastName}`;
    const formattedNameCapitalized =
      formattedName?.charAt(0)?.toUpperCase() + formattedName?.slice(1);
    item["assigned_to_username"] = formattedNameCapitalized;
  });


  // Filtering tasks based on priority

  const criticalData = currentData?.filter((item) => {
    return item?.new === "4";
  });

  const highData = currentData?.filter((item) => {
    return item?.new === "3";
  });

  const mediumData = currentData?.filter((item) => {
    return item?.new === "2";
  });

  const lowData = currentData?.filter((item) => {
    return item?.new === "1";
  });

  // Checking that any task is available to perform today or not

  let criticalTodayTask = false;

  criticalData?.map((item) => {
    if (item?.due_date?.toLowerCase()?.includes(todaysDate.toLowerCase())) {
      criticalTodayTask = true;
    }
  });

  let highTodayTask = false;

  highData?.map((item) => {
    if (item?.due_date?.toLowerCase()?.includes(todaysDate.toLowerCase())) {
      highTodayTask = true;
    }
  });

  let mediumTodayTask = false;

  mediumData?.map((item) => {
    if (item?.due_date?.toLowerCase()?.includes(todaysDate.toLowerCase())) {
      mediumTodayTask = true;
    }
  });

  let lowTodayTask = false;

  lowData?.map((item) => {
    if (item?.due_date?.toLowerCase()?.includes(todaysDate.toLowerCase())) {
      lowTodayTask = true;
    }
  });

 

 


  // Formatting date to show like jan, feb etc. in tasks list
  function convertToDate(dateString) {
    const dateParts = dateString?.split("-");
    if(dateParts?.length > 0){
    const day = dateParts[0];
    const monthIndex = parseInt(dateParts[1], 10) - 1; // months are zero-based in JavaScript
    const year = dateParts[2];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = months[monthIndex];
    return `${day} ${monthName} ${year}`;
    }
  }



  function convertToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes < 10 ? '0' : ''}${remainingMinutes}`;
}


  return (
    <div style={{ marginTop: "20px", overflowX: "auto" }}>
        {currentData?.length > 0 ? (
          <div className={styles.priorityContainer}>
            <div className={styles.priorityCritical}>
              <span className={styles.critical}>
                <span className={styles.flexHeader}>
                  <span>Critical</span>
                  <span className={styles.count} style={{ color: "#ff5c5c" }}>
                    {criticalData.length}
                  </span>
                </span>
              </span>
              <div className={styles.parentCard}>

                {/* It will be shown only if there is no tasks to perform today */}
                {!props?.checked && !criticalTodayTask && (
                  <>
                    <div className={styles.date}>
                      {convertToDate(todaysDate)}
                    </div>
                    <div
                      className="alert alert-warning text-center"
                      role="alert"
                      style={{ fontSize: "10px", padding: "5px 1.25rem" }}
                    >
                      No Task Available To Perform !
                    </div>
                  </>
                )}
                {criticalData.map((item) => (
                  <>
                    {item?.due_date !== date1 &&
                      ((date1 = item?.due_date),
                      (
                        <div className={styles.date}>
                          {convertToDate(item?.due_date)}
                        </div>
                      ))}
                    <div
                      className={styles.card}
                      style={{
                        // boxShadow: item?.status === "Overdue" ? '#ffabb3e0 -6px 0px 2px -2px' : ""
                        borderLeft:
                          item?.status === "Overdue"
                            ? "4px solid #ffabb3e0"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "10px",
                            color: "gray",
                          }}
                        >
                          <div>{item?.project_name}</div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              {convertToDate(item?.due_date)} 
                              {
                                 props?.role !== "3" && 
                                 <span> | {convertToHoursAndMinutes(item?.estimated_hours)} hrs</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "start",
                            padding: "10px 0px",
                          }}
                        >
                          <span>{item?.category_name}</span>
                          {/* <span> | </span>
                        <span>{item?.sub_category}</span> */}
                          <span> | </span>
                          <span>{item?.title}</span>
                        </div>
                        {/* <div>{item?.project_name}</div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          paddingTop: "0px",
                          fontSize: "10px",
                          color: "gray",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d4f1db",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.status}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#d0f2fd",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.assigned_to_username}
                        </div>
                        <Link
                          key="viewButton##1"
                          type="button"
                          to={`/admin/project-ticket-list/view/${item?.id}`}
                          // className="btn btn-icon btn-outline-success mr-1 mb-1"
                          className={styles.button}
                          title="View"
                        >
                          <FaEye />
                          {/* <MdKeyboardArrowRight /> */}
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className={styles.priorityHigh}>
              <span className={styles.high}>
                <span className={styles.flexHeader}>
                  <span>High</span>
                  <span className={styles.count} style={{ color: "#ff952b" }}>
                    {highData.length}
                  </span>
                </span>
              </span>

              <div className={styles.parentCard}>
                {!props?.checked && !highTodayTask && (
                  <>
                    <div className={styles.date}>
                      {convertToDate(todaysDate)}
                    </div>
                    <div
                      className="alert alert-warning text-center"
                      role="alert"
                      style={{ fontSize: "10px", padding: "5px 1.25rem" }}
                    >
                      No Task Available To Perform !
                    </div>
                  </>
                )}
                {highData.map((item) => (
                  <>
                    {item?.due_date !== date2 &&
                      ((date2 = item?.due_date),
                      (
                        <div className={styles.date}>
                          {convertToDate(item?.due_date)}
                        </div>
                      ))}
                    <div
                      className={styles.card}
                      style={{
                        // boxShadow: item?.status === "Overdue" ? '#ffabb3e0 -6px 0px 2px -2px' : ""
                        borderLeft:
                          item?.status === "Overdue"
                            ? "4px solid #ffabb3e0"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "10px",
                            color: "gray",
                          }}
                        >
                          <div>{item?.project_name}</div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              {convertToDate(item?.due_date)} 
                              {
                                 props?.role !== "3" && 
                                 <span> | {convertToHoursAndMinutes(item?.estimated_hours)} hrs</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "start",
                            // alignItems: "center",
                            // display: "flex",
                            // gap: "10px",
                            // flexWrap:'wrap',
                            padding: "10px 0px",
                          }}
                        >
                          <span>{item?.category_name}</span>

                          <span> | </span>
                          <span>{item?.title}</span>
                        </div>
                        {/* <div>{item?.project_name}</div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          paddingTop: "0px",
                          fontSize: "10px",
                          color: "gray",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d4f1db",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.status}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#d0f2fd",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.assigned_to_username}
                        </div>
                        <Link
                          key="viewButton##1"
                          type="button"
                          to={`/admin/project-ticket-list/view/${item?.id}`}
                          // className="btn btn-icon btn-outline-success mr-1 mb-1"
                          className={styles.button}
                          title="View"
                        >
                          <FaEye />
                          {/* <MdKeyboardArrowRight /> */}
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className={styles.priorityMedium}>
              <span className={styles.medium}>
                <span className={styles.flexHeader}>
                  <span>Medium</span>
                  <span className={styles.count} style={{ color: "#f6c32d" }}>
                    {mediumData.length}
                  </span>
                </span>
              </span>
              <div className={styles.parentCard}>
                {!props?.checked && !mediumTodayTask && (
                  <>
                    <div className={styles.date}>
                      {convertToDate(todaysDate)}
                    </div>
                    <div
                      className="alert alert-warning text-center"
                      role="alert"
                      style={{ fontSize: "10px", padding: "5px 1.25rem" }}
                    >
                      No Task Available To Perform !
                    </div>
                  </>
                )}
                {mediumData.map((item) => (
                  <>
                    {item?.due_date !== date3 &&
                      ((date3 = item?.due_date),
                      (
                        <div className={styles.date}>
                          {convertToDate(item?.due_date)}
                        </div>
                      ))}
                    <div
                      className={styles.card}
                      style={{
                        // boxShadow: item?.status === "Overdue" ? '#ffabb3e0 -6px 0px 2px -2px' : ""
                        borderLeft:
                          item?.status === "Overdue"
                            ? "4px solid #ffabb3e0"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "10px",
                            color: "gray",
                          }}
                        >
                          <div>{item?.project_name}</div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              {convertToDate(item?.due_date)}
                              {
                                 props?.role !== "3" && 
                                 <span> | {convertToHoursAndMinutes(item?.estimated_hours)} hrs</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "start",
                            // alignItems: "center",
                            // display: "flex",
                            // gap: "10px",
                            // flexWrap:'wrap',
                            padding: "10px 0px",
                          }}
                        >
                          <span>{item?.category_name}</span>

                          <span> | </span>
                          <span>{item?.title}</span>
                        </div>
                        {/* <div>{item?.project_name}</div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          paddingTop: "0px",
                          fontSize: "10px",
                          color: "gray",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d4f1db",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.status}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#d0f2fd",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.assigned_to_username}
                        </div>
                        <Link
                          key="viewButton##1"
                          type="button"
                          to={`/admin/project-ticket-list/view/${item?.id}`}
                          // className="btn btn-icon btn-outline-success mr-1 mb-1"
                          className={styles.button}
                          title="View"
                        >
                          <FaEye />
                          {/* <MdKeyboardArrowRight /> */}
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div className={styles.priorityLow}>
              <span className={styles.low}>
                <span className={styles.flexHeader}>
                  <span>Low</span>
                  <span className={styles.count} style={{ color: "#308cd0" }}>
                    {lowData.length}
                  </span>
                </span>
              </span>
              <div className={styles.parentCard}>
                {!props?.checked && !lowTodayTask && (
                  <>
                    <div className={styles.date}>
                      {convertToDate(todaysDate)}
                    </div>
                    <div
                      className="alert alert-warning text-center"
                      role="alert"
                      style={{ fontSize: "10px", padding: "5px 1.25rem" }}
                    >
                      No Task Available To Perform !
                    </div>
                  </>
                )}
                {lowData.map((item) => (
                  <>
                    {item?.due_date !== date4 &&
                      ((date4 = item?.due_date),
                      (
                        <div className={styles.date}>
                          {convertToDate(item?.due_date)}
                        </div>
                      ))}
                    <div
                      className={styles.card}
                      style={{
                        // boxShadow: item?.status === "Overdue" ? '#ffabb3e0 -6px 0px 2px -2px' : ""
                        borderLeft:
                          item?.status === "Overdue"
                            ? "4px solid #ffabb3e0"
                            : "",
                      }}
                    >
                      <div
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        <div
                          style={{
                            padding: "5px 10px",
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "10px",
                            color: "gray",
                          }}
                        >
                          <div>{item?.project_name}</div>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <div>
                              {convertToDate(item?.due_date)}
                              {
                                 props?.role !== "3" && 
                                 <span> | {convertToHoursAndMinutes(item?.estimated_hours)} hrs</span>
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "0px 10px",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "start",
                            padding: "10px 0px",
                          }}
                        >
                          <span>{item?.category_name}</span>

                          <span> | </span>
                          <span>{item?.title}</span>
                        </div>
                        {/* <div>{item?.project_name}</div> */}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px",
                          paddingTop: "0px",
                          fontSize: "10px",
                          color: "gray",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#d4f1db",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.status}
                        </div>
                        <div
                          style={{
                            backgroundColor: "#d0f2fd",
                            color: "#3b4447",
                            borderRadius: "20px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item.assigned_to_username}
                        </div>
                        <Link
                          key="viewButton##1"
                          type="button"
                          to={`/admin/project-ticket-list/view/${item?.id}`}
                          // className="btn btn-icon btn-outline-success mr-1 mb-1"
                          className={styles.button}
                          title="View"
                        >
                          <FaEye />
                          {/* <MdKeyboardArrowRight /> */}
                        </Link>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="alert alert-warning text-center" role="alert">
            No Task Available To Perform !
          </div>
        )}
      </div>
  );
};

export default PriorityWiseTask;