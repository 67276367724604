import React, { useState, useEffect } from "react";

const Search = ({ onSearch }) => {
    const [search, setSearch] = useState("");
    const [customWidth, setCustomWidth] = useState('240px');
    const onInputChange = value => {
        setSearch(value);
        onSearch(value);
    };


    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 576) {
                setCustomWidth('100%');
            } else {
                setCustomWidth('240px');
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [window.innerWidth]); 


    return (
        <input
            type="text"
            className="form-control"
            style={{ width: customWidth }}
            placeholder="Search"
            value={search}
            onChange={e => onInputChange(e.target.value)}
        />
    );
};

export default Search;
