import React, { useContext, useEffect, useState } from "react";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import AddTicketReply from "./AddTicketReply";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";
import { errorToast, successToast } from "../../../utils/Toaster";
import { MdOutlineAttachment } from "react-icons/md";

import { CgAttachment } from "react-icons/cg";

import "./TicketProjectList.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Dashboard/toggle.css";
const TicketList = (props) => {
  const { id } = useParams();
  const [ticketDetails, setTicketDetails] = useState([]);
  const [showReplyComponent, setShowReplyComponent] = useState(false);
  const navigate = useNavigate();
  const { loading, setIsLoading } = useContext(LoaderContext);
  const [ticketData, setTicketData] = useState([]);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [external, setExternal] = useState(false);

  let userId = "";
  if (localStorage.getItem("userData")) {
    const userData = JSON.parse(localStorage.getItem("userData"));
    userId = userData?.userId;
  }

  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  async function getData() {
    try {
      setIsLoading(true);
      const ticket = await axiosPrivate.post(`tickets-by-task/${id}`);
      const ticketData = await ticket.data.data[0];
      const thread_id = await ticketData.thread_id;
      const response = await axiosPrivate.post(`/tickets-by-thread`, {
        thread_id: thread_id,
      });
      setTicketData(ticketData);
      setTicketDetails(response.data.data);
      setIsLoading(false);
      // setAlertMessage({ message: "tickets fetched successfully" });
    } catch (error) {
      setIsLoading(false);
      errorToast("Error fetching tickets:", error);
    }
  }

  const handleIconClick = () => {
    setShowAttachmentModal(true);
  };

  const closeModal = () => {
    setShowAttachmentModal(false);
  };

  useEffect(() => {
    getData();
    window.scrollTo({ bottom: 0, behavior: "smooth" });
  }, []);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const optionsDate = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const optionsTime = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", optionsDate).format(
      date
    );
    const formattedTime = new Intl.DateTimeFormat("en-US", optionsTime).format(
      date
    );
    return `${formattedDate} | ${formattedTime}`;
  }

  const handleReplyButtonClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowReplyComponent(true);
  };

  const handleGoBack = () => {
    navigate(-1); // This will go back one step in the history
  };

  function convertToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes < 10 ? "0" : ""}${remainingMinutes}`;
  }


  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };
  
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="tab-content m-3">
          {showReplyComponent && (
            <AddTicketReply
              ticketDetails={ticketDetails}
              setShowReplyComponent={setShowReplyComponent}
            />
          )}
          <div
            className="tab-pane fade show active"
            id="user-list"
            role="tabpanel"
          >
            <div className="card">
              <div className="card-body">
                <div className="p-2 text-center"></div>
                <div className="header-wrapper">
                  <div className="msg-header headerContainer">
                    <ol
                      className="headerroutes breadcrumb m-0 bg-none leftContainer"
                      style={{ padding: "0rem" }}
                    >
                      <li className="item">
                        <span className="route-heading item ml-2">
                          {ticketDetails[0]?.title?.slice(0) &&
                            ticketDetails[0]?.title?.slice(0, 1).toUpperCase() +
                              ticketDetails[0]?.title?.slice(1)}
                        </span>
                      </li>

                      <li className="item color-gray ml-2">|</li>
                      <li className="item itemData">
                        <span className="route-heading item ml-2">
                          {ticketDetails[0]?.subject &&
                            truncateText(
                              ticketDetails[0]?.subject
                                .slice(0, 1)
                                .toUpperCase() +
                                ticketDetails[0]?.subject.slice(1),
                              50 /* Adjust the max length as needed */
                            )}
                        </span>
                      </li>
                      {ticketDetails[ticketDetails?.length - 1]?.status && (
                        <>
                          <li className="item color-gray ml-2">|</li>
                          <li className="item">
                            <span className="route-heading item ml-2">
                              {ticketDetails[ticketDetails?.length - 1]?.status}
                            </span>
                          </li>
                        </>
                      )}
                      {userData?.role !== "3" && (
                        <>
                          <li className="item color-gray ml-2">|</li>
                          <li className="item">
                            <span className="route-heading item ml-2">
                              {convertToHoursAndMinutes(
                                ticketData?.estimated_hours
                              )}{" "}
                              Hrs
                            </span>
                          </li>
                        </>
                      )}
                      <li className="item color-gray ml-2">|</li>
                      <li className="item positionContainer">
                        <span className="route-heading item ml-2">
                          {ticketDetails[ticketDetails?.length - 1]
                            ?.priority === "4" && (
                            <span className="status critical priorityButton">
                              Critical
                            </span>
                          )}
                          {ticketDetails[ticketDetails?.length - 1]
                            ?.priority === "3" && (
                            <span className="status high priorityButton">
                              High
                            </span>
                          )}
                          {ticketDetails[ticketDetails?.length - 1]
                            ?.priority === "1" && (
                            <span className="status low priorityButton">
                              Low
                            </span>
                          )}
                          {ticketDetails[ticketDetails?.length - 1]
                            ?.priority === "2" && (
                            <span className="status medium priorityButton">
                              Medium
                            </span>
                          )}
                        </span>
                      </li>
                    </ol>
                    <div className="mx-2">
                      {userData?.role !== "3" && (
                        <div className="toggleContainer2">
                          <div>Internal</div>
                          <div>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={external}
                                onChange={() => setExternal(!external)}
                                style={{ marginTop: "2px", position: "relative", top:"-8px" }}
                              />
                            </label>
                          </div>
                          <div>External</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="chat-page">
                  <div className="msg-inbox">
                    <div className="chats">
                      <div className="msg-page">
                        {userData?.role !== "3" &&
                          ticketDetails.length > 0 &&
                          ticketDetails
                            ?.filter((ticket) => {
                              if (external) {
                                return ticket?.internal_external === 1;
                              } else {
                                return ticket?.internal_external === 0;
                              }
                            })
                            .map((ticket) =>
                              external ? (
                                <>
                                  {console.log(ticket?.ticket_image)}
                                  {ticket?.raised_by_user_id != userId ? (
                                    <div className="received-chats ">
                                      <div className="d-flex align-items-baseline">
                                        <Avatar
                                          className="user-icon mr-2"
                                          label={ticket?.raised_by_user
                                            ?.charAt(0)
                                            .toUpperCase()}
                                          shape="circle"
                                          size="large"
                                        />
                                        <div className="activeP">
                                          <p>{ticket?.raised_by_user}</p>
                                        </div>
                                        <span className="time ml-2">
                                          {formatDate(ticket.created_at)}
                                        </span>
                                        <span className="time ml-2">
                                        {" | "}
                                        {convertToHoursAndMinutes(
                                                    ticket?.actual_time
                                                  )}{" "}
                                                  Hrs
                                        </span>
                                      </div>
                                      <div className="received-msg">
                                        <div className="received-msg-inbox">
                                          <div className="block">
                                            <div
                                              className="descriptionBottomMargin"
                                            >
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: ticket?.description,
                                                }}
                                              ></div>
                                              {ticket?.ticket_image != null && (
                                                <Link
                                                  to={`${process.env.REACT_APP_COMMON_IMAGE_URL}ticket/${ticket?.ticket_image}`}
                                                  target="_blank"
                                                  style={{
                                                    fontSize: "x-large",
                                                    display: "flex",
                                                    flexDirection:
                                                      "row-reverse",
                                                    color: "var(--primary)",
                                                    cursor: "pointer",
                                                  }}
                                                  title="View Attachment"
                                                >
                                                  <MdOutlineAttachment color="var(--primary)" />{" "}
                                                </Link>
                                              )}
                                            </div>
                                            <div className="chatContainer">
                                              <div className="spaceContainer">
                                                {ticket?.assigned_users?.map(
                                                  (user, index) => (
                                                    <span key={index}>
                                                      <Avatar
                                                        icon="pi pi-user"
                                                        shape="circle"
                                                        size="medium"
                                                      />
                                                      <span
                                                        className="avatarSize"
                                                      >
                                                        {" "}
                                                        {user.name}{" "}
                                                      </span>
                                                    </span>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="outgoing-chats">
                                      <div className="outgoing-msg">
                                        <div className="outgoing-chats-msg">
                                          <div className="block">
                                            <div
                                              className="descriptionBottomMargin"
                                            >
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: ticket?.description,
                                                }}
                                              ></div>
                                              <div>
                                                {ticket?.ticket_image !=
                                                  null && (
                                                  <Link
                                                    to={`${process.env.REACT_APP_COMMON_IMAGE_URL}ticket/${ticket?.ticket_image}`}
                                                    target="_blank"
                                                    style={{
                                                      fontSize: "x-large",
                                                      display: "flex",
                                                      flexDirection:
                                                        "row-reverse",
                                                      color: "#cbc5c5",
                                                      cursor: "pointer",
                                                    }}
                                                    title="View Attachment"
                                                  >
                                                    <MdOutlineAttachment color="#cbc5c5" />{" "}
                                                  </Link>
                                                )}
                                              </div>
                                            </div>
                                            <div className="chatContainer">
                                              <p>
                                              
                                                {ticket?.assigned_users?.map(
                                                  (user, index) => (
                                                    <span key={index}>
                                                      <Avatar
                                                        icon="pi pi-user"
                                                        shape="circle"
                                                        size="medium"
                                                      />
                                                      <span
                                                        className="avatarSize"
                                                      >
                                                        {" "}
                                                        {user.name}{" "}
                                                      </span>
                                                    </span>
                                                  )
                                                )}
                                              </p>
                                              
                                            </div>
                                          </div>
                                          <div></div>

                                          <div className="d-flex ">
                                            <span className="time">
                                              {formatDate(ticket.created_at)}
                                            </span>
                                            <span className="time">
                                              &nbsp;{"|"}&nbsp;
                                            {convertToHoursAndMinutes(
                                                    ticket?.actual_time
                                                  )}{" "}
                                                  Hrs
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {console.log(ticket?.ticket_image)}
                                  {ticket?.raised_by_user_id != userId ? (
                                    <div className="received-chats ">
                                      <div className="d-flex align-items-baseline">
                                        <Avatar
                                          className="user-icon mr-2"
                                          label={ticket?.raised_by_user
                                            ?.charAt(0)
                                            .toUpperCase()}
                                          shape="circle"
                                          size="large"
                                        />
                                        <div className="activeP">
                                          <p>{ticket?.raised_by_user}</p>
                                        </div>
                                        <span className="time ml-2">
                                          {formatDate(ticket.created_at)}
                                        </span>
                                        <span className="time ml-2">
                                        {" | "}
                                        {convertToHoursAndMinutes(
                                                    ticket?.actual_time
                                                  )}{" "}
                                                  Hrs
                                        </span>
                                      </div>
                                      <div className="received-msg">
                                        <div className="received-msg-inbox">
                                          <div className="block">
                                            <div
                                              className="descriptionBottomMargin"
                                            >
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: ticket?.description,
                                                }}
                                              ></div>
                                              {ticket?.ticket_image != null && (
                                                <Link
                                                  to={`${process.env.REACT_APP_COMMON_IMAGE_URL}ticket/${ticket?.ticket_image}`}
                                                  target="_blank"
                                                  style={{
                                                    fontSize: "x-large",
                                                    display: "flex",
                                                    flexDirection:
                                                      "row-reverse",
                                                    color: "var(--primary)",
                                                    cursor: "pointer",
                                                  }}
                                                  title="View Attachment"
                                                >
                                                  <MdOutlineAttachment color="var(--primary)" />{" "}
                                                </Link>
                                              )}
                                            </div>
                                            <div className="chatContainer">
                                              <div className="spaceContainer">
                                                {ticket?.assigned_users?.map(
                                                  (user, index) => (
                                                    <span key={index}>
                                                      <Avatar
                                                        icon="pi pi-user"
                                                        shape="circle"
                                                        size="medium"
                                                      />
                                                      <span
                                                        className="avatarSize"
                                                      >
                                                        {" "}
                                                        {user.name}{" "}
                                                      </span>
                                                    </span>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="outgoing-chats">
                                      <div className="outgoing-msg">
                                        <div className="outgoing-chats-msg">
                                          <div className="block">
                                            <div
                                              className="descriptionBottomMargin"
                                            >
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: ticket?.description,
                                                }}
                                              ></div>
                                              <div>
                                                {ticket?.ticket_image !=
                                                  null && (
                                                  <Link
                                                    to={`${process.env.REACT_APP_COMMON_IMAGE_URL}ticket/${ticket?.ticket_image}`}
                                                    target="_blank"
                                                    style={{
                                                      fontSize: "x-large",
                                                      display: "flex",
                                                      flexDirection:
                                                        "row-reverse",
                                                      color: "#cbc5c5",
                                                      cursor: "pointer",
                                                    }}
                                                    title="View Attachment"
                                                  >
                                                    <MdOutlineAttachment color="#cbc5c5" />{" "}
                                                  </Link>
                                                )}
                                              </div>
                                            </div>
                                            <div className="chatContainer">
                                              <p>
                                              
                                                {ticket?.assigned_users?.map(
                                                  (user, index) => (
                                                    <span key={index}>
                                                      <Avatar
                                                        icon="pi pi-user"
                                                        shape="circle"
                                                        size="medium"
                                                      />
                                                      <span
                                                        className="avatarSize"
                                                      >
                                                        {" "}
                                                        {user.name}{" "}
                                                      </span>
                                                    </span>
                                                  )
                                                )}
                                              </p>
                                              
                                            </div>
                                          </div>
                                          <div></div>

                                          <div className="d-flex ">
                                            <span className="time">
                                              {formatDate(ticket.created_at)}
                                            </span>
                                            <span className="time">
                                              &nbsp;{"|"}&nbsp;
                                            {convertToHoursAndMinutes(
                                                    ticket?.actual_time
                                                  )}{" "}
                                                  Hrs
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )
                            )}

                        {userData?.role === "3" &&
                          ticketDetails.length > 0 &&
                          ticketDetails
                            ?.filter((ticket) => {
                              return ticket?.internal_external === 1;
                            })
                            .map((ticket) => (
                              <>
                                {console.log(ticket?.ticket_image)}
                                {ticket?.raised_by_user_id != userId ? (
                                  <div className="received-chats ">
                                    <div className="d-flex align-items-baseline">
                                      <Avatar
                                        className="user-icon mr-2"
                                        label={ticket?.raised_by_user
                                          ?.charAt(0)
                                          .toUpperCase()}
                                        shape="circle"
                                        size="large"
                                      />
                                      <div className="activeP">
                                        <p>{ticket?.raised_by_user}</p>
                                      </div>
                                      <span className="time ml-2">
                                        {formatDate(ticket.created_at)}
                                      </span>
                                    </div>
                                    <div className="received-msg">
                                      <div className="received-msg-inbox">
                                        <div className="block d-flex">
                                          <div
                                            className="style"
                                            style={{ width: "90%" }}
                                          >
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: ticket?.description,
                                              }}
                                            ></p>
                                            <p>
                                              <span>Assigned To: </span>
                                              {ticket?.assigned_users?.map(
                                                (user, index) => (
                                                  <span key={index}>
                                                    <Avatar
                                                      icon="pi pi-user"
                                                      shape="circle"
                                                      size="medium"
                                                    />
                                                    <span
                                                  
                                                      className="avatarSize"
                                                    >
                                                      {" "}
                                                      {user.name}{" "}
                                                    </span>
                                                  </span>
                                                )
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className="style2"
                                            style={{ width: "10%" }}
                                          >
                                            {ticket?.ticket_image != null && (
                                              <Link
                                                to={`${process.env.REACT_APP_COMMON_IMAGE_URL}ticket/${ticket?.ticket_image}`}
                                                target="_blank"
                                                style={{
                                                  fontSize: "x-large",
                                                  display: "flex",
                                                  flexDirection: "row-reverse",
                                                  color: "var(--primary)",
                                                  cursor: "pointer",
                                                }}
                                                title="View Attachment"
                                              >
                                                <MdOutlineAttachment color="var(--primary)" />{" "}
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="outgoing-chats">
                                    <div className="outgoing-msg">
                                      <div className="outgoing-chats-msg">
                                        <div className="block d-flex">
                                          <div
                                            className="style"
                                            style={{ width: "90%" }}
                                          >
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: ticket?.description,
                                              }}
                                            ></p>

                                            <p>
                                              <span>Assigned To: </span>
                                              {ticket?.assigned_users?.map(
                                                (user, index) => (
                                                  <span key={index}>
                                                    <Avatar
                                                      icon="pi pi-user"
                                                      shape="circle"
                                                      size="medium"
                                                    />
                                                    <span
                                                      className="avatarSize"
                                                    >
                                                      {" "}
                                                      {user.name}{" "}
                                                    </span>
                                                  </span>
                                                )
                                              )}
                                            </p>
                                          </div>
                                          <div
                                            className="style2"
                                            style={{ width: "10%" }}
                                          >
                                            {ticket?.ticket_image != null && (
                                              <Link
                                                to={`${process.env.REACT_APP_COMMON_IMAGE_URL}ticket/${ticket?.ticket_image}`}
                                                target="_blank"
                                                style={{
                                                  fontSize: "x-large",
                                                  display: "flex",
                                                  flexDirection: "row-reverse",
                                                  color: "#cbc5c5",
                                                  cursor: "pointer",
                                                }}
                                                title="View Attachment"
                                              >
                                                <MdOutlineAttachment color="#cbc5c5" />{" "}
                                              </Link>
                                            )}
                                          </div>
                                        </div>
                                        <div className="d-flex ">
                                          <span className="time">
                                            {formatDate(ticket.created_at)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-2 ml-2">
                {!showReplyComponent && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleReplyButtonClick}
                  >
                    Reply
                  </button>
                )}
                {!showReplyComponent && (
                  <button
                    type="button"
                    className="btn btn-outline-primary ml-2"
                    onClick={handleGoBack}
                  >
                    Close
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TicketList;
