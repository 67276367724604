import React, { useState, useRef, useEffect, useContext } from "react";
import { Badge } from "primereact/badge";
import styles from "./TodoListFixedControls.module.css";
import { FiFileText } from "react-icons/fi";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Card } from "primereact/card";
import { RiArrowDownSFill } from "react-icons/ri";
import AlertContext from "../../../context/Alert/AlertContext";
import { useNavigate } from "react-router-dom";
function NotificationCount() {
  const [visible, setVisible] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const { changeNotification, setChangeNotification } =
    useContext(AlertContext);

  const getNotification = async () => {
    await axiosPrivate
      .get("get-notifications")
      .then((response) => {
        setNotificationData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getNotification();
  }, [changeNotification]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const deleteNotification = async (id, link) => {
    await axiosPrivate
      .delete(`notification-delete/${id}`)
      .then((response) => {
        getNotification();
        navigate(link);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <div>
        {
          notificationData?.length > 0 && (
            <div
          className={styles.notificationBellIcon}
          onClick={() => setVisible(!visible)}
        >
          <i
            className="pi pi-bell p-overlay-badge text-white"
            style={{ fontSize: "15px" }}
          >
            <Badge value={notificationData?.length | 0}></Badge>
          </i>
        </div>
          )
        }
        {visible && (
          <div ref={notificationRef} style={{ position: "relative" }}>
            <div className="notificationModel">
              <div className={styles.notificationArrowIcon}>
                <RiArrowDownSFill color="#5a5278" size={"30px"} />
              </div>
              <div className="card">
                <Card className={styles.scrollableCard}>
                  {notificationData?.map((item) => {
                    // Parse the date string into a Date object
                    const itemDate = new Date(item?.created_at);
                    const currentDate = new Date();

                    // Calculate the difference in milliseconds
                    const timeDifference = currentDate - itemDate;

                    // Calculate the difference in hours
                    const hoursDifference = Math.abs(
                      Math.floor(timeDifference / (1000 * 60 * 60))
                    );

                    // Format the date as "14 Aug 2024"
                    const formattedDate = new Intl.DateTimeFormat("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    }).format(itemDate);

                    // Format the time as "HH:MM AM/PM"
                    const formattedTime = itemDate.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    });

                    return (
                      <div
                        className={styles.notificationMsg}
                        key={item?.id}
                        onClick={() => deleteNotification(item?.id, item?.link)}
                      >
                        <div className={styles.userIcon}>
                          {item?.type === "0" ? (
                            <FiFileText size={"20px"} />
                          ) : (
                            <i className="pi pi-bell"></i>
                          )}
                        </div>
                        <div className={styles.lineHeight}>
                          <div className={styles.notificationHeading}>
                            {item?.title}
                          </div>
                          <div className={styles.notificationDescription}>
                            {item?.body?.length >= 50
                              ? item?.body?.slice(0, 50) + "..."
                              : item?.body}
                          </div>
                          <div className={styles.notificationDescription}>
                            {hoursDifference < 24 ? (
                              <span>{`${hoursDifference} hours ago`}</span>
                            ) : (
                              <span>{`${formattedDate} at ${formattedTime}`}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default NotificationCount;
