import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import PermissionTableTd from "./PermissionTableTd";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";
import { errorToast, successToast } from "../../../utils/Toaster";
import styles from "./Rolepermission.module.css"
const validationRules = {};

const EditRolePermission = () => {
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setRolePermissions] = useState([]);
  const [permissionDistinctName, setPermissionDistinctName] = useState([]);
  const [rolePermissionsId, setRolePermissionsId] = useState([]);
  const { loading, setIsLoading } = useContext(LoaderContext);
  useEffect(() => {
    axiosPrivate.get("permissions").then((fetched_permissions) => {
      setPermissions(fetched_permissions?.data?.data);
    });

    axiosPrivate.get("permissions/distinct/name").then((distinctName) => {
      setPermissionDistinctName(distinctName?.data?.data);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(`role-has-permissions/${id}`)
      .then((fetched_role_permissions) => {
        setRolePermissions(fetched_role_permissions?.data?.data);
        if (fetched_role_permissions.data.data.length) {
          let rolePermissionsArray = [];
          for (let m = 0; m < fetched_role_permissions.data.data.length; m++) {
            rolePermissionsArray.push(
              fetched_role_permissions.data.data[m].permission_id
            );
          }
          setRolePermissionsId(rolePermissionsArray);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        errorToast(error);
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    axiosPrivate.get("permissions").then((fetched_permissions) => {
      setPermissions(fetched_permissions?.data?.data);
    });

    axiosPrivate.get("permissions/distinct/name").then((distinctName) => {
      setPermissionDistinctName(distinctName?.data?.data);
    });
  }, [rolePermissionsId]);

  const modifyPermissionIdArray = async (permission_id, action) => {
    setRolePermissionsId((prevIds) => {
      if (action === false) {
        return prevIds.filter((id) => id !== permission_id);
      } else {
        return [...prevIds, permission_id];
      }
    });
  };

  const submitHandler = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    let values = {
      role_id: id,
      permission_id: rolePermissionsId,
    };

    await axiosPrivate
      .post(`role-has-permissions`, values)
      .then((response) => {
        setAlertMessage({ message: "Role Permissions added successfully" });
        setIsLoading(false);
        navigate("/admin/rolepermission");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status !== 201) {
          }
        }
        setIsLoading(false);
      });
  };

  const title = ["Create", "List", "Edit", "Delete"];
  const rows = permissionDistinctName?.map((distinct, index) => {
    // Filter permissions based on distinct name
    const distinctPermissions = permissions?.filter(
      (permission) => permission.name === distinct.name
    );

    const createPermission = distinctPermissions?.find(
      (permission) => permission.action === "create"
    );

    const listPermission = distinctPermissions?.find(
      (permission) => permission.action === "list"
    );

    const updatePermission = distinctPermissions?.find(
      (permission) => permission.action === "edit"
    );

    const deletePermission = distinctPermissions?.find(
      (permission) => permission.action === "delete"
    );

    return (
      <tr key={index} className={styles.rowWrap}>
        <td >{distinct?.name}</td>
        {/* Map over the filtered permissions array */}

        <PermissionTableTd
          id={createPermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={rolePermissionsId.includes(
            createPermission?.id.toLocaleString()
          )}
          tdKey={index}
        />

        <PermissionTableTd
          id={listPermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={rolePermissionsId.includes(
            listPermission?.id.toLocaleString()
          )}
          tdKey={index}
        />

        <PermissionTableTd
          id={updatePermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={rolePermissionsId.includes(
            updatePermission?.id.toLocaleString()
          )}
          tdKey={index}
        />

        <PermissionTableTd
          id={deletePermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={rolePermissionsId.includes(
            deletePermission?.id.toLocaleString()
          )}
          tdKey={index}
        />
      </tr>
    );
  });

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="section-body mt-3">
          <div>
            <div className="tab-content ">
              <form onSubmit={submitHandler}>
                <div
                  className="tab-pane fade show active"
                  id="user-permissions"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="row clearfix">
                        <div className="col-12">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th />
                                  {title.map((name, key) => (
                                    <th key={key}>{name}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>{rows}</tbody>
                            </table>
                          </div>
                          <div className="col-12">
                          
                            <div className="table-responsive">
                              <table className="table table-striped"></table>
                            </div>
                            <button type="submit" className="btn btn-primary">
                              Update
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline-primary ml-2"
                              data-dismiss="modal"
                              onClick={() => navigate("/admin/rolepermission")}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditRolePermission;
