import React from "react";
import Reports from "./reports";

const ReportsData = () => {
  return (
    <>
      <div>
        <div>
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div
                className="tab-pane fade show active"
                id="user-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    {/* Use the CustomTable component */}
                    <Reports />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsData;
