import { Formik, useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './TicketList.module.css';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
	
	name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
	project_name: Yup.string()
    .max(120, "Project name must not exceed 120 characters")
    .required(" Project Name is required"),
    description: Yup.string()
    .max(120, " Description must not exceed 120 characters")
    .required("Description is required"),
    priority: Yup.string()
    .max(" Priority must not exceed 120 characters")
    .required("Priority is required"),
    department: Yup.string()
    .max(" Department must not exceed 120 characters")
    .required("Department is required"),
	

};


const EditTicketList = () => {
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext); 
  const navigate = useNavigate();	
  const [roles, setRoles] = useState([]);	
  const [getData,setGetData] = useState({});
  const [initialValues, setInitialValues] = useState({
        project_name: '',
        short_description: '',
    });

  useEffect(()=>{
    axiosPrivate.get('roles')
        .then(user_roles=>{
			setRoles(user_roles?.data?.data);
		  }
		)
		.catch(error => {
			if (error.response) {
				//response status is an error code
				console.log(error.response.status);
			}
			else if (error.request) {
				//response not received though the request was sent
				console.log(error.request);
			}
			else {
				//an error occurred when setting up the request
				console.log(error.message);
			}
		});
  },[]);

  useEffect(()=>{
    axiosPrivate.get(`projects/${id}`)
        .then(response=>{ 	
          if(response.data.code == 200) {
            setGetData(response.data.data);
          }
		  }
		)
		.catch(error => {
			if (error.response) {
				//response status is an error code
				console.log(error.response.status);
			}
			else if (error.request) {
				//response not received though the request was sent
				console.log(error.request);
			}
			else {
				//an error occurred when setting up the request
				console.log(error.message);
			}
		});
  },[roles]);

  useEffect(()=>{
	if(getData != "") {
		formik.setValues({...getData});
	}
  },[getData]);


  const formik = useFormik({
   
    initialValues: {
        name: "",
        project_name: "",
        description: "",
        priority: "",
        department: ""

    },
	validationSchema: Yup.object(validationRules),
	onSubmit: async(values,{ setSubmitting, setFieldError }) => {
       
        let formData = new FormData();
		
        formData.append("name", values.name);
        formData.append("project_name", values.project_name);
        formData.append("description", values.description);
        formData.append("priority", values.priority);
        formData.append("department", values.department);
		axiosPrivate.put(`projects/update/${id}`,values)
		.then(response=>{ 	
			setAlertMessage({"message": "Project updated successfully"});
			navigate('/admin/project-list');
		})
		.catch(error => {
			if (error.response) {
				if(error.response.status == 422) {
					for(let i = 0; i < error.response.data.errors.length; i++) {
						setFieldError(error.response.data.errors[i].param,error.response.data.errors[i].msg);
					}
				}
			}
		});
	},
  });


  return (
    <div className="section-body mt-3">
        <div className="container-fluid">
            <div className="tab-content mt-3">
                <form onSubmit={formik.handleSubmit}>
                <div className="tab-pane fade show active" id="user-add" role="tabpanel">
                    <div className="card">
                        <div className="card-header">
                            <strong>Edit Project</strong>
                        </div>
                        <div className="card-body">
                            <div className="row clearfix">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                         Employee Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Employee Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

											<div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                         Project Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="project_name"
                            name="project_name"
                            type="text"
                            className="form-control"
                            placeholder="Project Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.project_name}
                          />
                          {formik.touched.project_name && formik.errors.project_name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.project_name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <label>
                         Description:<span className="required"></span>
                        </label>
                        <div className="form-group">
                          <input
                            id="description"
                            name="description"
                            type="text"
                            className="form-control"
                            placeholder="Description*"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                          />
                          {formik.touched.description && formik.errors.description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

					  <div className="col-md-4 col-sm-12">
                        <label>
                          Priority:
                          <span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="priority"
                            name="priority"
                            type="type"
                            className="form-control"
                            placeholder=" Priority "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.priority}
                          />
                          {formik.touched.priority &&
													formik.errors.priority ? (
														<div className={classes.invalidDataError}>
															{formik.errors.priority}
														</div>
													) : null}
                        </div>
                      </div>

                      <div className="col-md-4 col-sm-12">
                        <label>
                          Department:
                          <span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="department"
                            name="department"
                            type="type"
                            className="form-control"
                            placeholder=" Department"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.department}
                          />
                          {formik.touched.department &&
													formik.errors.department ? (
														<div className={classes.invalidDataError}>
															{formik.errors.department}
														</div>
													) : null}
                        </div>
                      </div>
										
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Update
                            </button>
                            <button
                                type="button"
                                className="btn btn-outline-primary ml-2"
                                data-dismiss="modal"
                                onClick={() => navigate('/admin/project-list')}
                            >
                                CLOSE
                            </button>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default EditTicketList