import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

import styles from "./TodoListFixedControls.module.css";
import "./toggle.css";
import { Calendar } from "primereact/calendar";
import { IoIosClose } from "react-icons/io";
import { IoCheckmarkSharp } from "react-icons/io5";
import dayjs from "dayjs";

import "primereact/resources/themes/saga-blue/theme.css"; // or any other theme you prefer
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Sidebar } from "primereact/sidebar";
import { IoFilter } from "react-icons/io5";

import { TbFilterPlus } from "react-icons/tb";
import NotificationCount from "./NotificationCount";

const FilterTaskMobile = (props) => {
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [departmentList, setDepartmentList] = useState([
    { name: "Development", id: "1" },
    { name: "Digital", id: "2" },
  ]);
  const [lead, setLead] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [departmentNameFilter, setDepartmentNameFilter] = useState("");
  const [dateNameFilter, setDateNameFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [projectList, setProjectList] = useState([]);

  const [visible, setVisible] = useState(false);
  const [filterBase, setFilterBase] = useState("project");
  const [projectSearch, setProjectSearch] = useState("");
  const [employeeSearch, setEmployeeSearch] = useState("");

  let userDetails = {};
  if (localStorage.getItem("userData")) {
    userDetails = JSON.parse(localStorage.getItem("userData"));
  }

  useEffect(() => {
    const filterTasks = (tasks) => {
      if (tasks?.length > 0) {
        return tasks?.filter(
          (item) =>
            item?.project_name
              ?.toLowerCase()
              .includes(projectNameFilter?.toLowerCase()) &&
            item?.assigned_to_username
              ?.toLowerCase()
              .includes(employeeNameFilter?.toLowerCase()) &&
            (statusFilter === "" || item?.status === statusFilter) &&
            (departmentNameFilter === "" ||
              item?.department
                ?.toLowerCase()
                .includes(departmentNameFilter?.toLowerCase())) &&
            item?.due_date?.includes(dateNameFilter)
        );
      }
    };

    if (props?.tasks || props?.completedTasks) {
      props?.filteredData(
        !checked
          ? filterTasks(props?.tasks)
          : filterTasks(props?.completedTasks)
      );
    }

    if (props?.leadData) {
      props?.leadData(filterTasks(props?.leadTasks));
    }

    if (props?.setChecked) {
      props?.setChecked(checked);
    }

    if (props?.setLead) {
      props?.setLead(lead);
    }

    console.log("ProjectnameFilter", employeeNameFilter);
  }, [
    projectNameFilter,
    employeeNameFilter,
    statusFilter,
    departmentNameFilter,
    dateNameFilter,
    checked,
    lead,
    props?.tasks,
    props?.completedTasks,
    props?.leadTasks,
  ]);

  // Formatting date in dd-mm-yyyy format

  const formatDate = (date) => {
    // Format the date using dayjs
    const formattedDate = dayjs(date).format("DD-MM-YYYY");
    // Set the formatted date to the state
    setDateNameFilter(formattedDate);
    setSelectedDate(date);
  };

  const clearDateFilter = () => {
    // Clear the date filter
    setDateNameFilter("");
    setSelectedDate(null);
    // alert(dateNameFilter);
  };

  // Checking that any task is available to perform today or not

  useEffect(() => {
    if (props?.users) {
      const fetchedEmployeeOptions = props?.users
        ?.filter((employee) => employee?.role_name !== "Client")
        ?.map((employee) => ({
          value: employee?.id,
          label: employee?.name,
          role_name: employee?.role_name,
        }));
      setEmployeeList(fetchedEmployeeOptions);
    }

    if (props?.projectDetails) {
      const fetchedProjectOptions = props?.projectDetails?.map((project) => ({
        value: project?.project_id,
        label: project?.project_name,
      }));
      setProjectList(fetchedProjectOptions);
    }
  }, [
    props?.users,
    props?.completedTasks,
    props?.leadTasks,
    props?.projectDetails,
  ]);

  const statusOptions1 = [
    { value: "Pending", label: "Pending" },
    { value: "In Progress", label: "In Progress" },
    { value: "Overdue", label: "Overdue" },
    { value: "On Hold", label: "On Hold" },
  ];

  const statusOptions2 = [
    { value: "Ready For Q/A", label: "Ready For Q/A" },
    { value: "Ready For UAT", label: "Ready For UAT" },
    { value: "Done", label: "Done" },
  ];

  // Formatting date to show like jan, feb etc. in tasks list

  const clearAll = () => {
    setProjectNameFilter("");
    setEmployeeNameFilter("");
    setStatusFilter("");
    setDepartmentNameFilter("");
  };

  const employeeNameFormat = (name) => {
    if (name) {
      const nameParts = name.split(" ");
      if (nameParts && nameParts.length > 0) {
        const firstName = nameParts[0];
        const lastName =
          nameParts.length > 1
            ? nameParts[nameParts.length - 1].slice(0, 1) + "."
            : "";
        const formattedName = `${firstName} ${lastName}`;
        const formattedNameCapitalized =
          formattedName.charAt(0).toUpperCase() + formattedName.slice(1);
        return formattedNameCapitalized;
      }
    } else {
      return "";
    }
  };
  return (
    <div className="card">
      <div className={styles.reportContainer}>
        {userDetails?.role === "4" && (
          <div className={styles.toggleContainer}>
            <div>My Tasks</div>
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={lead}
                  onChange={() => setLead(!lead)}
                />
              </label>
            </div>
            <div>Team Tasks</div>
          </div>
        )}
        <div className={styles.toggleContainer}>
          <>
            <div>To Do Tasks</div>
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </label>
            </div>
            <div>Completed Tasks</div>
          </>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "20%",
                top: "10%",
                cursor: "pointer",
                zIndex: "1",
              }}
              onClick={clearDateFilter}
            >
              {selectedDate !== null && <IoIosClose size={20} />}
            </div>

            <div
              className="card flex justify-content-center"
              style={{ marginBottom: "0px" }}
            >
              <Calendar
                value={selectedDate}
                placeholder="DD-MM-YYYY"
                style={{ height: "30px", width: "205px" }}
                onChange={(e) => {
                  formatDate(e.value);
                  setSelectedDate(e.value);
                }}
                dateFormat="dd-mm-yy"
                showIcon
              />
            </div>
          </div>
          <div>
            <button
              className={styles.filterIcon}
              onClick={() => setVisible(true)}
            >
              <TbFilterPlus color="#fff" />
            </button>
          </div>
          <NotificationCount/>

        </div>
      </div>

      <Sidebar
        style={{ transition: "1s" }}
        visible={visible}
        onHide={() => {
          clearAll();
          setVisible(false);
        }}
        fullScreen
      >
        <>
          <div
            className={`d-flex justify-content-between mb-0 border-bottom pl-2 pr-2 pb-1 ${styles.header}`}
          >
            <div style={{ fontWeight: "bold" }}>Filters</div>
            <div
              className={styles.clearAll}
              onClick={() => {
                clearAll();
              }}
            >
              Clear All
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className={styles.leftFilterData}>
              <div style={{ position: "sticky", top: "35px" }}>
                <div
                  className={`pt-2 pb-3 pl-2 pr-2 ${
                    filterBase === "project" ? styles.leftFilterBackground : ""
                  }`}
                  onClick={() => setFilterBase("project")}
                >
                  Project
                </div>
                {userDetails?.role !== "2" && userDetails?.role !== "3" && (
                  <div
                    className={`pt-2 pb-3 pl-2 pr-2 ${
                      filterBase === "employee"
                        ? styles.leftFilterBackground
                        : ""
                    }`}
                    onClick={() => setFilterBase("employee")}
                  >
                    Employee
                  </div>
                )}

                <div
                  className={`pt-2 pb-3 pl-2 pr-2 ${
                    filterBase === "status" ? styles.leftFilterBackground : ""
                  }`}
                  onClick={() => setFilterBase("status")}
                >
                  Status
                </div>
                {userDetails?.role !== "2" && userDetails?.role !== "3" && (
                  <div
                    className={`pt-2 pb-3 pl-2 pr-2 ${
                      filterBase === "department"
                        ? styles.leftFilterBackground
                        : ""
                    }`}
                    onClick={() => setFilterBase("department")}
                  >
                    Department
                  </div>
                )}
              </div>
            </div>
            <div className={styles.rightFilterData}>
              <div className="card flex justify-content-center">
                <div className="flex flex-column gap-3">
                  {filterBase === "project" && (
                    <div
                      className="form-outline ml-3"
                      style={{ position: "sticky", top: "35px" }}
                      data-mdb-input-init
                    >
                      <input
                        type="search"
                        id="form1"
                        className="form-control"
                        style={{ height: "auto", padding: "6px 12px 6px 12px" }}
                        value={projectSearch}
                        onChange={(e) => setProjectSearch(e.target.value)}
                        placeholder="Search projects..."
                        aria-label="Search"
                      />
                    </div>
                  )}
                  {filterBase === "project" &&
                    projectList
                      .filter((project) =>
                        project.label
                          .toLowerCase()
                          .includes(projectSearch.toLowerCase())
                      )
                      ?.map((project) => {
                        return (
                          <div
                            key={project.value}
                            className="flex align-items-center ml-3 mt-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => setProjectNameFilter(project?.label)}
                          >
                            <IoCheckmarkSharp
                              color="#ff5c5c"
                              style={{
                                visibility:
                                  projectNameFilter === project?.label
                                    ? "visible"
                                    : "hidden",
                              }}
                            />{" "}
                            <span
                              style={{
                                color:
                                  projectNameFilter === project?.label
                                    ? "#ff5c5c"
                                    : "",
                              }}
                            >
                              {project?.label}
                            </span>
                          </div>
                        );
                      })}

                  {userDetails?.role !== "2" &&
                    userDetails?.role !== "3" &&
                    filterBase === "employee" && (
                      <div
                        className="form-outline ml-3"
                        data-mdb-input-init
                        style={{ position: "sticky", top: "35px" }}
                      >
                        <input
                          type="search"
                          id="form1"
                          className="form-control"
                          style={{ height: "auto", padding: "6px 12px 6px 12px" }}
                          placeholder="Search employees..."
                          value={employeeSearch}
                          onChange={(e) => setEmployeeSearch(e.target.value)}
                          aria-label="Search"
                        />
                      </div>
                    )}

                  {userDetails?.role !== "2" &&
                    userDetails?.role !== "3" &&
                    filterBase === "employee" &&
                    employeeList
                      .filter((employee) =>
                        employee.label
                          .toLowerCase()
                          .includes(employeeSearch.toLowerCase())
                      )
                      ?.map((employee) => {
                        const formattedNameCapitalized = employeeNameFormat(
                          employee?.label
                        );
                        return (
                          <div
                            key={employee.value}
                            className="flex align-items-center ml-3 mt-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setEmployeeNameFilter(formattedNameCapitalized);
                            }}
                          >
                            <IoCheckmarkSharp
                              color="#ff5c5c"
                              style={{
                                visibility:
                                  employeeNameFilter ===
                                  formattedNameCapitalized
                                    ? "visible"
                                    : "hidden",
                              }}
                            />{" "}
                            <span
                              style={{
                                color:
                                  employeeNameFilter ===
                                  formattedNameCapitalized
                                    ? "#ff5c5c"
                                    : "",
                              }}
                            >
                              {employee?.label}
                            </span>
                          </div>
                        );
                      })}

                  {filterBase === "status" &&
                    !checked &&
                    statusOptions1.map((status) => {
                      return (
                        <div
                          key={status.value}
                          className="flex align-items-center ml-3 mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setStatusFilter(status?.label)}
                        >
                          <IoCheckmarkSharp
                            color="#ff5c5c"
                            style={{
                              visibility:
                                statusFilter === status?.label
                                  ? "visible"
                                  : "hidden",
                            }}
                          />{" "}
                          <span
                            style={{
                              color:
                                statusFilter === status?.label ? "#ff5c5c" : "",
                            }}
                          >
                            {status?.label}
                          </span>
                        </div>
                      );
                    })}

                  {filterBase === "status" &&
                    checked &&
                    statusOptions2.map((status) => {
                      return (
                        <div
                          key={status.value}
                          className="flex align-items-center ml-3 mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => setStatusFilter(status?.label)}
                        >
                          <IoCheckmarkSharp
                            color="#ff5c5c"
                            style={{
                              visibility:
                                statusFilter === status?.label
                                  ? "visible"
                                  : "hidden",
                            }}
                          />{" "}
                          <span
                            style={{
                              color:
                                statusFilter === status?.label ? "#ff5c5c" : "",
                            }}
                          >
                            {status?.label}
                          </span>
                        </div>
                      );
                    })}
                  {userDetails?.role !== "2" &&
                    userDetails?.role !== "3" &&
                    filterBase === "department" &&
                    departmentList.map((department) => {
                      return (
                        <div
                          key={department.id}
                          className="flex align-items-center ml-3 mt-2"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setDepartmentNameFilter(department?.name)
                          }
                        >
                          <IoCheckmarkSharp
                            color="#ff5c5c"
                            style={{
                              visibility:
                                departmentNameFilter === department?.name
                                  ? "visible"
                                  : "hidden",
                            }}
                          />{" "}
                          <span
                            style={{
                              color:
                                departmentNameFilter === department?.name
                                  ? "#ff5c5c"
                                  : "",
                            }}
                          >
                            {department?.name}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.applyContainer}>
            <div
              className={styles.applyButton}
              onClick={() => {
                setVisible(false);
              }}
            >
              Close
            </div>
            <div style={{ borderRight: "1px solid #fff" }}></div>
            <div
              className={styles.applyButton}
              onClick={() => {
                setVisible(false);
              }}
            >
              Apply
            </div>
          </div>
        </>
      </Sidebar>
    </div>
  );
};

export default FilterTaskMobile;
