import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable2 from '../../../components/DataTable2';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';

const Expenses = (props) => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [expensesType, setExpensesType] = useState([]);

	const headers = [
		{
			name: 'User Name',
			field: 'user_name',
			sortable: true,
			classKey: 'w60',
		},
		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['name'];

	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('expenses', options)
			.then((expenses) => {
 				const expenseData = expenses.data.data.map((value, key) => {
					let buttons = [];
					if (value.department)
						value.department_name = value.department.name
							? value.department.name
							: 'N/A';

					if (true)
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/expenses/edit/${value.employee_id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<i className="fa fa-edit"></i>
							</Link>
						);
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => expensesTypeDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<i className="fa fa-trash-o text-danger"></i>
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value; // console.log("uservalue",userData)
				});

				setExpensesType(expenseData);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	const expensesTypeDeleteHandler = async (id) => {
		console.log('id,id');
		axiosPrivate
			.delete(`expenses/${id}`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setExpensesType([]);
					setAlertMessage({ message: 'expenses  deleted successfully' });
					await refreshTable();
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	};

	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate
				.get('expenses', options)
				.then((expenses) => {
					console.log('', expenses);
					const ExpensesData = expenses.data.data.map((value, key) => {
						let buttons = [];
						if (value.expense_type)
							value.expense_type = value.expense_type
								? value.expense_type
								: 'N/A';

						if (true)
							buttons.push(
								<button
									key="editButton##1"
									type="button"
									className="btn btn-icon"
									title="Edit"
								>
									<i className="fa fa-edit"></i>
								</button>
							);
						if (true)
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									onClick={() => expensesTypeDeleteHandler(value.expense_id)}
									className="btn btn-icon js-sweetalert"
									title="Delete"
								>
									<i className="fa fa-trash-o text-danger"></i>
								</button>
							);
						value['action'] = buttons.length > 0 ? buttons : '-';
						return value;
					});
					setExpensesType(ExpensesData);
					resolve(true);
				})
				.catch((error) => {
					reject(error);
					if (error.response) {
						//response status is an error code
						console.log(error.response.status);
					} else if (error.request) {
						//response not received though the request was sent
						console.log(error.request);
					} else {
						//an error occurred when setting up the request
						console.log(error.message);
					}
				});
		});
	};

	return (
		<div>
			<TopNavBar
				links={{ list: '/admin/expenses', add: '/admin/expenses/add' }}
			/>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">Expenses</h3>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={expensesType}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Expenses;
