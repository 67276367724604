import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "./TodoListFixedControls.module.css";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { FaEye } from "react-icons/fa";
import { successToast, errorToast } from "../../../utils/Toaster";
import PriorityWiseTask from "./PriorityWiseTask";
import FilterTask from "./FilterTask";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";
import "./button.css";
import FilterTaskMobile from "./filterTaskMobile";

const LeadData = ({ userData }) => {
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [leadTasks, setLeadTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const { loading, setIsLoading } = useContext(LoaderContext);
  const [filteredData, setFilteredData] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [lead, setLead] = useState(false);

  const formatDate = (inputDateStr) => {
    if (!inputDateStr) {
      return null;
    }

    const parsedDate = new Date(inputDateStr);
    parsedDate.setHours(0, 0, 0, 0);

    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("tasks", options)
      .then((posttypes) => {
        const posttypeData = posttypes?.data?.data?.map((value) => {
          value["new"] = value?.priority;
          let buttons = [];

          buttons.push(
            <Link
              key="viewButton##1"
              type="button"
              to={`/admin/project-ticket-list/view/${value?.id}`}
              className="btn btn-icon btn-outline-success mr-1 mb-1"
              title="View"
            >
              <FaEye />
            </Link>
          );

          value["priority"] = (
            <div>
              {value?.priority === "4" && (
                <span className="status critical">Critical</span>
              )}
              {value?.priority === "3" && (
                <span className="status high">High</span>
              )}
              {value?.priority === "1" && (
                <span className="status low">Low</span>
              )}
              {value?.priority === "2" && (
                <span className="status medium">Medium</span>
              )}
            </div>
          );
          value["due_date"] = formatDate(value["due_date"]);
          value["action"] = buttons?.length > 0 ? buttons : "-";
          return value;
        });

        setTasks(posttypeData);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    setIsLoading(true);

    try {
      axiosPrivate
        .get("users")
        .then((user) => {
          setIsLoading(false);
          setUsers(user?.data?.data);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      console?.error(error);
    }

    try {
      axiosPrivate.get("projects").then((project) => {
        setProjectDetails(project?.data?.data);
      });
    } catch (error) {
      console?.error(error);
    }

    axiosPrivate
      .get("completed-tasks")
      .then((response) => {
        const finalData = response?.data?.data?.map((item) => {
          item["new"] = item?.priority;
          const splitDate = item?.due_date?.split("T")[0];
          if (splitDate) {
            const dateParts = splitDate?.split("-");
            const day = dateParts[2];
            const month = dateParts[1];
            const year = dateParts[0];
            item["due_date"] = `${day}-${month}-${year}`;
          }
          return item;
        });
        setCompletedTasks(finalData);
      })
      .catch((error) => {
        errorToast(error?.response?.data?.message);
      });

    axiosPrivate
      .get("lead_tasks")
      .then((response) => {
        const updatedLeadData = response?.data?.data?.map((item) => {
          item["new"] = item?.priority;
          const splitDate = item?.due_date?.split("T")[0];
          if (splitDate) {
            const dateParts = splitDate?.split("-");
            const day = dateParts[2];
            const month = dateParts[1];
            const year = dateParts[0];
            item["due_date"] = `${day}-${month}-${year}`;
          }
          return item;
        });
        setLeadTasks(updatedLeadData);
      })
      .catch((error) => {
        errorToast(error?.response?.data?.message);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div>
            <div className="section-body mt-3">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="user-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body">
                      {/* Use the CustomTable component */}
                      <div className="col-lg-12 d-flex justify-content-end pr-0 pt-0 pb-4">
                        <div className="d-flex justify-content-end">
                          <Link
                            to="/admin/project-task/add-task"
                            className="text-decoration-none "
                          >
                            <button
                              type="button"
                              className="btn btn-primary btnSize"
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <i className="fe fe-plus mr-2" />
                              Add Task
                            </button>
                          </Link>
                          <Link
                            to="/admin/project-list/add"
                            className="text-decoration-none ml-1"
                          >
                            <button
                              type="button"
                              className="btn btn-primary btnSize"
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <i className="fe fe-plus mr-2" />
                              Add Project
                            </button>
                          </Link>
                        </div>
                      </div>

                      {window?.innerWidth <= 576 ? (
                        <FilterTaskMobile
                          tasks={tasks}
                          projectDetails={projectDetails}
                          completedTasks={completedTasks}
                          users={users}
                          leadTasks={leadTasks}
                          filteredData={setFilteredData}
                          leadData={setLeadData}
                          setChecked={setChecked}
                          setLead={setLead}
                        />
                      ) : (
                        <FilterTask
                          tasks={tasks}
                          projectDetails={projectDetails}
                          completedTasks={completedTasks}
                          users={users}
                          leadTasks={leadTasks}
                          filteredData={setFilteredData}
                          leadData={setLeadData}
                          setChecked={setChecked}
                          setLead={setLead}
                        />
                      )}

                      <PriorityWiseTask
                        filteredData={filteredData}
                        leadData={leadData}
                        checked={checked}
                        lead={lead}
                        role={userData?.role}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );
};

export default LeadData;
