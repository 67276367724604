import React, { useEffect, useMemo, useState } from "react";
import { Search } from "../DataTable";
import classes from "../DataTable2/DataTable.module.css";
import fixedControlsStyles from "../DataTable2/DataTableFixedControls.module.css";

const CompactPagination = ({
  total,
  itemsPerPage,
  currentPage,
  onPageChange,
  style,
}) => {
  const totalPages = Math.ceil(total / itemsPerPage);

  const getPaginationRange = () => {
    const range = [];
    const minRange = Math.max(currentPage - 1, 1);
    const maxRange = Math.min(currentPage + 1, totalPages);

    if (totalPages <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
    } else {
      range.push(minRange);

      if (minRange < totalPages - 1) {
        range.push(minRange + 1);
      }

      if (minRange < totalPages - 2) {
        range.push("...");
      }

      range.push(totalPages);

      return range;
    }

    return range;
  };

  const renderPageButton = (page) => (
    <li
      key={page}
      className={`page-item ${page === currentPage ? "active" : ""}`}
    >
      <button
        className="page-link"
        onClick={() => (page !== "..." ? handlePageChange(page) : null)}
        disabled={page === "..." || page === currentPage}
      >
        {page}
      </button>
    </li>
  );

  const handlePageChange = (page) => {
    console.log("Changing to page:", page);
    onPageChange(page);
  };

  return (
    <nav aria-label="Page navigation" style={style}>
      <ul className="pagination pagination-sm justify-content-center compact-pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Prev
          </button>
        </li>

        {getPaginationRange().map((page) => renderPageButton(page))}

        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

const DataTable2 = ({ lists, headers, searchItems }) => {
  const [comments, setComments] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const filterListLengths = [10, 25, 50, 100];

  useEffect(() => {
    setComments(lists);
  }, [lists]);

  const listData = useMemo(() => {
    let computedData = comments;

    if (search && searchItems?.length) {
      computedData = computedData.filter((filterData) =>
        searchItems.some((field) =>
          filterData[field].toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedData.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const changeItemsPerPage = (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(1); // Reset current page when changing items per page
  };

  const handleSort = (field) => {
    const newSorting = {
      field,
      order:
        sorting.field === field && sorting.order === "asc" ? "desc" : "asc",
    };
    setSorting(newSorting);
  };

  return (
    <>
    
        <div className={classes.displayContainer1}>
          <div className="row w-100">
            <div className="col mb-3 col-12 text-center">
              <div className="row">
                <div className="col-md-6">
                  <div className="float-left w-auto form-control">
                    <label className="mr-2">Show</label>
                    <select
                      name="list_length"
                      className="form-select border-0"
                      onChange={changeItemsPerPage}
                    >
                      {filterListLengths.map((value, key) => (
                        <option key={++key} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <span className="ml-2">entries</span>
                  </div>
                </div>
                <div className="col-md-6 col-12 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row w-100">
            <div className="col mb-3 col-12 text-center">
              {listData.length > 0 ? (
                <>
                  <div className="table-container">
                    <table className="table table-striped table-hover table-vcenter text-nowrap mb-0 table_sticky_2col">
                      <thead>
                        <tr>
                          <th
                            style={{ paddingRight: "3rem" }}
                            className="text-left"
                          >
                            Sr No.
                          </th>
                          {headers.map((header) => (
                            <th
                              style={{
                                paddingRight: "3rem",
                                cursor: "pointer",
                              }}
                              key={header.field}
                              className="text-left"
                              onClick={
                                header?.sortable &&
                                (() => handleSort(header.field))
                              }
                            >
                              {header?.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {listData.map((comment, idx) => (
                          <tr key={idx}>
                            <td
                              style={{ paddingRight: "3rem" }}
                              className="text-left"
                            >
                              {(currentPage - 1) * itemsPerPage + idx + 1}
                            </td>
                            {headers.map((col, colIdx) => (
                              <td
                                style={{ paddingRight: "3rem" }}
                                key={`${idx}-${colIdx}`}
                                className="text-left"
                              >
                                {comment[col.field]}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div className="alert alert-warning" role="alert">
                  No data found.
                </div>
              )}
            </div>
          </div>

          <div
            className={`fixed-controls ${fixedControlsStyles.fixedControls} mt-3 d-flex flex-column align-items-center`}
          >
            <CompactPagination
              total={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
              style={{
                display: "flex",
                gap: "8px",
                fontSize: "12px",
              }}
            />
            <div className="mt-2" style={{ fontSize: "small" }}>
              Showing Result{" "}
              {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
                currentPage * itemsPerPage,
                totalItems
              )} of ${totalItems}`}
            </div>
          </div>
        </div>
   
        <div
          className={classes.displayContainer2}
          style={{ width: "100%", maxWidth: "350px", margin: "auto" }}
        >
          <div className="row w-100">
            <div className="col mb-3 col-12 text-center">
              <div className="row">
                <div className="col-md-6">
                  <div className="float-left w-auto form-control">
                    <label className="mr-2">Show</label>
                    <select
                      name="list_length"
                      className="form-select border-0"
                      onChange={changeItemsPerPage}
                    >
                      {filterListLengths.map((value, key) => (
                        <option key={++key} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <span className="ml-2">entries</span>
                  </div>
                </div>
                <div className="col-md-6 col-12 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {listData?.length > 0 ? (
            listData?.map((data, index) => (
              // <div className="card flex justify-content-center mb-3" key={index}>
              //   <Card className="md:w-25rem">
              //     {headers.map((header, idx) => (
              //       <div
              //         className="d-flex justify-content-between p-2"
              //         style={{ borderBottom: idx < headers.length - 1 ? "1px solid #ddd" : "none" }}
              //         key={idx}
              //       >
              //         <div>{header.name}</div>
              //         <div>{data[header.field]}</div>
              //       </div>
              //     ))}
              //   </Card>
              // </div>
              <div
                class="card"
                style={{ border: "1px solid rgba(0, 0, 0, .125)" }}
              >
                <div
                  class="card-body"
                  style={{ padding: "5px", backgroundColor: "#f5f5f5" }}
                >
                  {headers.map((header, idx) => (
                    <div
                      className="d-flex justify-content-between p-2"
                      style={{
                        borderBottom:
                          idx < headers.length - 1 ? "none" : "none",
                      }}
                      key={idx}
                    >
                      <div>{header.name}</div>
                      <div
                        style={{
                          wordBreak: "break-all",
                          maxWidth: "60%",
                          textAlign: "right",
                        }}
                      >
                        {data[header.field]}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <div className="alert alert-warning" role="alert">
              No data found.
            </div>
          )}

          <div className="fixed-controls mt-3 d-flex flex-column align-items-center">
            <CompactPagination
              total={totalItems}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
              style={{
                display: "flex",
                gap: "8px",
                fontSize: "12px",
              }}
            />
            <div className="mt-2" style={{ fontSize: "small" }}>
              Showing Result{" "}
              {`${(currentPage - 1) * itemsPerPage + 1} - ${Math.min(
                currentPage * itemsPerPage,
                totalItems
              )} of ${totalItems}`}
            </div>
          </div>
        </div>
     

      <style>
        {`
          /* Your existing styles */
          @media (max-width: 426px) {
            .col-md-6 {
              margin-bottom: 4%;
              height: 28px;
            }
            .float-right {
              font-size: 13px;
            }
          }

          @media (max-width: 321px) {
            .col-md-6 {
              margin-bottom: 3%;
            }
          }

          .fixed-controls {
            position: sticky;
            top: 0;
            z-index: 1;
            background-color: white;
          }

//           .table-container::-webkit-scrollbar-track {
//     background: gray; /* Color of the track */
// }

          .table-container {
            overflow-x: auto;
          }

        .table-container::-webkit-scrollbar-thumb {
            background: gray; 
        }
            .table-container:hover::-webkit-scrollbar-thumb {
            background: gray; 
        }

        .table-container::-webkit-scrollbar {
    height: 4px; /* Set the height of the scrollbar */
}

          .compact-pagination {
            font-size: 12px;
          }
        `}
      </style>
    </>
  );
};

export default DataTable2;
