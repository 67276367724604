import { useFormik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import DataTable2 from '../../../components/DataTable2';
import classes from "./Team.module.css";
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import AddTask from "../../project/ProjectTaskList/AddTask";
import { errorToast, successToast } from "../../../utils/Toaster";
import AlertContext from "../../../context/Alert/AlertContext";

const validationRules = {
  team_name: Yup.string()
    .max(120, "Team Name must not exceed 120 characters")
    .required("Team Name is required"),
  
};

const AddTeam = (props) => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
 const [formSubmitted, setFormSubmitted] = useState(false);
 const {sendNotification} = useContext(AlertContext);

 
  const formik = useFormik({
    initialValues: {
      team_name: "",
      project_id: props.project_id|| "",
      employee_user_id: [],
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const requestData = {
        team_name: values.team_name,
        project_id: values.project_id,
        employee_user_id: values.employee_user_id.map(
          (employee) => employee.value
        ),
      };

      try {
        const response = await axiosPrivate.post(`team/add`, requestData).then((res) =>{
          values?.employee_user_id?.map((employee) => {
            if(employee?.register_id){
              sendNotification(employee?.register_id, props?.project_name, "You have assigned a new project", `/admin/project-list/edit/${res?.data?.project_name?.replaceAll(
                  " ",
                  "-"
                )}`, employee?.value, 0);
            }
          })

          setFormSubmitted(true);
        });
        
        // successToast('Team added successfully');

      } catch (error) {
        if (error.response) {
          if (error.response.status === 422) {
            for (let i = 0; i < error.response.data.errors.length; i++) {
              setFieldError(
                error.response.data.errors[i].param,
                error.response.data.errors[i].msg
              );
            }
          }
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    axiosPrivate
      .get("users")
      .then((response) => {
        const fetchedEmployeeOptions = response.data.data.map((employee) => ({
          value: employee.id,
          label: employee.name,
          role_name: employee.role_name,
          register_id: employee.register_id
        }));
        setEmployeeOptions(fetchedEmployeeOptions);
       
      })
      .catch((error) => {
        errorToast("Error fetching employee names:");
      });

    axiosPrivate
      .get("projects")
      .then((projects) => {
        setProjects(projects?.data?.data);
      })
      .catch((error) => {
      });
  }, []);

  const employeeData = employeeOptions.filter(
    (option) => option.role_name !== "Client"
  );

  return (
    <div>
      <div className="section-body mt-3">
        <div className="container-fluid">
          <div className="tab-content mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong>Add Team</strong>
                  </div>

                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <div className="form-group">
                         
                          <input
                            id="team_name"
                            name="team_name"
                            type="text"
                            className="form-control"
                            placeholder="Team Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.team_name}
                           
                            disabled={formSubmitted}
                          />
                          {formik.touched.team_name &&
                          formik.errors.team_name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.team_name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        
                        <div className="form-group">
                          <Select
                            id="employee_user_id"
                            name="employee_user_id"
                            className="custom-style"
                            isMulti
                            options={employeeData}
                            placeholder="Emp name *"
                            required
                            onChange={(selectedOptions) =>
                              formik.setFieldValue(
                                "employee_user_id",
                                selectedOptions
                              )
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.employee_user_id}
                          
                            isDisabled={formSubmitted} 
                          />
                          
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody></tbody>
                        </table>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-primary "
                          disabled={formSubmitted}
                        >
                          <FaCheck />
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary ml-2"
                          onClick={() => navigate("/admin/project-list")}
                          disabled={formSubmitted}
                        >
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {formSubmitted && <AddTask project_id={props.project_name} />}
    </div>
  );
};

export default AddTeam;
