import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./UserRegistration.module.css";
import Select from "react-select";
import { errorToast, successToast } from "../../../utils/Toaster";
import { FaEye, FaEyeSlash } from "react-icons/fa";



const AddEmployee = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [validate, setValidate] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);


  const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
  name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),
  role_id:
    Yup.string()
    .required("Role is required"),
  designation: Yup.string()
    .max(120, "Designation must not exceed 120 characters")
    .required("Designation is required"),

  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  mobile: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "Mobile number must be of 10 to 15 digits")
    .max(15, "Mobile number must be of 10 to 15 digits"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(25, "Password must not exceed 25 characters")
    .required("Password is required"),
  department: validate && Yup.string().required("Department is required"),
};


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      designation: "",
      password: "",
      role_id: "",
      department: "",
    },

    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const requestData = {
        name: values.name,
        role_id: values.role_id,
        designation: values.designation,
        email: values.email,
        mobile: values.mobile,
        password: values.password,
        department: values.department,
      };

      axiosPrivate
        .post("register", requestData)
        .then((response) => {
          if (response?.data?.code === 200 || response?.data?.code === 201) {
            successToast("New User created successfully");
            navigate(-1);
          } else {
            {
            }
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 500) {
            console.error("Internal Server Error:", error);
          } else {
            console.error("Unhandled error:", error);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    axiosPrivate.get("roles").then((user_roles) => {
      setRoles(user_roles?.data?.data);
    });
  }, []);

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue("mobile", numericValue);
  };

  useEffect(() =>{
    if(formik.values.role_id === "2" || formik.values.role_id === "4") {
      setValidate(true)
    }else{
      setValidate(false)
    }
  }, [formik.values.role_id])

  return (
    <div>
      <div className="section-body mt-3">
        <div>
          <div className="tab-content mt-3">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <strong>Add Users</strong>
                  </div>

                  <div className="card-body">
                    <div className="row clearfix">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder=" Name *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Role:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="role_id"
                            name="role_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.role_id}
                          >
                            <option selected disabled value={""}>Select Role</option>
                            {roles.map((role, key) => (
                              <option key={key + role.name} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.role_id && formik.errors.role_id ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.role_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {
                         validate && (
                          <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Department:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="department"
                            name="department"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.department}
                          >
                            <option selected disabled value={""}>
                              Select Department
                            </option>
                            <option key={"Development"} value={"Development"}>
                              Development
                            </option>
                            <option key={"Digital"} value={"Digital"}>
                              Digital
                            </option>
                          </select>
                          {formik.touched.department &&
                          formik.errors.department ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.department}
                            </div>
                          ) : null}
                        </div>
                      </div>
                         )
                      }

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Designation:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="designation"
                            name="designation"
                            type="text"
                            className="form-control"
                            placeholder=" Designation *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.designation}
                          />
                          {formik.touched.designation &&
                          formik.errors.designation ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.designation}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Email:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Email ID *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Mobile Number:
                          <span className="required"></span>
                        </label>

                        <div className="form-group">
                          <input
                            id="mobile"
                            name="mobile"
                            type="text"
                            className="form-control"
                            placeholder=" Mobile Number "
                            onChange={(e) => {
                              numberFilter(e);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobile}
                            autoComplete={false}
                            maxLength={10}
                          />
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12">
                        <label>
                          Password:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <div className="input-group">
                            <input
                              id="password"
                              name="password"
                              type={passwordVisible ? "text" : "password"}
                              className="form-control"
                              placeholder="Password *"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                            />
                            <div className="input-group-append">
                              <span
                                className="input-group-text"
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                              >
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                              </span>
                            </div>
                          </div>
                          {formik.touched.password && formik.errors.password ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table table-striped"></table>
                        </div>
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-primary ml-2"
                          data-dismiss="modal"
                          onClick={() => navigate(-1)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
