import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import TopNavBar from '../../../components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './PostStyle.module.css';

const validationRules = {
	// project_id: Yup.string().required('project is required'),

	// client_id: Yup.string().required('client   is required'),

	// employee_id: Yup.string().required('user is required'),

 
	// task_id: Yup.string().required('subtask is required'),

	// post_type_id: Yup.string().required('post is required'),
};

const AddCalendar = (props) => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [projectL, setProjectL] = useState([]);
	const [clientL, setClientL] = useState([]);
	const [MainTask, setMainTask] = useState([]);
	const [subTask, setSubTask] = useState([]);
	const [postTypestate, setPostTypestate] = useState([]);
	const [userState, setUserState] = useState([]);
	const [taskValue, setTaskValue] = useState({});
    const [subTaskid,setSubTaskId]=useState()
 
 	// project list
	useEffect(() => {
		axiosPrivate
			.get('projects')
			.then((projectList) => {
				setProjectL(projectList?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	// Client list
	useEffect(() => {
		axiosPrivate
			.get('clients')
			.then((clientList) => {
				setClientL(clientList?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	//users

	useEffect(() => {
		axiosPrivate
			.get('users')
			.then((users) => {
				setUserState(users?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	// main list 
	useEffect(() => {
		axiosPrivate
			.get('task-type')
			.then((maintask) => {
				setMainTask(maintask?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, []);

	const mainTask = (eve) => {
		setTaskValue(eve.target.value);
	};

 	// sub-task
	useEffect(() => {
		axiosPrivate
			.get(`task-type/sub-task/${taskValue}`)
			.then((subtask) => {
				setSubTask(subtask?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, [taskValue]);

	const idChange = (eve) => {
 		setSubTaskId(eve.target.value);
	};

 	// post type
	useEffect(() => {
		axiosPrivate
			.get(`post-type/${subTaskid}`)
			.then((posttype) => {
 				setPostTypestate(posttype?.data?.data);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					console.log(error.response.status);
				} else if (error.request) {
					//response not received though the request was sent
					console.log(error.request);
				} else {
					//an error occurred when setting up the request
					console.log(error.message);
				}
			});
	}, [subTaskid]);

	const formik = useFormik({
		initialValues: {
			project_id: '',
			client_id: '',
			employee_id: '',
 			task_id: '',
			post_type_id: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {

            var values ={
                
                project_id: values.project_id,
                client_id: values.client_id,
                employee_id: values.employee_id,
                 task_id: subTaskid,
                post_type_id:values.post_type_id,
    
            }
			axiosPrivate
				.post('posts/create', values)
				.then((response) => {
					setAlertMessage({ message: 'post created successfully' });
					navigate('/admin/post');
					console.log(response);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			//   alert(JSON.stringify(values, null, 2));
		},
	});

	return (
		<div>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Post</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">
											{/* Project select */}
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="project_id"
														name="project_id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.id}
													>
														<option>Select Project</option>
														{projectL.map((role, key) => (
															<option key={key + role.id} value={role.id}>
																{role.project_name}
															</option>
														))}
													</select>
													{formik.touched.project_id &&
													formik.errors.project_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.project_id}
														</div>
													) : null}
												</div>
											</div>
											{/* client select */}
											<div className="col-md-6 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="client_id"
														name="client_id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.id}
													>
														<option>Select Client</option>
														{clientL.map((role, key) => (
															<option key={key + role.id} value={role.id}>
																{role.client_name}
															</option>
														))}
													</select>
													{formik.touched.client_id &&
													formik.errors.client_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.client_id}
														</div>
													) : null}
												</div>
											</div>


											{/* main */}
											<div className="col-md-4 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="id"
														name="id"
														onChange={(eve) => mainTask(eve)}
														// onChange={formik.handleChange}
														// onBlur={formik.handleBlur}
														value={formik.values.id}
													>
														<option>Select Main Task</option>
														{MainTask?.map((role, key) => (
															<option
																key={key + role.task_name}
																value={role.task_name}
															>
																{role.task_name}
															</option>
														))}
													</select>
													{/* {formik.touched.task_name &&
													formik.errors.task_name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.task_name}
														</div>
													) : null} */}
												</div>
											</div>

											{/*  sub task */}
											<div className="col-md-4 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="task_id"
														name="task_id"
														// onChange={formik.handleChange}
														onChange={(eve) => idChange(eve)}
														onBlur={formik.handleBlur}
														value={formik.values.id}
													>
														<option>Select Sub Task</option>
														{subTask.length > 0 &&
															subTask?.map((role, key) => (
																<option key={key + role.id} value={role.id}>
																	{role.sub_task_name}
																</option>
															))}
													</select>
													{formik.touched.task_id && formik.errors.task_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.task_id}
														</div>
													) : null}
												</div>
											</div>

											{/* post type */}

											<div className="col-md-4 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="post_type_id"
														name="post_type_id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.post_id}
														required
													>
														<option>Select Post Type</option>
														{postTypestate.map((role, key) => (
															<option key={key + role.id} value={role.id}>
																{role.post_name}
															</option>
														))}
													</select>
													{formik.touched.post_type_id &&
													formik.errors.post_type_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.post_type_id}
														</div>
													) : null}
												</div>
											</div>

                                    {/*   users */}
											<div className="col-md-12 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="employee_id"
														name="employee_id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.id}
													>
														<option>Select User</option>
														{userState.map((role, key) => (
															<option key={key + role.name} value={role.id}>
																{role.name}
															</option>
														))}
													</select>
													{formik.touched.employee_id &&
													formik.errors.employee_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.employee_id}
														</div>
													) : null}
												</div>
											</div>


											{/* module */}
											<div className="col-12">
												<hr className="mt-4" />
												{/* <h6>Module Permission</h6>
												<div className="table-responsive">
													<table className="table table-striped">
														<thead>
															<tr>
																<th />
																<th>Read</th>
																<th>Write</th>
																<th>Delete</th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td>Super Admin</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																			defaultChecked
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																			defaultChecked
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																			defaultChecked
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
															</tr>
															<tr>
																<td>Admin</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																			defaultChecked
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
															</tr>
															<tr>
																<td>Employee</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																			defaultChecked
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
															</tr>
															<tr>
																<td>HR Admin</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																			defaultChecked
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																			defaultChecked
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
																<td>
																	<label className="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			name="example-checkbox1"
																			defaultValue="option1"
																			defaultChecked
																		/>
																		<span className="custom-control-label">
																			&nbsp;
																		</span>
																	</label>
																</td>
															</tr>
														</tbody>
													</table>
												</div> */}
												<button type="submit" className="btn btn-primary">
													Add
												</button>
												<button
													type="button"
													className="btn btn-outline-primary ml-2"
													data-dismiss="modal"
													onClick={() => navigate('/admin/post')}
												>
													CLOSE
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddCalendar;
