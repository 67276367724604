import { Formik, useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import DataTable2 from "../../../components/DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./addProjectTaskList.module.css";
import { Link } from "react-router-dom";
import { errorToast, successToast } from "../../../utils/Toaster";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
  name: Yup.string()
    .max(120, "Task Name must not exceed 120 characters")
    .required("Task Name is required"),
//   taskType: Yup.string().required("Please select a Task Type"),
};


const searchItems = ["name"];
const EditProjectTask = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [parent, setParent] = useState([]);
  const [getData, setGetData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get(`task-categories/sub-categories/${id}`)
      .then((projects) => {
        const projectData = projects.data.data.map((value, key) => {
          let buttons = [];
          if (true)
            // buttons.push(
            //   <Link
            //     key="editButton##1"
            //     type="button"
            //     to={`/admin/project-child-task-list/edit/${value.id}`}
            //     className="btn btn-icon btn-outline-info mr-1"
            //     title="Edit"
            //   >
            //     <i className="fa fa-edit"></i>
            //   </Link>
            // );
          if (true)
            buttons.push(
              <button
                key="[nodemon] app crashed - waiting for file changes before starting...leteButton##1"
                type="button"
                data-id={value.id}
                // onClick={() => taskDeleteHandler(value.task_name)}
                className="btn btn-icon js-sweetalert btn-outline-danger"
                title="Delete"
              >
                <i className="fa fa-trash-o text-danger"></i>
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });
        setProjects(projectData);
      })
      .catch((error) => {
      });
  }, []);

  const projectDeleteHandler = async (id) => {
    axiosPrivate
      .delete(`users/${id}`)
      .then(async (response) => {
        if (response.data.code == 200) {
          setProjects([]);
          successToast("User deleted successfully");
          await refreshTable();
        }
      })
      .catch((error) => {
        if (error.response) {
          errorToast(error?.response?.data?.message);
        }
      });
  };

  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axiosPrivate
        .get("projects", options)
        .then((projects) => {
          const projectData = projects.data.data.map((value, key) => {
            let buttons = [];
            if (true)
              buttons.push(
                <button
                  key="editButton##1"
                  type="button"
                  className="btn btn-icon"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </button>
              );
            if (true)
              buttons.push(
                <button
                  key="deleteButton##1"
                  type="button"
                  data-id={value.id}
                  onClick={() => projectDeleteHandler(value.id)}
                  className="btn btn-icon js-sweetalert"
                  title="Delete"
                >
                  <i className="fa fa-trash-o text-danger"></i>
                </button>
              );
            value["action"] = buttons.length > 0 ? buttons : "-";
            return value;
          });
          setProjects(projectData);
          resolve(true);
        })
        .catch((error) => {
          
        });
    });
  };

  useEffect(() => {
    axiosPrivate
      .get("task-categories/categories")
      .then((projects) => {
        setParent(projects?.data?.data);
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get(`task-categories/view/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          const taskData = response.data.data;
          setGetData({ ...taskData });
          formik.setValues({
            name: taskData.name,
            project_id: taskData.project_id,
            taskType: taskData.taskType,
            parent_id: taskData.parent_id,
          });
        }
      })
      .catch((error) => {
      });
  }, [id]);

  const formik = useFormik({
    initialValues: {
      name: "",
      project_id: "",
      taskType: "",
      parent_id: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const requestData = {
        name: values.name,
        project_id: values.project_id,
        parent_id: values.taskType === "parent" ? null : values.parent_id,
      };

      axiosPrivate
        .put(`task-categories/update/${id}`, requestData)
        .then((response) => {
          successToast("Task updated successfully");
          navigate("/admin/project-task-list");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>Edit Project Tasks</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label>
                        Parent Task Name:<span className="required">*</span>
                      </label>
                      <div className="form-group">
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Parent Task"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div className="col-12">
									
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>
											<button type="submit" className="btn btn-primary">
												Update
											</button>
											<button
												type="button"
                        className="btn btn-outline-primary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/project-Task-list')}
											>
												Close
											</button>
										</div>
                </div>

                {/* DataTable2 rendered here */}
               
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProjectTask;
