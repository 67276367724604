import {useState,useCallback, useEffect} from 'react';
import AlertContext from './AlertContext';
import axiosPrivate from '../../hooks/axiosPrivate';

const AlertState = (props) => {
    const [alertMessage, setAlertMessage] = useState(null);
    const [changeNotification, setChangeNotification] = useState(false);

    const sendNotification = async (token, title, message, link, userId, type) => {
      
      await axiosPrivate
                .post("/send-notification", {
                  token: token,
                  title: title,
                  message: message,
                  link: link,
                })
                .then( async (response) => {
                  setChangeNotification(!changeNotification);
                  await axiosPrivate
                  .post("/save-notification", {
                    title: title,
                    message: message,
                    link: link,
                    user_id: userId,
                    type: type
                  })
                  .then((response) => {
                  }).catch((error) => {
                    console.log("Error",error)
                  });
                }).catch((error) => {
                  console.log("Error",error)
                });
    }

    return (
        <AlertContext.Provider value={{ alertMessage, setAlertMessage, changeNotification, setChangeNotification, sendNotification }}>
            {props.children}
        </AlertContext.Provider>
    );
}

export default AlertState;