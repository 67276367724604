import React from "react";
import { Button } from "@material-ui/core";
import { exportToExcel } from "react-json-to-excel";
import moment from "moment/moment";
function ExcelExportComponent({ data, reportType, reportName, reportTime }) {
  const samplejson2 = [];

  function convertToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes < 10 ? "0" : ""}${remainingMinutes}`;
  }

  data.map((value) => {
    value["due_date"] = moment(value?.due_date).format("DD MMM YYYY");
    value["created_at"] = moment(value?.created_at).format("DD MMM YYYY");
    value["estimated_time"] = `${convertToHoursAndMinutes(value.estimated_time)} Hrs`;
    value["actual_time"] = `${convertToHoursAndMinutes(value.actual_time)} Hrs`;
  

    // This is for project report
    value?.summary?.map((item) =>{
      item["actual_time"] = `${convertToHoursAndMinutes(item?.actual_time)} Hrs`;
      let priority = item?.priority;
    if (priority === "1") {
      item["priority"] = "Low";
    } else if (priority === "2") {
      item["priority"] = "Medium";
    } else if (priority === "3") {
      item["priority"] = "High";
    } else if (priority === "4") {
      item["priority"] = "Critical";
    }
    })

    // This is for employee report
    let priority = value.priority;
    if (priority === "1") {
      value["priority"] = "Low";
    } else if (priority === "2") {
      value["priority"] = "Medium";
    } else if (priority === "3") {
      value["priority"] = "High";
    } else if (priority === "4") {
      value["priority"] = "Critical";
    }

    // let priority = value.priority;
    // if (priority === "1") {
    //   value["priority"] = "Low";
    // } else if (priority === "2") {
    //   value["priority"] = "Medium";
    // } else if (priority === "3") {
    //   value["priority"] = "High";
    // } else if (priority === "4") {
    //   value["priority"] = "Critical";
    // }
  
    value["replies"] = value?.replies?.filter((reply) => {
      reply.date = moment(reply?.date).format("DD MMM YYYY");
  
      // Convert HTML content to plain text
      const parser = new DOMParser();
      const doc = parser.parseFromString(reply.reply, 'text/html');
      reply.reply = doc.body.textContent || "";
  
      if(reportName === "Webanix Report"){
        return reply;
      }else if(reportName === "Client Report"){
        return reply.internal_external === 1;
      }else{
        return reply;
      }
    });
  
    return value;
  });

  if(reportName === "Webanix Report"){
    for (let i = 0; i < data.length; i++) {
       data[i].summary?.map((summary) =>{
        const obj = {
          "Project Name": data[i]?.project_name,
          "Department": data[i]?.department,
          "Employee Name": summary?.name,
          "Category": data[i]?.category_name,
          "Task": data[i]?.sub_category,
          "Task Description": data[i]?.title,
          "Estimated Time": data[i]?.estimated_time,
          "Actual Time": summary?.actual_time,
          "Priority": summary?.priority,
          "Status": summary?.status,
          "Due Date": data[i]?.due_date,
        };
        samplejson2.push(obj);
       })
      }
  }else if(reportName === "Client Report"){
    for (let i = 0; i < data.length; i++) {

      if (!data[i].replies || data[i].replies.length === 0) {
        
          if(i === 0){
            const obj = {
              "Project Name": data[i].project_name,
              "Estimated Time": `${reportTime?.estimated_hours} Hrs`,
              "Employee Name": data[i].assigned_to_username,
              "Category": data[i].category_name,
              "Task": data[i].sub_category,
              "Task Description": data[i].title,
              "Reply": "-", // No reply content
              "Reply Date": "-", // No reply date
              // "Actual Time": data[i].actual_time,
              "Status": data[i].status,
            };
            samplejson2.push(obj);
          }else{
            const obj = {
              "Employee Name": data[i].assigned_to_username,
              "Category": data[i].category_name,
              "Task": data[i].sub_category,
              "Task Description": data[i].title,
              "Reply": "-", // No reply content
              "Reply Date": "-", // No reply date
              "Status": data[i].status,
            };
            samplejson2.push(obj);
          }
        
      } else {
        data[i].replies.forEach((reply, index) => {
          const obj = {
            "Employee Name": reply?.assigned_to_username,
            "Category": reply?.category_name,
            "Task": reply?.sub_category,
            "Task Description": reply?.title,
            "Reply": reply?.reply || "-",
            "Reply Date": reply?.date || "-",
            "Status": reply?.status
          };
          samplejson2.push(obj);
        });
      }
    }
  }else{
    for (let i = 0; i < data.length; i++) {

        const obj = {
          "Project Name": data[i].project_name,
          "Department": data[i].department,
          "Employee Name": data[i].assigned_to_username,
          "Category": data[i].category_name,
          "Task": data[i].sub_category,
          "Task Description": data[i].title,
          "Estimated Time": data[i].estimated_time,
          "Actual Time": data[i].actual_time,
          "Priority": data[i].priority,
          "Status": data[i].status,
          "Due Date": data[i].due_date,
        };
        samplejson2.push(obj);
      }
  }

  return (
    <Button
        variant="contained"
        style={{ backgroundColor: "#5a5278", color: "#fff" }}
        className="export-btn"
        onClick={() => exportToExcel(samplejson2, reportType)}
      >
        Export Excel
      </Button>
  );
}

export default ExcelExportComponent;
