import { useState } from "react";

import AdminData from "../project/Dashboard/AdminData";
import LeadData from "../project/Dashboard/LeadData";
import EmployeeData from "../project/Dashboard/EmployeeData";
import ClientData from "../project/Dashboard/ClientData";
import NotificationCount from "../project/Dashboard/NotificationCount";
import styles from "./notification-view.module.css"      
const DashBoard = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  
  return (
    <>
      <div className={styles.displayNotification}>
      <NotificationCount />
      </div>
      {userData?.role === "1" && <AdminData userData = {userData}/>}

      {userData?.role === "2" && <EmployeeData userData = {userData}/>}

      {userData?.role === "3" && <ClientData userData = {userData}/>}

      {userData?.role === "4" && <LeadData userData = {userData}/>}

      

      
    </>
  );
};

export default DashBoard;
