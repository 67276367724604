import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";

import styles from "./TodoListFixedControls.module.css";
import "./toggle.css";
import { IoIosClose } from "react-icons/io";
import { Calendar } from "primereact/calendar";
import dayjs from "dayjs";
import NotificationCount from "./NotificationCount";

const FilterTask = (props) => {
  const [projectNameFilter, setProjectNameFilter] = useState("");
  const [employeeNameFilter, setEmployeeNameFilter] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [departmentList, setDepartmentList] = useState([
    { name: "Development", id: "1" },
    { name: "Digital", id: "2" },
  ]);
  const [lead, setLead] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [departmentNameFilter, setDepartmentNameFilter] = useState("");
  const [dateNameFilter, setDateNameFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [projectList, setProjectList] = useState([]);
  let userDetails = {};
  if (localStorage.getItem("userData")) {
    userDetails = JSON.parse(localStorage.getItem("userData"));
  }

  useEffect(() => {
    const filterTasks = (tasks) => {
      if (tasks?.length > 0) {
        return tasks?.filter(
          (item) =>
            item?.project_name
              ?.toLowerCase()
              .includes(projectNameFilter?.toLowerCase()) &&
            item?.assigned_to_username
              ?.toLowerCase()
              .includes(employeeNameFilter?.toLowerCase()) &&
            (statusFilter === "" || item?.status === statusFilter) &&
            (departmentNameFilter === "" ||
              item?.department
                ?.toLowerCase()
                .includes(departmentNameFilter?.toLowerCase())) &&
            item?.due_date?.includes(dateNameFilter)
        );
      }
    };

    if (props?.tasks || props?.completedTasks) {
      props?.filteredData(
        !checked
          ? filterTasks(props?.tasks)
          : filterTasks(props?.completedTasks)
      );
    }

    if (props?.leadData) {
      props?.leadData(filterTasks(props?.leadTasks));
    }

    if (props?.setChecked) {
      props?.setChecked(checked);
    }

    if (props?.setLead) {
      props?.setLead(lead);
    }
  }, [
    projectNameFilter,
    employeeNameFilter,
    statusFilter,
    departmentNameFilter,
    dateNameFilter,
    checked,
    lead,
    props?.tasks,
    props?.completedTasks,
    props?.leadTasks,
  ]);

  // Formatting date in dd-mm-yyyy format

  const formatDate = (date) => {
    // Format the date using dayjs
    const formattedDate = dayjs(date).format("DD-MM-YYYY");
    // Set the formatted date to the state
    setDateNameFilter(formattedDate);
    setSelectedDate(date);
  };

  const clearDateFilter = () => {
    // Clear the date filter
    setDateNameFilter("");
    setSelectedDate(null);
    // alert(dateNameFilter);
  };

  // Checking that any task is available to perform today or not

  useEffect(() => {
    if (props?.users) {
      const fetchedEmployeeOptions = props?.users
        ?.filter((employee) => employee?.role_name !== "Client")
        ?.map((employee) => ({
          value: employee?.id,
          label: employee?.name,
          role_name: employee?.role_name,
        }));
      setEmployeeList(fetchedEmployeeOptions);
    }

    if (props?.projectDetails) {
      const fetchedProjectOptions = props?.projectDetails?.map((project) => ({
        value: project?.project_id,
        label: project?.project_name,
      }));
      setProjectList(fetchedProjectOptions);
    }
  }, [
    props?.users,
    props?.completedTasks,
    props?.leadTasks,
    props?.projectDetails,
  ]);

  const statusOptions1 = [
    { value: "Pending", label: "Pending" },
    { value: "In Progress", label: "In Progress" },
    { value: "Overdue", label: "Overdue" },
    { value: "On Hold", label: "On Hold" },
    
  ];

  const statusOptions2 = [
    { value: "Ready For Q/A", label: "Ready For Q/A" },
    { value: "Ready For UAT", label: "Ready For UAT" },
    { value: "Done", label: "Done" },
  ];

  // Formatting date to show like jan, feb etc. in tasks list

  return (
    <>
      <div className={styles.reportContainer}>
        {userDetails?.role === "4" && (
          <div className={styles.toggleContainer}>
            <div>My Tasks</div>
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={lead}
                  onChange={() => setLead(!lead)}
                />
              </label>
            </div>
            <div>Team Tasks</div>
          </div>
        )}
        <div className={styles.toggleContainer}>
          <>
            <div>To Do Tasks</div>
            <div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </label>
            </div>
            <div>Completed Tasks</div>
          </>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "20%",
                top:'10%',
                cursor: "pointer",
                zIndex: "1",
              }}
              onClick={clearDateFilter}
            >
              {selectedDate !== null && <IoIosClose size={20} />}
            </div>
            <div className="card flex justify-content-center">
              <Calendar
                // value={dateNameFilter}
                value={selectedDate}
                style={{height:'30px', width:'205px'}}
                placeholder="DD-MM-YYYY"
                onChange={(e) => {
                  formatDate(e.value);
                  setSelectedDate(e.value)
                }}
                dateFormat="dd-mm-yy"
                showIcon
              />
            </div>
          </div>
        </div>
        <NotificationCount/>
      </div>
      <div className="d-block d-md-flex " style={{ gap: "5px" }}>
        <Select
          className="form-control p-0 border-0 custom-style "
          value={projectList?.find(
            (project) => project.label === projectNameFilter
          )}
          onChange={(selectedOption) =>
            setProjectNameFilter(selectedOption ? selectedOption.label : "")
          }
          options={projectList}
          placeholder="Select Project"
          isSearchable
          isClearable={true}
          styles={{
            control: (styles) => ({
              ...styles,
              fontSize: "1rem",
            }),
            menu: (provided) => ({
              ...provided,
              overflowY: "auto",
            }),
            option: (provided) => ({
              ...provided,
              fontSize: "1rem",
              // backgroundColor:'blue'
            }),
          }}
        />

        {userDetails?.role !== "2" && userDetails?.role !== "3" && (
          <Select
            className="form-control p-0 border-0 custom-style "
            value={employeeList?.find(
              (employee) => employee.label === employeeNameFilter
            )}
            onChange={(selectedOption) => {
              if (selectedOption) {
                const nameParts = selectedOption?.label.split(" ");
                if (nameParts && nameParts.length > 0) {
                  const firstName = nameParts[0];
                  const lastName =
                    nameParts.length > 1
                      ? nameParts[nameParts.length - 1].slice(0, 1) + "."
                      : "";
                  const formattedName = `${firstName} ${lastName}`;
                  const formattedNameCapitalized =
                    formattedName.charAt(0).toUpperCase() +
                    formattedName.slice(1);
                  setEmployeeNameFilter(formattedNameCapitalized);
                }
              } else {
                setEmployeeNameFilter("");
              }
            }}
            options={employeeList}
            placeholder="Select Employee"
            isSearchable
            isClearable={true}
            styles={{
              control: (styles) => ({
                ...styles,
                fontSize: "1rem",
              }),
              menu: (provided) => ({
                ...provided,
                overflowY: "auto",
              }),
              option: (provided) => ({
                ...provided,
                fontSize: "1rem",
              }),
            }}
          />
        )}

        <Select
          className="form-control form-control p-0 border-0  custom-style"
          value={
            !checked
              ? statusOptions1?.find((option) => option.value === statusFilter)
              : statusOptions2?.find((option) => option.value === statusFilter)
          }
          onChange={(selectedOption) =>
            setStatusFilter(selectedOption ? selectedOption.value : "")
          }
          options={!checked ? statusOptions1 : statusOptions2}
          isSearchable
          placeholder="Select Status"
          isClearable={true}
          styles={{
            control: (styles) => ({
              ...styles,
              fontSize: "1rem",
            }),

            menu: (provided) => ({
              ...provided,
              // maxHeight: "150px",
              overflowY: "auto",
            }),
            option: (provided) => ({
              ...provided,
              fontSize: "1rem",
            }),
          }}
        />

        {userDetails?.role !== "2" && userDetails?.role !== "3" && (
          <Select
            className="form-control p-0 border-0 custom-style "
            value={departmentList.find(
              (department) => department.label === departmentNameFilter
            )}
            onChange={(selectedOption) =>
              setDepartmentNameFilter(
                selectedOption ? selectedOption.label : ""
              )
            }
            options={departmentList.map((department) => ({
              value: department.id,
              label: department.name,
            }))}
            placeholder="Select Department"
            isSearchable
            isClearable={true}
            styles={{
              control: (styles) => ({
                ...styles,
                fontSize: "1rem",
              }),
              menu: (provided) => ({
                ...provided,
                overflowY: "auto",
              }),
              option: (provided) => ({
                ...provided,
                fontSize: "1rem",
              }),
            }}
          />
        )}
      </div>
    </>
  );
};

export default FilterTask;
