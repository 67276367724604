import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import Loader from "../../../context/Loader/Loader";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Swal from "sweetalert2";
import AuthContext from "../../../context/Auth/AuthContext";
const Users = (props) => {
  const [projects, setProjects] = useState([]);
  const [client, setClient] = useState([]);
  const [team, setTeam] = useState([]);
  const { loading, setIsLoading } = useContext(LoaderContext);
  const [refreshState, setRefreshState] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem("userData"));

  const { authPermissions } = useContext(AuthContext);

  const userName = userDetails?.userName;

  const headers = [
    userDetails?.role !== "3" && {
      name: "Client Name",
      field: "client_name",
      sortable: true,
      classKey: "w60",
    },

    {
      name: "Project Name",
      field: "project_name",
      sortable: true,
      classKey: "",
    },

    // { name: "Team Name", field: "team_name", classKey: "" },

    // {
    //   name: "Employee Names",
    //   field: "employee_name",
    //   sortable: true,
    //   classKey: "",
    // },

    { name: "Due Date", field: "due_date", sortable: true, classKey: "" },

    { name: "Type", field: "type", sortable: true, classKey: "" },

    { name: "Action", field: "action", classKey: "" },
  ];

  const searchItems = ["project_name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setIsLoading(true);
    axiosPrivate
      // .get("all-projects-details", options)
      .get("projects", options)
      .then((projects) => {
        // setAlertMessage({ message: "Projects fetched successfully" });

        const projectData = projects?.data?.data?.map((value) => {
          let buttons = [];

          if (authPermissions.includes("Project List-edit")) {
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/project-list/edit/${value?.project_name?.replaceAll(
                  " ",
                  "-"
                )}`}
                className={
                  window?.innerWidth >= 768
                    ? "btn btn-icon btn-outline-primary mr-1"
                    : "editButton"
                }
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          }

          if (authPermissions.includes("Project List-delete")) {
            buttons.push(
              <span
                key="deleteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => projectDeleteHandler(value.project_id)}
                className={
                  window?.innerWidth >= 768
                    ? "btn btn-icon js-sweetalert btn-outline-danger"
                    : "deleteButton"
                }
                title="Delete"
              >
                <i className="fa fa-trash-o "></i>
              </span>
            );
          }

          value["type"] = (
            <span style={{ textTransform: "capitalize" }}>{value?.type}</span>
          );

          value["action"] = buttons.length > 0 ? buttons : "-";

          const parsedDate = new Date(value?.due_date);

          parsedDate.setHours(0, 0, 0, 0);

          const day = parsedDate.getDate().toString().padStart(2, "0");
          const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
          const year = parsedDate.getFullYear();

          value["due_date"] = `${day}-${month}-${year}`;

          return value;
        });
        setProjects(projectData);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [refreshState]);

  const projectDeleteHandler = async (id) => {
    showConfirmMessage(async () => {
      try {
        const response = await axiosPrivate.delete(`project/delete/${id}`);
        if (response.data.code === 200) {
          setRefreshState(!refreshState);
          Swal.fire("Deleted!", `${response.data.message}`, "success");
        } else {
          Swal.fire("Error", `${response.data.message}`, "error");
        }
      } catch (error) {
        Swal.fire("Error", "There was an error deleting the project.", "error");
      }
    });
  };

  function showConfirmMessage(callback) {
    Swal.fire({
      title: "Are you sure?",
      text: "By clicking 'Yes', this project will be permanently deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      customClass: {
        cancelButton: "btn btn-outline-primary ml-2",
        confirmButton: "btn btn-primary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
      } else {
        Swal.fire("Cancelled", "Project deletion cancelled.", "error");
      }
    });
  }

  return (
    <>
      <div>
        {" "}
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className="section-body mt-3">
              <div>
                <div className="tab-content mt-3">
                  <div
                    className="tab-pane fade show active"
                    id="user-list"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h3 className="card-title">PROJECT LIST</h3>
                        <div className="header-action">
                          {authPermissions.includes("Project List-create") && (
                            <Link to="add" className="text-decoration-none">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                <i className="fe fe-plus mr-2" />
                                Add
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <DataTable2
                            lists={projects}
                            headers={headers}
                            searchItems={searchItems}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <style>
        {`
              .permissionButton{
              cursor: pointer;
              padding: 7px; 
              color:#28a745; 
              position:relative; 
              top: -2px
              }

              .editButton{
              padding: 7px; 
              color:#5a5278
              }

              .deleteButton{
              cursor: pointer; 
              padding: 7px;
              color:#dc3545
              }
            `}
      </style>
    </>
  );
};

export default Users;
