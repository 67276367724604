import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { FaLock } from "react-icons/fa";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";
import { errorToast, successToast } from "../../../utils/Toaster";
import AuthContext from "../../../context/Auth/AuthContext";
const RolePermission = (props) => {
  const [rolePermission, setrolePermission] = useState([]);
  const { loading, setIsLoading } = useContext(LoaderContext);
  const [employeeState, setEmployeeState] = useState([]);

  const { authPermissions } = useContext(AuthContext);

  const headers = [
    {
      name: "ROLE",
      field: "name",
      classKey: "",
    },

    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setIsLoading(true);
    axiosPrivate
      .get("roles", options)
      .then((roleper) => {
        // setAlertMessage({ message: "Role & Permissions fetched successfully" });
        const roleData = roleper.data.data.map((value, key) => {
          let buttons = [];

          if (true) {
            buttons.push(
              <Link
                key={`editButton##${key}`}
                type="button"
                to={`/admin/rolepermission/edit/${value.id}`}
                className={
                  window?.innerWidth >= 768
                    ? "btn btn-icon btn-outline-success mr-1"
                    : "permissionButton"
                }
                title="View"
              >
                <FaLock />
              </Link>
            );
          }

          if (authPermissions.includes("Role Permissions-edit")) {
            buttons.push(
              <Link
                key={`editButton##${key}`}
                type="button"
                to={`/admin/roles/edit/${value.id}`}
                className={
                  window?.innerWidth >= 768
                    ? "btn btn-icon btn-outline-primary mr-1"
                    : "editButton"
                }
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          }

          // if (true)
          //   buttons.push(
          //     <button
          //       key={`deleteButton##${key}`}
          //       type="button"
          //       data-id={value.id}
          //       onClick={() => roleDeleteHandler(value.id)}
          //       className="btn btn-icon js-sweetalert btn-outline-danger"
          //       title="Delete"
          //     >
          //       <i className="fa fa-trash-o"></i>
          //     </button>
          //   );
          value.action = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setrolePermission(roleData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          //response status is an error code
        } else if (error.request) {
          //response not received though the request was sent
        } else {
          //an error occurred when setting up the request
        }
      });
  }, []);

  const roleDeleteHandler = async (id) => {
    axiosPrivate
      .delete(`roles/${id}`)
      .then(async (response) => {
        if (response.data.code == 200) {
          setEmployeeState([]);
          successToast("company deleted successfully");
          await refreshTable();
        }
      })
      .catch((error) => {
        errorToast(error?.response?.data?.message || "Something went wrong!");
      });
  };
  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axiosPrivate
        .get("companies", options)
        .then((companies) => {
          const companiesData = companies.data.data.map((value, key) => {
            let buttons = [];
            if (true)
              buttons.push(
                <button
                  key="editButton##1"
                  type="button"
                  className="btn btn-icon"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </button>
              );
            if (true)
              buttons.push(
                <button
                  key="deleteButton##1"
                  type="button"
                  data-id={value.id}
                  onClick={() => roleDeleteHandler(value.id)}
                  className="btn btn-icon js-sweetalert"
                  title="Delete"
                >
                  <i className="fa fa-trash-o text-danger"></i>
                </button>
              );
            value["action"] = buttons.length > 0 ? buttons : "-";
            return value;
          });
          setEmployeeState(companiesData);
          resolve(true);
        })
        .catch((error) => {});
    });
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="section-body mt-3">
            <div>
              <div className="tab-content mt-3">
                <div
                  className="tab-pane fade show active"
                  id="user-list"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header d-flex justify-content-between">
                      <h3 className="card-title">ROLE PERMISSION LIST</h3>
                      <div className="header-action">
                        {authPermissions.includes(
                          "Role Permissions-create"
                        ) && (
                          <Link to="add" className="text-decoration-none">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <i className="fe fe-plus mr-2" />
                              Add
                            </button>
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <DataTable2
                          lists={rolePermission}
                          headers={headers}
                          searchItems={searchItems}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <style>
        {`
              .permissionButton{
              cursor: pointer;
              padding: 7px; 
              color:#28a745; 
              position:relative; 
              top: -2px
              }

              .editButton{
              padding: 7px; 
              color:#5a5278
              }

              .deleteButton{
              cursor: pointer; 
              padding: 7px;
              color:#dc3545
              }
            `}
      </style>
    </div>
  );
};

export default RolePermission;
