import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Rolepermission.module.css";
import { errorToast, successToast } from "../../../utils/Toaster";
import React, { useContext, useEffect, useState } from "react";
const validationRules = {
  name: Yup.string()
    .max(200, "Name must not exceed 200 characters")
    .required("Role Name is required"),
};

const AddRoles = () => {
  const navigate = useNavigate();

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    axiosPrivate.get("roles").then((user_roles) => {
      setRoles(user_roles?.data?.data);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
    

      axiosPrivate
        .post("roles", values)
        .then((response) => {
          successToast(response.data.message);
          navigate("/admin/rolepermission");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  return (
    <div className="section-body">
      <div>
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="role-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>Add Roles</strong>
                </div>

                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>
                          Name:<span className="required">*</span>
                        </label>
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Roles*"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12">
                 
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <tbody></tbody>
                        </table>
                      </div>
                      <button type="submit" className="btn btn-primary">
                      Submit

                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/rolepermission")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddRoles;
