import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/Loader/LoaderContext";
import Loader from "../../../context/Loader/Loader";
import { successToast } from "../../../components/common/Toaster";
import { errorToast } from "../../../utils/Toaster";
import Swal from "sweetalert2";
import AuthContext from "../../../context/Auth/AuthContext";

const Company = (props) => {
  const [clientState, setClientState] = useState([]);
  const [refreshState, setRefreshState] = useState(false);
  const { loading, setIsLoading } = useContext(LoaderContext);

  const { authPermissions } = useContext(AuthContext);
  const headers = [
    { name: "CLIENT NAME", field: "name", sortable: true, classKey: "" },
    // { name: 'COMPANY NAME', field: 'name', sortable: true, classKey: '' },
    // { name: 'DESIGNATION', field: 'designation', sortable: true, classKey: '' },
    { name: "EMAIL", field: "email", sortable: true, classKey: "" },
    { name: "MOBILE", field: "mobile", classKey: "" },
    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    setIsLoading(true);
    axiosPrivate
      .get("clients", options)

      .then((companies) => {
        // setAlertMessage({ message: "Clients fetched successfully" });

        const companiesData = companies.data.data.map((value, key) => {
          let buttons = [];

          if (authPermissions.includes("Clients-edit")) {
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/usersregistration/edit/${value.id}`}
                className={
                  window?.innerWidth >= 768
                    ? "btn btn-icon btn-outline-primary mr-1"
                    : "editButton"
                }
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          }

          if (authPermissions.includes("Clients-delete")) {
            buttons.push(
              <span
                key="deleteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => clientDeleteHandler(value.id)}
                className={
                  window?.innerWidth >= 768
                    ? "btn btn-icon js-sweetalert btn-outline-danger"
                    : "deleteButton"
                }
                title="Delete"
              >
                <i className="fa fa-trash-o"></i>
              </span>
            );
          }

          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setClientState(companiesData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          errorToast(error?.response?.data?.message);
        }
      });
  }, [refreshState]);

  const clientDeleteHandler = async (id) => {
    showConfirmMessage(() => {
      axiosPrivate
        .delete(`users/${id}`)
        .then(async (response) => {
          if (response.data.code === 200) {
            setRefreshState(!refreshState);
            successToast(response.data.message);
          }
        })
        .catch((error) => {
          errorToast(error.message);
        });
    });
  };

  function showConfirmMessage(callback) {
    Swal.fire({
      title: "Are you sure?",
      text: "By clicking 'Yes', this client will be permanently deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      customClass: {
        cancelButton: "btn btn-outline-primary ml-2 ",
        confirmButton: "btn btn-primary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
        Swal.fire("Deleted!", "Client has been deleted.", "success");
      } else {
        Swal.fire("Cancelled", "Client deletion cancelled.", "error");
      }
    });
  }

  return (
    <>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div>
            <div className="section-body mt-3">
              <div>
                <div className="tab-content mt-3">
                  <div
                    className="tab-pane fade show active"
                    id="user-list"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-header d-flex justify-content-between">
                        <h3 className="card-title">CLIENT LIST</h3>
                        <div className="header-action">
                          {authPermissions.includes("Clients-create") && (
                            <Link
                              to="/admin/usersregistration/add"
                              className="text-decoration-none"
                            >
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                <i className="fe fe-plus mr-2" />
                                Add
                              </button>
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <DataTable2
                            lists={clientState}
                            headers={headers}
                            searchItems={searchItems}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <style>
        {`
              .permissionButton{
              cursor: pointer;
              padding: 7px; 
              color:#28a745; 
              position:relative; 
              top: -2px
              }

              .editButton{
              padding: 7px; 
              color:#5a5278
              }

              .deleteButton{
              cursor: pointer; 
              padding: 7px;
              color:#dc3545
              }
            `}
      </style>
    </>
  );
};

export default Company;
