import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomTable from "../TodoList/CustomDataTable";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { FaEye } from "react-icons/fa";
import { successToast, errorToast } from "../../../utils/Toaster";

const TodoList = (props) => {
  const [postTypedata, setPostTypedata] = useState([]);

  
  const formatDate = (inputDateStr) => {
    if (!inputDateStr) {
      return null;
    }

    const parsedDate = new Date(inputDateStr);
    parsedDate.setHours(0, 0, 0, 0);

    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {

    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("tasks", options)
      .then((posttypes) => {
        const posttypeData = posttypes?.data?.data?.map((value) => {
          value["new"] = value?.priority;
          let buttons = [];
          
    
          buttons.push(
            <Link
              key="viewButton##1"
              type="button"
              to={`/admin/project-ticket-list/view/${value?.id}`}
              className="btn btn-icon btn-outline-success mr-1 mb-1"
              title="View"
            >
              <FaEye />
            </Link>
          );
    
          value["priority"] = (
            <div>
              {value?.priority === "4" && (
                <span
                  className="status critical"
                 
                
                >
                  Critical
                </span>
              )}
              {value?.priority === "3" && (
                <span
                  className="status high"
                 
               
                >
                  High
                </span>
              )}
              {value?.priority === "1" && (
                <span
                  className="status low"
                 
                
                >
                  Low
                </span>
              )}
              {value?.priority === "2" && (
                <span
                  className="status medium"
                 
                 
                >
                  Medium
                </span>
              )}
            </div>
          );
          value["due_date"] = formatDate(value["due_date"]);
          value["action"] = buttons?.length > 0 ? buttons : "-";
          return value;
        });
    
        setPostTypedata(posttypeData);
      })
      .catch((error) => {
      });
  }, []);

  



  return (
    <>
      <div>
        <div>
         

          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div
                className="tab-pane fade show active"
                id="user-list"
                role="tabpanel"
              >
                <div className="card">
                 
                  <div className="card-body">
                    {/* Use the CustomTable component */}
                    <CustomTable
                      data={postTypedata}
                      projectDetails={props?.projectList}
                      users={props?.users}
                      completedTask={props?.completedTask}
                      leadTask={props?.leadTask}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TodoList;
