import { useState, useEffect } from "react";
import AuthContext from "./AuthContext";
import axiosPrivate from '../../hooks/axiosPrivate';
import { useLocation, useNavigate, useParams} from "react-router-dom";

const AuthState = (props) => {
  const navigate = useNavigate();
 const {pathname}=useLocation()

  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userData") || "{}")
  );
  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("userData") || "{}")
  );
  const [authPermissions, setAuthPermissions] = useState([]);

  useEffect(() => {
    if (user !== "" && user?.userId !== undefined) {
      axiosPrivate
        .get(`user-has-permissions/user/${user?.userId}`)
        .then((fetched_user_permissions) => {
          if (fetched_user_permissions.data.data.length) {
            let mergedPermissions = [];
            for (
              let i = 0;
              i < fetched_user_permissions.data.data.length;
              i++
            ) {
              mergedPermissions.push(
                `${fetched_user_permissions.data.data[i].name}-${fetched_user_permissions.data.data[i].action}`
              );
            }

            setAuthPermissions(mergedPermissions);
          }
        })
        .catch((error) => {
         
        });
    }
    else {
      navigate("/signin");
    }
  }, [user]);

  function changeAuthPermissions() {
    if (user != "" && user.userId != undefined) {
      axiosPrivate
        .get(`user-has-permissions/user/${user.userId}`)
        .then((fetched_user_permissions) => {
          if (fetched_user_permissions.data.data.length) {
            let mergedPermissions = [];
            for (
              let i = 0;
              i < fetched_user_permissions.data.data.length;
              i++
            ) {
              mergedPermissions.push(
                `${fetched_user_permissions.data.data[i].name}-${fetched_user_permissions.data.data[i].action}`
              );
            }
            setAuthPermissions(mergedPermissions);
          }
        })
        .catch((error) => {
        
        });
    }
  }

  function checkPermissionExists(permission) {
    if (authPermissions.includes(permission)) {
      return true;
    }
    return false;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authPermissions,
        setAuthPermissions,
        changeAuthPermissions,
        checkPermissionExists,
        userDetails,
        setUserDetails,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
