import { Fragment } from "react";
import SideBar from './SideBar';
import Header from './Header';
import Footer from './Footer'
import { useState,useEffect,useContext } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthContext from '../../context/Auth/AuthContext';

const AuthMain = (props) => {

    const { userDetails } = useContext(AuthContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (userDetails?.token) {
     
      navigate('admin');
    }
    else {
      navigate('signin')
    }
  }, [])


    return (
        <Fragment>
        <div className="font-montserrat">  
            <div className="auth">   
                <Outlet />
            </div>
        </div>
        </Fragment>
    );
};

export default AuthMain;
