import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosApiSigninInstance from "../../hooks/axiosLogin";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { errorToast } from "../../utils/Toaster";
import webPm from "../../assets/images/webpm-logo (1).png";

const SignIn = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const loginSchema = Yup.object({
    email: Yup.string().email().required("Email is Required"),
    password: Yup.string().required(" Password is Required"),
  });

  const loginForm = {
    email: "",
    password: "",
  };

  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: loginForm,
      validationSchema: loginSchema,
      onSubmit: (data, values) => {
        axiosApiSigninInstance
          .post("login", data)
          .then((res) => {
            console.log(res?.data);
            localStorage.setItem("userData", JSON.stringify(res.data));
            localStorage.setItem(
              "userRefreshToken",
              JSON.stringify(res?.data?.refresh_token)
            );
            window.location.reload();
           
            
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                console.log(err.response.data.message);
                errorToast(err.response.data.message, {});
              } else if (err.response.status === 400) {
                console.log(err.response.data.message);
                errorToast(err.response.data.message, {});
              }
            }
          });
      },
    });

  useEffect(() => {
    if (JSON.parse(localStorage?.getItem("userData"))?.token) {
      navigate("/admin", { replace: true });
    }
  }, [navigate]);

  const togglePasswordVisibility = () => {
    console.log(values?.password);
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="auth_left">
        <div className="card" style={{ right: "0px", marginBottom: "0px" }}>
          <div className="text-center mb-2">
            <Link className="header-brand" to="/">
              <img src={webPm} width={"65px"} alt="WebPM Logo" />
            </Link>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="card-title">Login to your account</div>

              <div className="form-group">
                <label className="form-label">Email</label>
                <input
                  type="text"
                  name="email"
                  id="id"
                  className="form-control"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Email"
                />
                {touched.email && errors.email ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.email}
                  </div>
                ) : null}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Password
                </label>
                <div className="position-relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  id="password"
                  className="form-control"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Password"
                />
                {touched.password && errors.password ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errors.password}
                  </div>
                ) : null}
                {
                   values?.password && (
                    <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "23%",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? (
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  )}
                </span>
                   )
                }
                </div>
              </div>

              <div className="form-group">
                <label className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" />
                  <span className="custom-control-label">Remember me</span>
                </label>
              </div>
              <div className="form-footer">
                <input
                  type="submit"
                  className="btn btn-primary btn-block"
                  value="Login"
                />
              </div>
            </form>
          </div>
          {/* <div className="text-center text-muted">
                        Don't have account yet? <Link to="/signup">Sign Up</Link>
                    </div> */}
        </div>
      </div>
    </>
  );
};

export default SignIn;
