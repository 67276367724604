import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Calendar } from "primereact/calendar";
import { errorToast, successToast } from "../../../utils/Toaster";
import classes from "../ProjectTaskList/addProjectTaskList.module.css";
import AlertContext from "../../../context/Alert/AlertContext";
const validationRules = {
  category_id: Yup.number().required(" Category name is required"),
  sub_category: Yup.string().required(" Task  is required"),
  title: Yup.string().required(" Task description is required"),
  // assigned_to_user_id: Yup.string().required(" Employee is required"),
  priority: Yup.string().required(" Priority is required"),
  status: Yup.string().required("Status is required"),
  due_date: Yup.string().required("Due date is required"),

  // estimated_hours: Yup.number()
  //   .positive("Time should be a positive number")
  //   .integer("Time should be an integer")
  //   .required("Time is required"),
};
const EditAddTask = () => {
  const inputRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [category, setCategory] = useState([]);
  const { sendNotification } = useContext(AlertContext);

  const formik = useFormik({
    initialValues: {
      title: "",
      category_id: "",
      sub_category: "",
      description: "",
      assigned_to_user_id: "",
      estimated_hours: "",
      priority: "",
      due_date: new Date().toISOString().split("T")[0],
    },

    validationSchema: Yup.object(validationRules),
    onSubmit: async (values) => {
      try {
        const response = await axiosPrivate
          .put(`task/update/${id}`, values)
          .then((res) => {
            
            res?.data?.data?.map((data) => {
              if (data?.register_id) {
                sendNotification(
                  data?.register_id,
                  data?.sub_category,
                  data?.title,
                  `/admin/project-ticket-list/view/${data?.id}`,
                  data?.user_id,
                  1
                );
              }

              successToast("Task updated successfully");
              navigate(-1);
            });
          })
          .catch((error) => {});
      } catch (error) {
        errorToast(error?.response?.data?.message);
      }
    },
  });

  useEffect(() => {
    axiosPrivate
      .get(`tasksById/${id}`)
      .then((response) => {
        if (response.data.code === 200) {
          formik.setValues(response.data?.data);
          axiosPrivate
            .get(
              `all-project-details-by-slug/${response.data?.data?.project_name}`
            )
            .then((response) => {
              setEmployee(
                response?.data?.data[0]?.teams[0]?.team_members || ""
              );
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("task-categories/categories")
      .then((res) => {
        if (res.data.code === 200) {
          setCategory(res.data.data);
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    // axiosPrivate
    //   .get("users")
    //   .then((response) => {
    //     const fetchedEmployeeOptions = response.data.data
    //       .filter((employee) => employee.role_name === "Employee")
    //       .map((employee) => ({
    //         value: employee.id,
    //         label: employee.name,
    //         role_name: employee.role_name,
    //       }));
    //     setEmployee(fetchedEmployeeOptions);
    //   })
    //   .catch((error) => {
    //     errorToast(error?.response?.data?.message);
    //   });
  }, []);
  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="section-body mt-3">
      <div>
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong> Edit Project Task</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix"></div>
                  <div className="col-12">
                    <div className="table-responsive">
                      <div className="">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <select
                                className="form-control show-tick"
                                id="category_id"
                                name="category_id"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.category_id}
                              >
                                <option value="" disabled selected>
                                  Select Category *
                                </option>
                                {category.map((category) => (
                                  <option key={category.id} value={category.id}>
                                    {category.name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.category_id &&
                              formik.errors.category_id ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.category_id}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Enter Task *"
                                id={`sub_category`}
                                name={`sub_category`}
                                onChange={formik.handleChange}
                                value={formik.values?.sub_category}
                                onBlur={formik.handleBlur}
                              />

                              {formik.touched.sub_category &&
                              formik.errors.sub_category ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.sub_category}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Enter Task Description *"
                                id={`title`}
                                name={`title`}
                                onChange={formik.handleChange}
                                value={formik.values?.title}
                              />
                              {formik.touched.title && formik.errors.title ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.title}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {/* Child End */}

                          {/* Sub Child Task Start */}

                          <div className="col-md-4">
                            <div className="form-group">
                              <select
                                name={`assigned_to_user_id`}
                                id={`assigned_to_user_id`}
                                className="form-control show-tick"
                                onChange={formik.handleChange}
                                value={formik.values?.assigned_to_user_id}
                              >
                                <option value="" selected disabled>
                                  Assigned To *
                                </option>
                                {employee?.map((employee) => (
                                  <option value={employee?.employee_user_id}>
                                    {employee?.employee_name}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.assigned_to_user_id &&
                              formik.errors.assigned_to_user_id ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.assigned_to_user_id}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="number"
                                id={`estimated_hours`}
                                name={`estimated_hours`}
                                onChange={formik.handleChange}
                                placeholder="Estimated Time (Minutes) *"
                                value={formik.values?.estimated_hours}
                              />
                              {formik.touched.estimated_time &&
                              formik.errors.estimated_time ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.estimated_time}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <select
                                className="form-control"
                                id={`priority`}
                                name={`priority`}
                                onChange={formik.handleChange}
                                value={formik.values?.priority}
                              >
                                <option value="" disabled selected>
                                  Select Priority *
                                </option>
                                <option value="4">Critical</option>
                                <option value="3">High</option>
                                <option value="2">Medium</option>
                                <option value="1">Low</option>
                              </select>

                              {formik.touched.priority &&
                              formik.errors.priority ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.priority}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <select
                                className="form-control"
                                id={`status`}
                                name={`status`}
                                onChange={formik.handleChange}
                                value={formik.values?.status}
                              >
                                <option value="" disabled selected>
                                  Select Status *
                                </option>
                                <option value="Pending">Pending</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Ready For Q/A">
                                  Ready For Q/A
                                </option>
                                <option value="Done">Done</option>
                                <option value="Overdue">Overdue</option>
                                <option value="On Hold">On Hold</option>
                                <option value="Ready For UAT">
                                  Ready For UAT
                                </option>
                              </select>
                              {formik.touched.status && formik.errors.status ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.status}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <div className="input-group">
                                <input
                                  ref={inputRef}
                                  type="date"
                                  className="form-control"
                                  id={`due_date`}
                                  name={`due_date`}
                                  min={new Date().toISOString().split("T")[0]}
                                  onChange={(e) => {
                                    const newValue =
                                      e.target.value === ""
                                        ? null
                                        : e.target.value;

                                    formik.handleChange({
                                      target: {
                                        name: "due_date",
                                        value: newValue,
                                      },
                                    });
                                  }}
                                  onClick={handleInputClick}
                                  value={
                                    formik.values?.due_date
                                      ? formik.values.due_date.split("T")[0]
                                      : ""
                                  }
                                />
                              </div>
                              {formik.touched.due_date &&
                              formik.errors.due_date ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.due_date}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table table-striped"></table>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Update
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary ml-2"
                      data-dismiss="modal"
                      onClick={() => navigate(-1)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAddTask;
