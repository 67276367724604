import { useFormik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Team.module.css";
import Select from "react-select";
import EditAddTask from "../../project/ProjectTaskList/EditAddTask";
import { successToast, errorToast } from "../../../utils/Toaster";
import AlertContext from "../../../context/Alert/AlertContext";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  team_name: Yup.string().required("Team Name is required"),
  // employee_name: Yup.string().required("Employee is  required"),
});

const EditTeam = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [isFormModified, setIsFormModified] = useState(false);
  const [employeeIds, setEmployeeIds] = useState([]);
  const {sendNotification} = useContext(AlertContext);

  useEffect(() => {
    axiosPrivate
      .get("users")
      .then((response) => {
        const fetchedEmployeeOptions = response.data.data.map((employee) => ({
          value: employee.id,
          label: employee.name,
          role_name: employee.role_name,
          register_id: employee.register_id
        }));
        setEmployeeOptions(fetchedEmployeeOptions);
        setEmployeeIds(props?.employeeIds || []);
      })
      .catch((error) => {
        errorToast("Error fetching employee names:", error);
      });
  }, []);

  const employeeData = employeeOptions.filter(
    (option) => option.role_name !== "Client"
  );

  const formik = useFormik({
    initialValues: {
      team_name: props?.team_name || "",
      project_id: props?.project_id || "",
      employee_name: props?.employee_name || [],
      employee_user_id: props?.employee_user_id || [],
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const requestData = {
          team_name: values.team_name,
          project_id: id,
          employee_name: values.employee_name,
          employee_user_id: values.employee_user_id,
        };

        const response = await axiosPrivate.post(
          `team/update/${id}`,
          requestData
        ).then((res) =>{
            employeeData?.filter((emp) =>{
              return values?.employee_user_id.includes(emp?.value)
            }).filter((emp) =>{
              return !props.employee_user_id.includes(String(emp?.value))
            }).map((employee) =>{ 
              if(employee?.register_id){
                sendNotification(employee?.register_id, id.replaceAll("-", " "), "You have assigned a new project",`/admin/project-list/edit/${id}`, employee?.value, 0);
              }
            })
        });

        if (response?.code === 201) {
          successToast("Team updated successfully!");
        }
        // navigate("/admin/project-list");
      } catch (error) {
        console.log("Error", error);
        
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleInputChange = (e) => {
    formik.handleChange(e);
    setIsFormModified(true);
  };

  return (
    <div>
      <div className="tab-content mt-3">
        <form onSubmit={formik.handleSubmit}>
          <div
            className="tab-pane fade show active"
            id="user-add"
            role="tabpanel"
          >
            <div className="card">
              <div>
                <strong>Edit Team</strong>
              </div>

              <div className="row clearfix mt-4">
                <div className="col-lg-3 col-md-16 col-sm-12">
                  <div className="form-group">
                    {/* <label> 
                            Name:<span className="required">*</span>
                          </label> */}
                    <input
                      id="team_name"
                      name="team_name"
                      type="text"
                      className="form-control"
                      placeholder="Team Name*"
                      onChange={handleInputChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.team_name}
                    />
                    {formik.touched.team_name && formik.errors.team_name ? (
                      <div className={classes.invalidDataError}>
                        {formik.errors.team_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                  <Select
  id="employee_name"
  name="employee_name"
  className="custom-style"
  isMulti
  options={employeeData}
  placeholder="Select emp name *"
  onChange={(selectedOptions) => {
    const updatedEmployeeValues = selectedOptions.map(
      (option) => option.label
    );
    const employeeIds = selectedOptions.map((option) => option.value);
    setEmployeeIds(employeeIds);

    formik.setValues({
      ...formik.values,
      employee_name: updatedEmployeeValues,
      employee_user_id: employeeIds,
    });
    setIsFormModified(true);

    handleInputChange({
      target: {
        name: "employee_name",
        value: updatedEmployeeValues,
      },
    });
  }}
  onBlur={formik.handleBlur}
  value={
    Array.isArray(formik.values.employee_name)
      ? employeeData.filter((option) =>
          formik.values.employee_name.includes(option.label)
        )
      : []
  }
/>


                    {formik.touched.employee_name &&
                    formik.errors.employee_name ? (
                      <div className={classes.invalidDataError}>
                        {formik.errors.employee_name}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div>
                {isFormModified && (
                  <span
                    onClick={formik.handleSubmit}
                    className="btn btn-primary mr-2"
                  >
                    Update
                  </span>
                )}

                <button
                  type="button "
                  className="btn btn-outline-primary"
                  onClick={() => navigate("/admin/project-list")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditTeam;