import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Permission.module.css";
import { errorToast, successToast } from "../../../utils/Toaster";
import "react-toastify/dist/ReactToastify.css";


const validationRules = {
  action: Yup.string()
    .max(200, "Name must not exceed 200 characters")
    .required("Permission Type is required"),
  name: Yup.string()
    .max(200, "Name must not exceed 200 characters")
    .required("Module Name is required"),
};

const AddPermission = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      action: "",
      name: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      axiosPrivate
        .post("permissions", values)
        .then((response) => {
          successToast("Permission added successfully");
          navigate("/admin/permission");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 422) {
              errorToast("Permission Name and Action are already in use");
            } else if (error.response.status !== 201) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  return (
    <div className="section-body">
      <div>
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="permission-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>Add Permission</strong>
                </div>

                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label >
                          Module Name <span className="required">*</span>
                        </label>
                        <input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Module Name*"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                         
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>
                          Permission Type{" "}
                          <span className="required">*</span>
                        </label>
                        <select
                          id="action"
                          name="action"
                          type="text"
                          className="form-control"
                          placeholder="Permission Action*"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.action}
                         
                        >
                          <option value="" disabled>
                            Select Type
                          </option>
                          <option value="list">List</option>
                          <option value="create">Create</option>
                          <option value="edit">Edit</option>

                          <option value="delete">Delete</option>
                        </select>
                        {formik.touched.action && formik.errors.action ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.action}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="col-12">
                    
                      <div className="table-responsive">
                        <table className="table table-striped">
                        
                        </table>
                      </div>
                      <button type="submit" className="btn btn-primary">
                      Submit

                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/permission")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPermission;
