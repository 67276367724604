import { Formik, useFormik } from "formik";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./addProjectStyle.module.css";
import DataTable2 from "../../../components/DataTable2";
import EditTeam from "../../hrms/Team/EditTeam";
import { Link } from "react-router-dom";
import EditAddTask from "../ProjectTaskList/EditAddTask";
import LoaderContext from "../../../context/Loader/LoaderContext";
import { set } from "react-hook-form";
import Loader from "../../../context/Loader/Loader";
import { errorToast, successToast } from "../../../utils/Toaster";
import { FaEye } from "react-icons/fa";
import Swal from "sweetalert2";

import "react-datepicker/dist/react-datepicker.css";
import { BsCalendar } from "react-icons/bs";
import DatePicker from "react-datepicker";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationRules = {
  project_name: Yup.string()
    .max(120, "Project Name must not exceed 120 characters")
    .required("Project Name is required"),

  client_user_id: Yup.string().required("Client is required"),
  estimated_hours: Yup.string()
    .max(120, "Time must not exceed 30 characters")
    .required("Time is required"),
  type: Yup.string()
    .max(120, "Type must not exceed 30 characters")
    .required("Type is required"),
  client_user: Yup.string().required("Client Name is required"),
  due_date: Yup.string().required("Due date is required"),
};

const EditProject = () => {
  let { id } = useParams();

  const { loading, setIsLoading } = useContext(LoaderContext);
  const navigate = useNavigate();
  const [projectId, setProjectId] = useState(null);
  const [projects, setProjects] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [employeeNames, setEmployeeNames] = useState([]);
  const [employeeUserId, setEmployeeUserId] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskDetails, setTaskDetails] = useState(null);
  const [isFormModified, setIsFormModified] = useState(false);
  const [refreshState, setRefreshState] = useState(false);

  const [editTaskVisible, setEditTaskVisible] = useState(false);
  const inputRef = useRef(null);
  // const [taskIdToEdit, setTaskIdToEdit] = useState(null);

  const userDetails = JSON.parse(localStorage.getItem("userData")) || {};

  const headers = [
    {
      name: "Task",
      field: "sub_category",
      sortable: true,
      classKey: "w60",
    },
    {
      name: "Task Description",
      field: "task_name",
      sortable: true,
      classKey: "",
    },
    {
      name: "Assignee ",
      field: "assigned_to_user_name",
      sortable: true,
      classKey: "",
    },
    { name: "Status", field: "task_status", sortable: true, classKey: "" },
    { name: "Priority", field: "priority", classKey: "" },
    { name: "Due Date", field: "due_date", classKey: "" },

    userDetails?.role !== "3"
      ? {
          name: "Actual Hours",
          field: "actual_time",
          sortable: true,
          classKey: "",
        }
      : null,
    userDetails?.role !== "3"
      ? {
          name: "Est.Time",
          field: "estimated_time",
          sortable: true,
          classKey: "",
        }
      : null,
    { name: "Action", field: "action", classKey: "" },
  ].filter((header) => header !== null);

  const searchItems = ["task_name", "task_status", "sub_category"];

  const userName = userDetails?.userName;

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(`all-project-details-by-slug/${id}`)
      .then((response) => {
        if (response?.status == 200) {
          formik.setValues(response?.data?.data[0]);
          formik.setFieldValue(
            "due_date",
            formatDate(response?.data?.data[0]?.due_date)
          );
          setTeamName(response?.data?.data[0]?.teams[0]?.team_name || "");
          setTasks(response?.data?.data[0]?.tasks);
          setEmployeeNames(
            response?.data?.data[0]?.teams[0]?.team_members?.map(
              (member) => member.employee_name
            ) || []
          );
          setEmployeeUserId(
            response?.data?.data[0]?.teams[0]?.team_members?.map(
              (member) => member.employee_user_id
            ) || []
          );

          setProjects(response?.data?.data[0]);
        }
        setIsFormModified(false);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [refreshState]);

  const showEditTask = (task_id) => {
    setIsLoading(true);
    axiosPrivate
      .get(`tasksById/${task_id}`)
      .then((response) => {
        if (response.data.code === 200) {
          setTaskDetails(response.data.data);
          setEditTaskVisible(true);
        } else {
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (e) => {
    formik.handleChange(e);
    setIsFormModified(true);
  };

  const formik = useFormik({
    initialValues: {
      project_name: "",
      estimated_hours: "",
      type: "",
      project_id: "",
      due_date: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setFieldError }) => {
      const requestData = {
        project_name: values.project_name,
        estimated_hours: values.estimated_hours,
        type: values.type,
        project_id: values.project_id,
        due_date: values.due_date,
      };
      setIsLoading(true);
      axiosPrivate
        .put(`project/update/${projects?.project_id}`, requestData)
        .then((response) => {
          if (response.status === 200) {
            successToast("Project updated successfully");

            setProjectId(response.data.data.insertId);
            formik.setFieldValue("project_id", response.data.data.insertId);

            setIsLoading(false);
          }
        })
        .catch((error) => {
          errorToast(error?.response?.data?.message);
          setIsLoading(false);
          if (error.response) {
            if (error.response.status == 422) {
              for (let i = 0; i < error.response.data.errors?.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  const taskData = tasks?.map((value) => {
    let buttons = [];
    buttons.push(
      <Link
        key="viewButton##1"
        type="button"
        to={`/admin/project-ticket-list/view/${value?.task_id}`}
        className={
          window?.innerWidth >= 768
            ? "btn btn-icon btn-outline-success mr-1"
            : "viewButton"
        }
        title="View"
      >
        <FaEye />
      </Link>
    );
    if (value.task_status !== "Done") {
      buttons.push(
        <Link
          key={`editButton##${value?.task_id}`}
          type="button"
          to={`/admin/project-taskboard/edit/${value?.task_id}`}
          onClick={() => showEditTask(value?.task_id)}
          className={
            window?.innerWidth >= 768
              ? "btn btn-icon btn-outline-primary mr-1"
              : "editButton"
          }
          title="Edit"
        >
          <i className="fa fa-edit"></i>
        </Link>
      );
    }
    if (userName === "Admin") {
      buttons.push(
        <span
          key="deleteButton##1"
          type="button"
          data-id={value.task_id}
          onClick={() => projectDeleteHandler(value.task_id)}
          className={
            window?.innerWidth >= 768
              ? "btn btn-icon js-sweetalert btn-outline-danger"
              : "deleteButton"
          }
          title="Delete"
        >
          <i className="fa fa-trash-o"></i>
        </span>
      );
    }
    const parsedDate = new Date(value?.due_date);

    parsedDate.setHours(0, 0, 0, 0);

    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    value["due_date"] = `${day}-${month}-${year}`;

    value["action"] = buttons.length > 0 ? buttons : "-";
    value["assigned_to_user_name"] =
      value?.assigned_to_user?.assigned_to_user_name;
    //  value[]
    value["priority"] = (
      <div>
        {value.priority === "4" && (
          <span className="status critical" style={{fontSize:'11px'}}>Critical</span>
        )}
        {value.priority === "3" && <span className="status high" style={{fontSize:'11px'}}>High</span>}
        {value.priority === "2" && (
          <span className="status medium" style={{fontSize:'11px'}}>Medium</span>
        )}
        {value.priority === "1" && <span className="status low" style={{fontSize:'11px'}}>Low</span>}
      </div>
    );
    value["actual_time"] =
      convertToHoursAndMinutes(value?.actual_time) + " Hrs";
    value["estimated_time"] =
      convertToHoursAndMinutes(value?.estimated_time) + " Hrs";

    return value;
  });

  const projectDeleteHandler = async (task_id) => {
    showConfirmMessage(() => {
      axiosPrivate
        .delete(`task/delete/${task_id}`)
        .then(async (response) => {
          if (response.data.code === 200) {
            setRefreshState(!refreshState);
            successToast(response.data.message);
          }
        })
        .catch((error) => {
          errorToast(error.message);
        });
    });
  };

  function showConfirmMessage(callback) {
    Swal.fire({
      title: "Are you sure?",
      text: "By clicking 'Yes', this task will be permanently deleted.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      customClass: {
        cancelButton: "btn btn-outline-primary ml-2 ",
        confirmButton: "btn btn-primary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
        Swal.fire("Deleted!", "Task has been deleted.", "success");
      } else {
        Swal.fire("Cancelled", "Task deletion cancelled.", "error");
      }
    });
  }

  const formatDate = (inputDateStr) => {
    if (!inputDateStr) {
      return null;
    }

    const parsedDate = new Date(inputDateStr);

    parsedDate.setHours(0, 0, 0, 0);

    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${year}-${month}-${day}`;
  };

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const projectNameValidate = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    formik.setFieldValue("project_name", value);
  };

  function convertToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes < 10 ? "0" : ""}${remainingMinutes}`;
  }
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="section-body mt-3">
          <div>
            <div className="tab-content mt-3">
              {userDetails?.role !== "3" && (
                <form onSubmit={formik.handleSubmit}>
                  <div
                    className="tab-pane fade show active"
                    id="user-add"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-header">
                        <strong>Edit Project</strong>
                      </div>
                      <div className="card-body">
                        <div className="row clearfix">
                          <div className="col-lg-4 col-md-16 col-sm-12">
                            {/* <label>
                        Client Name:<span className="required">*</span>
                      </label> */}
                            <div className="form-group">
                              <input
                                id="client_user"
                                name="client_user"
                                type="text"
                                className="form-control"
                                placeholder="Client Name *"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values.client_user}
                                disabled={Boolean(formik?.values?.client_user)}
                              />
                              {formik.touched.client_user &&
                              formik.errors.client_user ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.client_user}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-16 col-sm-12">
                            {/* <label>
                        Project Name:<span className="required">*</span>
                      </label> */}
                            <div className="form-group">
                              <input
                                id="project_name"
                                name="project_name"
                                type="text"
                                className="form-control"
                                placeholder="Project Name *"
                                onChange={(e) => {
                                  projectNameValidate(e);
                                  setIsFormModified(true);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik?.values.project_name}
                              />
                              {formik.touched.project_name &&
                              formik.errors.project_name ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.project_name}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-16 col-sm-12">
                            {/* <label>
                        Time:<span className="required">*</span>
                      </label> */}
                            <div className="form-group">
                              <input
                                id="estimated_hours"
                                name="estimated_hours"
                                type="text"
                                className="form-control"
                                placeholder="Time(hours) *"
                                onChange={handleInputChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values.estimated_hours}
                              />
                              {formik.touched.estimated_hours &&
                              formik.errors.estimated_hours ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.estimated_hours}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-16 col-sm-12">
                            {/* <label>
                        Type:<span className="required">*</span>
                      </label> */}
                            <div className="form-group">
                              <select
                                id="type"
                                name="type"
                                className="form-control"
                                onChange={handleInputChange}
                                onBlur={formik.handleBlur}
                                value={formik?.values.type}
                                required
                              >
                                <option value="" disabled>
                                  Select Type
                                </option>
                                <option value="single">Single</option>
                                <option value="recurring">Recurring</option>
                              </select>
                              {formik.touched.type && formik.errors.type ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.type}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="form-group">
                              <div className="input-group datepickerwidth">
                                <DatePicker
                                  className="form-control "
                                  id="due_date"
                                  name="due_date"
                                  onChange={(date) => {
                                    setIsFormModified(true);
                                    formik.setFieldValue(
                                      "due_date",
                                      date ? formatDate(date) : null
                                    );
                                  }}
                                  placeholderText="Due Date (dd-mm-yyyy) *"
                                  onBlur={formik.handleBlur}
                                  selected={
                                    formik.values.due_date
                                      ? new Date(formik.values.due_date)
                                      : null
                                  }
                                  dateFormat="dd-MM-yyyy"
                                  minDate={new Date()}
                                />

                                <div
                                  className="input-group-append"
                                  style={{ height: "32px" }}
                                >
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() =>
                                      document
                                        .getElementById(`due_date`)
                                        .click()
                                    }
                                  >
                                    <BsCalendar />
                                  </button>
                                </div>
                              </div>
                              {formik.touched.due_date &&
                              formik.errors.due_date ? (
                                <div className={classes.invalidDataError}>
                                  {formik.errors.due_date}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-12">
                            {/* <hr className="mt-4" /> */}
                            <div className="table-responsive">
                              <table className="table table-striped"></table>
                            </div>
                            <div>
                              {isFormModified && (
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Update
                                </button>
                              )}
                            </div>

                            {/* <button
                        type="button"
                        className="btn btn-secondary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/project-list")}
                      >
                        Close
                      </button> */}
                          </div>
                        </div>

                        <EditTeam
                          project_id={projectId}
                          team_name={teamName}
                          employee_name={employeeNames}
                          employee_user_id={employeeUserId}
                        />

                        <div className="col-12"></div>
                      </div>
                    </div>
                  </div>
                </form>
              )}
              <div className="card">
                <div className="d-flex justify-content-end pt-3 pr-3 mr-3 align-items-center">
                  <Link
                    to={`/admin/project-task/add/${id}`}
                    className="text-decoration-none "
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      <i className="fe fe-plus mr-2" />
                      Add New Task
                    </button>
                  </Link>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <DataTable2
                      lists={taskData}
                      headers={headers}
                      searchItems={searchItems}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {editTaskVisible && <EditAddTask taskDetails={taskDetails} />}
      <style>
        {`
              .permissionButton{
              cursor: pointer;
              padding: 7px; 
              color:#28a745; 
              position:relative; 
              top: -2px
              }

              .editButton{
              padding: 7px; 
              color:#5a5278
              }

              .deleteButton{
              cursor: pointer; 
              padding: 7px;
              color:#dc3545
              }

              .viewButton{
              cursor: pointer;
              padding: 7px; 
              color:#28a745; 
              position:relative; 
              top: -1px
              }
            `}
      </style>
    </div>
  );
};

export default EditProject;
