import { Fragment, useContext, useState, useEffect } from "react";
import userPng from "../../assets/images/user.png";
import webPm from "../../assets/images/webpm-loader.gif";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import AuthContext from "../../context/Auth/AuthContext";
import Swal from "sweetalert2";
import styles from "./sidebar.module.css";

const SideBar = () => {
  const navigate = useNavigate();
  const { authPermissions, setAuthPermissions } = useContext(AuthContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  let userData = {};
  if (localStorage.getItem("userData")) {
    userData = JSON.parse(localStorage.getItem("userData"));
  }

  const logoutHandler = async (e) => {
    showConfirmMessage(() => {
      e.preventDefault();
      localStorage.clear();
      setAuthPermissions([]);
      
      window.location.reload();
    });
  };

  const pathName = window.location.pathname;

  function showConfirmMessage(callback) {
    Swal.fire({
      title: "Are you sure?",
      text: "By clicking 'Yes', user will logout.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      customClass: {
        cancelButton: "btn btn-outline-primary ml-2 ",
        confirmButton: "btn btn-primary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        callback();
        // Swal.fire(
        //     "Logout!",
        //     "User logout successfully.",
        //     "success"
        // );
      } else {
        Swal.fire("Cancelled", "Logout cancelled.", "error");
      }
    });
  }

  let userDetails = {};
  if (localStorage.getItem("userData")) {
    userDetails = JSON.parse(localStorage.getItem("userData"));
  }

  const userId = userDetails?.userId ?? null;

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() =>{
    setIsSidebarOpen(false);
  }, [pathName])
  return (
    <Fragment>
      <div id="header_top" className="header_top false d-md-none" style={{zIndex:'1000'}}>
        <div className="container">
          <div className="d-flex justify-content-between w-100">
            <div className="dropdown">
              <div className="dropdown d-flex ">
                <a
                  className="nav-link icon  d-md-flex  btn-icon ml-1"
                  data-toggle="dropdown"
                >
                  <img
                    className="avatar"
                    src={userPng}
                    alt="User Avatar"
                    data-toggle="tooltip"
                    data-placement="right"
                  />
                </a>
                <h4 style={{marginBottom:'0px', fontSize:'13px', color:'#2c2945'}}>{userData.userName}!</h4>

                <div className="dropdown-menu">
                  <Link
                    to={`/admin/usersregistration/edit/${userId}`}
                    className="dropdown-item"
                  >
                    <i className="dropdown-icon fe fe-user "></i> Profile
                  </Link>
                  <div className="dropdown-divider"></div>
                  <a
                    className="dropdown-item"
                    onClick={(e) => logoutHandler(e)}
                  >
                    <i className="dropdown-icon fe fe-log-out"></i> Sign out
                  </a>
                </div>
              </div>
            </div>
            
            <p className="nav-link icon menu_toggle mb-0" onClick={toggleSidebar}>
              <i className={`fa ${isSidebarOpen ? 'fa-times' : 'fa-align-left'}`}></i>
            </p>
          </div>
        </div>
      </div>

      <div className={isSidebarOpen ? styles.sidebarOpen : styles.sidebarClose}>
      <div id="left-sidebar" className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div
          className="hleft d-block d-md-block"
          style={{
            padding: "8px 0",
            borderBottom: "1px solid #ffffff26",
          }}
        >
          <a aria-current="page" className="header-brand active mr-2" >
            <img
              src={webPm}
              style={{
                fontSize: "20px",
                width: "35px",
                height: "35px",
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "5px",
              }}
            />
          </a>
          <h5
            className="brand-name"
            style={{ color: "#d2cde3", fontSize: "15px" }}
          >
          </h5>
        </div>

        <nav id="left-sidebar-nav" className="sidebar-nav col">
          <div className="">
            <ul className="metismenu">
              <li
                className={
                  pathName.includes("/client") ||
                  pathName.includes("/usersregistration") ||
                  pathName.includes("/project-list")
                    ? styles.inactive
                    : styles.bgColor
                }
              >
                <span className="">
                  <Link aria-current="page" className="list-a" to="/admin">
                    <i className="icon-bar-chart"></i>Dashboard
                    <i className="caret-left"></i>
                  </Link>
                </span>
              </li>
              <li
                className={
                  pathName.includes("/client") ||
                  pathName.includes("/usersregistration")
                    ? styles.bgColor
                    : ""
                }
              >
                {authPermissions.includes("Master-list") === true ? (
                  <span className="">
                    <a aria-current="page" className="list-a" href="/events">
                      <i className="icon-layers"></i>Master
                    </a>
                  </span>
                ) : (
                  ""
                )}

                <ul className="collapse collapse collapse collapse collapse collapse collapse collapse collapse collapse">
                  {authPermissions.includes("User Registration-list") ===
                  true ? (
                    <li className="">
                      <span className="">
                        <Link className="list-a" to="/admin/usersregistration">
                          Users
                        </Link>
                      </span>
                    </li>
                  ) : null}
                  {authPermissions.includes("Clients-list") === true ? (
                    <li className="">
                      <span className="">
                        <Link className="list-a" to="/admin/client">
                          Clients
                        </Link>
                      </span>
                    </li>
                  ) : null}

                  {authPermissions.includes("Permissions-list") === true ? (
                    <li className="">
                      <span className="">
                        <Link className="list-a" to="/admin/permission">
                          Permissions
                        </Link>
                      </span>
                    </li>
                  ) : null}

                  {authPermissions.includes("Role Permissions-list") ===
                  true ? (
                    <li className="">
                      <span className="">
                        <Link className="list-a" to="/admin/rolepermission">
                          Auth
                        </Link>
                      </span>
                    </li>
                  ) : null}
                </ul>
              </li>

              <li
                className={
                  pathName.includes("/project-list") ? styles.bgColor : ""
                }
              >
                {authPermissions.includes("Project List-list") === true ? (
                  <span className="">
                    <Link
                      aria-current="page"
                      className="list-a"
                      to="/admin/project-list"
                    >
                      <i className="icon-notebook"></i>Projects
                      <i className="caret-left"></i>
                    </Link>
                  </span>
                ) : null}
              </li>

              <li className="">
                {authPermissions.includes("Reports-list") === true ? (
                  userData.role === "1" ? (
                    <span className="">
                      <Link
                        aria-current="page"
                        className="list-a"
                        to="/admin/reports"
                      >
                        <i className="icon-doc"></i>Reports
                        <i className="caret-left"></i>
                      </Link>
                    </span>
                  ) : null
                ) : null}
              </li>

            </ul>
          </div>
        </nav>
        <div className="hright d-none d-md-block">
          <div className="dropdown">
            <div className="dropdown d-flex ">
              <a
                className="nav-link icon d-md-flex  btn-icon ml-1"
                data-toggle="dropdown"
              >
                <img
                  className="avatar"
                  src={userPng}
                  alt="User Avatar"
                  data-toggle="tooltip"
                  data-placement="right"
                />
              </a>
              
              <div className="dropdown-menu">
                <Link
                  to={`/admin/usersregistration/edit/${userId}`}
                  className="dropdown-item"
                >
                  <i className="dropdown-icon fe fe-user"></i>
                </Link>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" onClick={(e) => logoutHandler(e)}>
                  <i className="dropdown-icon fe fe-power"></i>
                </a>
              </div>
            </div>
            
          </div>
          <h4 style={{ fontSize:'13px', color:'#d2cde3'}}>{userData.userName}!</h4>
        </div>
      </div>
      </div>
    </Fragment>
  );
};

export default SideBar;
