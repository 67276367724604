import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosPrivate from "../../../hooks/axiosPrivate";
import PermissionTableTd from "./PermissionTableTd";
import { errorToast,successToast } from '../../../utils/Toaster';
import AuthContext from "../../../context/Auth/AuthContext";
import styles from "./addUserPermissionStyle.module.css"
const UserPermission = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // State variables
  const [permissions, setPermissions] = useState([]);
  const [permissionDistinctName, setPermissionDistinctName] = useState([]);
  const [userPermissionsIds, setUserPermissionsIds] = useState([]);

  // Fetch permissions and permission distinct names on component mount
  useEffect(() => {
    permissionsDinstict();
  }, []);

  // Fetch user permissions whenever user ID changes
  useEffect(() => {
    hasPermissions();
  }, [id]);

  // Fetch all permissions and distinct permission names
  const permissionsDinstict = async () => {
    try {
      const response = await axiosPrivate.get("permissions");
      setPermissions(response?.data?.data || []);
      
    } catch (error) {
      errorToast("Error fetching permissions!");
    }

    try {
      const response = await axiosPrivate.get("permissions/distinct/name");
      setPermissionDistinctName(response?.data?.data || []);
    } catch (error) {
      errorToast("Error fetching distinct permission names!");
    }
  };

  // Fetch user-specific permissions
  const hasPermissions = async () => {
    try {
      const response = await axiosPrivate.get(
        `user-has-permissions/user/${id}`
      );

      if (response?.data?.data) {
        const userPermissionsArray =
          response?.data?.data?.map((item) => item.permission_id) || [];
        setUserPermissionsIds(userPermissionsArray);
      }

      if (response?.code === 404) {
        errorToast("No user permissions found! Kindly add permissions.");
      }
    } catch (error) {
      errorToast("Error fetching user permissions!");
    }
  };

  const modifyPermissionIdArray = async (permission_id, action) => {
    setUserPermissionsIds((prevIds) => {
      if (action === false) {
        return prevIds.filter((id) => id !== permission_id);
      } else {
        return [...prevIds, permission_id];
      }
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    const userDetails = JSON.parse(localStorage.getItem("userData"))||{};

    let values = {};

    if (userDetails && userDetails.role) {
      const roleId = userDetails.role;

      values = {
        user_id: id,
        // role_id: roleId,
        permission_ids: userPermissionsIds,
      };
    } else {
      console.error("Role information not found in local storage");
    }

    await axiosPrivate
      .post(`user-has-permissions`, values)
      .then((response) => {
        successToast(response.data.message);
        navigate("/admin/usersregistration");
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status !== 201) {
            errorToast(error.response.message);
          }
        }
      });
  };

  const title = ["Create", "List", "Edit", "Delete"];
  const rows = permissionDistinctName?.map((distinct, index) => {
    // Filter permissions based on distinct name
    const distinctPermissions = permissions?.filter(
      (permission) => permission.name === distinct.name
    );

    const createPermission = distinctPermissions?.find(
      (permission) => permission.action === "create"
    );

    const listPermission = distinctPermissions?.find(
      (permission) => permission.action === "list"
    );

    const updatePermission = distinctPermissions?.find(
      (permission) => permission.action === "edit"
    );

    const deletePermission = distinctPermissions?.find(
      (permission) => permission.action === "delete"
    );

    return (
      <tr key={index} className={styles.rowWrap}>
        <td >{distinct?.name}</td>
        {/* Map over the filtered permissions array */}

        <PermissionTableTd
          id={createPermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={userPermissionsIds.includes(
            createPermission?.id.toLocaleString()
          )}
          tdKey={index}
        />

        <PermissionTableTd
          id={listPermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={userPermissionsIds.includes(
            listPermission?.id.toLocaleString()
          )}
          tdKey={index}
        />

        <PermissionTableTd
          id={updatePermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={userPermissionsIds.includes(
            updatePermission?.id.toLocaleString()
          )}
          tdKey={index}
        />

        <PermissionTableTd
          id={deletePermission?.id}
          modifyPermissionIdArray={modifyPermissionIdArray}
          checked={userPermissionsIds.includes(
            deletePermission?.id.toLocaleString()
          )}
          tdKey={index}
        />
      </tr>
    );
  });

  return (
    <div className="section-body mt-3">
      <div>
        <div className="tab-content mt-3">
          <form onSubmit={submitHandler}>
            <div
              className="tab-pane fade show active"
              id="user-permissions"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>User Permissions</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th />
                              {title?.map((name, key) => (
                                <th key={key}>{name}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>{rows}</tbody>
                        </table>
                      </div>
                      <button type="submit" className="btn btn-primary mr-2">
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/usersregistration")}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserPermission;
