import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable2 from "../../../components/DataTable2";
import TopNavBar from "../../../components/Pages/Users/TopNavBar";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { errorToast, successToast } from "../../../utils/Toaster";

const Team = (props) => {
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [taskType, setTaskType] = useState([]);

  const headers = [
    { name: "TEAM NAME", field: "team_name", sortable: true, classKey: "w60" },
    { name: "PROJECT NAME", field: "title", sortable: true, classKey: "" },

    {
      name: "EMPLOYEE NAME",
      field: "employee_names",
      sortable: true,
      classKey: "",
    },
    // { name: 'TIME', field: 'estimated_hours', sortable: true, classKey: '' },

    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["team_name"];

  useEffect(() => {
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("team", options)
      .then((task) => {
        const taskData = task.data.data.map((value, key) => {
          let buttons = [];
          if (true)
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/team/edit/${value.project_id}`}
                className="btn btn-icon btn-outline-info mr-1"
                title="Edit"
              >
                <i className="fa fa-edit"></i>
              </Link>
            );
          if (true)
            buttons.push(
              <button
                key="[nodemon] app crashed - waiting for file changes before starting...leteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => taskDeleteHandler(value.task_name)}
                className="btn btn-icon js-sweetalert btn-outline-danger"
                title="Delete"
              >
                <i className="fa fa-trash-o text-danger"></i>
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setTaskType(taskData);
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
         errorToast(error.response.status);
        } else if (error.request) {
          //response not received though the request was sent
         errorToast(error.request);
        } else {
          //an error occurred when setting up the request
         errorToast(error.message);
        }
      });
  }, []);

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  const taskDeleteHandler = async (task_name) => {
    axiosPrivate
      .delete(`task-type/${task_name}`)
      .then(async (response) => {
        if (response.data.code == 200) {
          setTaskType([]);
          setAlertMessage({ message: "task deleted successfully" });
          await refreshTable();
        }
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
         errorToast(error.response.status);
        } else if (error.request) {
          //response not received though the request was sent
         errorToast(error.request);
        } else {
          //an error occurred when setting up the request
         errorToast(error.message);
        }
      });
  };

  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axiosPrivate
        .get("team", options)
        .then((task) => {
          const taskData = task.data.data.map((value, key) => {
            let buttons = [];
            if (true)
              buttons.push(
                <button
                  key="editButton##1"
                  type="button"
                  to={`/admin/team/edit/${value.id}`}
                  className="btn btn-icon btn-outline-info mr-1"
                  title="Edit"
                >
                  <i className="fa fa-edit"></i>
                </button>
              );
            if (true)
              buttons.push(
                <button
                  key="deleteButton##1"
                  type="button"
                  data-id={value.id}
                  onClick={() => taskDeleteHandler(value.id)}
                  className="btn btn-icon js-sweetalert btn-outline-danger"
                  title="Delete"
                >
                  <i className="fa fa-trash-o text-danger"></i>
                </button>
              );
            value["action"] = buttons.length > 0 ? buttons : "-";
            return value;
          });
          setTaskType(taskData);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
          if (error.response) {
            //response status is an error code
           errorToast(error.response.status);
          } else if (error.request) {
            //response not received though the request was sent
           errorToast(error.request);
          } else {
            //an error occurred when setting up the request
           errorToast(error.message);
          }
        });
    });
  };

  return (
    <div>
      <div>
        <div className={`section-body ${props.fixNavbar ? "marginTop" : ""} `}>
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <ul className="nav nav-tabs page-header-tab">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="Employee-tab"
                    data-toggle="tab"
                    href="#Employee-list"
                  >
                    All
                  </a>
                </li>

                <li className="nav-item"></li>
              </ul>
              <div className="header-action">
                <Link to="add" className="text-decoration-none">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    <i className="fe fe-plus mr-2" />
                    Add
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="section-body mt-3">
          <div className="container-fluid">
            <div className="tab-content mt-3">
              <div
                className="tab-pane fade show active"
                id="user-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">TEAM LIST</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      {
                        <DataTable2
                          lists={taskType}
                          headers={headers}
                          searchItems={searchItems}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
